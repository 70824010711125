import React from 'react';
import PropTypes from 'prop-types';

import Attributes from './components/Attributes';
import AttributeOptions from './components/AttributeOptions';
import DateOptions from './components/DateOptions';
import Options from './components/Options';

const Results = ({
  activeAnswers,
  activeAttribute,
  activePrompt,
  hasActivePromptLimit,
  isLoading,
  isLoadingCustom,
  isSurvey,
  onSelectAnswer,
  onSetAnswerInput,
  onSetAnswerMetric,
  results,
  resultsCustom,
  setActiveAttribute,
}) => {
  if (activePrompt?.dateOptions) {
    return (
      <DateOptions
        activeAnswers={activeAnswers}
        activePrompt={activePrompt}
        dateOptions={activePrompt?.dateOptions}
        isLoading={isLoading}
        onSelectAnswer={onSelectAnswer}
        results={results}
      />
    );
  }

  if (activePrompt?.attributes && !results && !resultsCustom) {
    return (
      <Attributes
        isLoading={isLoading}
        isLoadingCustom={isLoadingCustom}
        options={activePrompt?.attributes}
        setActiveAttribute={setActiveAttribute}
      />
    );
  }

  if ((results || resultsCustom) && activePrompt?.attributes?.length > 1 && !activeAttribute) {
    return (
      <AttributeOptions
        activeAnswers={activeAnswers}
        activePrompt={activePrompt}
        hasActivePromptLimit={hasActivePromptLimit}
        isLoading={isLoading}
        isLoadingCustom={isLoadingCustom}
        isSurvey={isSurvey}
        onSelectAnswer={onSelectAnswer}
        results={results}
        resultsCustom={resultsCustom}
      />
    );
  }

  if (activePrompt?.objects || activePrompt?.metrics || results || resultsCustom) {
    return (
      <Options
        activeAnswers={activeAnswers}
        activePrompt={activePrompt}
        hasActivePromptLimit={hasActivePromptLimit}
        isLoading={isLoading}
        isLoadingCustom={isLoadingCustom}
        isSurvey={isSurvey}
        max={activePrompt?.max}
        onSelectAnswer={onSelectAnswer}
        onSetAnswerInput={onSetAnswerInput}
        onSetAnswerMetric={onSetAnswerMetric}
        operators={activePrompt?.operators}
        options={results || resultsCustom || activePrompt?.objects || activePrompt?.metrics}
      />
    );
  }

  return <p>No {activePrompt ? activePrompt?.name : 'results'} found.</p>;
};

Results.propTypes = {
  activeAnswers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  activeAttribute: PropTypes.object,
  activePrompt: PropTypes.object,
  hasActivePromptLimit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingCustom: PropTypes.bool,
  isSurvey: PropTypes.bool,
  onSelectAnswer: PropTypes.func,
  onSetAnswerInput: PropTypes.func,
  onSetAnswerMetric: PropTypes.func,
  onSortAnswers: PropTypes.func,
  results: PropTypes.array,
  resultsCustom: PropTypes.array,
  setActiveAttribute: PropTypes.func,
};

export default Results;
