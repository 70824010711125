import {
  COLOR_COBALT,
  COLOR_MINT,
  COLOR_ORANGE,
  COLOR_PINK,
  COLOR_PURPLE,
  COLOR_YELLOW,
} from '@utilities';

export const CHART_COLORS = [
  COLOR_COBALT,
  COLOR_MINT,
  COLOR_YELLOW,
  COLOR_PINK,
  COLOR_ORANGE,
  COLOR_PURPLE,
];
