import styles from './_index.module.scss';

/* ---------------------------------------------------------------------- */
/* Footer 
/* ---------------------------------------------------------------------- */

const Footer = () => {
  return <div className={styles['footer']}>Powered by The Numerator OmniPanel</div>;
};

export default Footer;
