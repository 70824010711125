import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useLocation, useMatch } from 'react-router-dom';
import classNames from 'classnames';

import { HelpV2Icon, NotificationIcon, PaletteIcon, ProductsIcon } from '@utilities';
import { readAllNotifications, readNotifications } from '@api/notifications';
import { transformNotificationData } from '@api/notifications/utilities/helpers';

import AccountDropdown from './components/AccountDropdown';
import ProductsDropdown from './components/ProductsDropdown';
import HelpDropdown from './components/HelpDropdown';
import NotificationDropdown from './components/NotificationDropdown';
import ThemeDropdown from './components/ThemeDropdown';

import { ReactComponent as NumeratorLogo } from './assets/data-wave-logo.svg';
import numeratorNLogo from './assets/logo.png';

import styles from './_index.module.scss';

const Header = ({ routes }) => {
  const [hasAllNotifications, setHasAllNotifications] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState('');
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);

  const { firstName, permissions } = useSelector((state) => state?.user?.data);
  const { notification } = useSelector((state) => state?.websocket);

  const { pathname } = useLocation();
  const { pathnameBase: url } = useMatch('/dashboard/*');
  const helpDropdown = useRef(null);
  const productDropdown = useRef(null);
  const notificationDropdown = useRef(null);
  const settingsDropdown = useRef(null);
  const themeDropdown = useRef(null);

  const handleReadAllNotifications = async () => {
    try {
      await readAllNotifications();
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    const getNotifications = async () => {
      try {
        setIsLoading(true);
        const response = await readNotifications({ page });
        const results = response?.data?.results;
        setNotifications([...notifications, ...results]);
        setHasNewNotification(results.some((notification) => notification.isRead === false));
        if (!response?.data?.meta?.next) setHasAllNotifications(true);
        setIsLoading(false);
      } catch (error) {
        console.warn(error);
      }
    };
    getNotifications();
  }, [page]);

  useEffect(() => {
    if (notification?.type === 'notification_center') {
      const newNotifications = [transformNotificationData(notification), ...notifications];
      setNotifications(newNotifications);
      setHasNewNotification(newNotifications.some((notification) => notification.isRead === false));
    }
  }, [notification]);

  return (
    <header
      aria-level="1"
      className={classNames(styles['header'], {
        [styles['is-data-wave']]: permissions?.canAccessDataWave,
      })}
      data-testid="header"
      role="heading"
    >
      <div className={styles['header-content']} data-testid="header-content">
        <div>
          <Link to={url} data-testid="header-content-logo-link">
            {permissions?.canAccessDataWave ? (
              <NumeratorLogo />
            ) : (
              <img alt="insights-logo" data-testid="header-content-logo" src={numeratorNLogo} />
            )}
            {!permissions?.canAccessDataWave && <div>Insights</div>}
          </Link>
          <ul data-testid="header-content-routes">
            {routes.map((route, routeIndex) => {
              const link = `${url}/${route?.path}`;
              const isActive = pathname?.includes(link) ? styles['is-active'] : '';

              return route?.is_hidden ? null : (
                <li
                  key={routeIndex}
                  className={isActive}
                  data-testid={`header-content-${route?.id}`}
                >
                  <Link to={link} className={isActive}>
                    {route?.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <ul className={styles['header-icon-actions']}>
            <li>
              <button
                aria-label="Help button"
                className={styles['header-icon-button']}
                data-testid="header-content-help-icon"
                onClick={() => setActiveDropdown('help')}
              >
                <HelpV2Icon ref={helpDropdown} />
              </button>
              <HelpDropdown
                isHelpDropdownActive={activeDropdown === 'help'}
                setActiveDropdown={setActiveDropdown}
                target={helpDropdown.current}
              />
            </li>
            <li>
              <button
                aria-label="Other products button"
                className={styles['header-icon-button']}
                data-testid="header-content-product-icon"
                onClick={() => setActiveDropdown('product')}
              >
                <ProductsIcon ref={productDropdown} />
              </button>
              <ProductsDropdown
                isProductsDropdownActive={activeDropdown === 'product'}
                setActiveDropdown={setActiveDropdown}
                target={productDropdown.current}
              />
            </li>
            <li>
              <button
                aria-label="Theme Options"
                className={styles['header-icon-button']}
                data-testid="header-content-theme"
                onClick={() => setActiveDropdown('theme')}
                ref={themeDropdown}
              >
                <PaletteIcon />
              </button>
              <ThemeDropdown
                isActive={activeDropdown === 'theme'}
                setActiveDropdown={setActiveDropdown}
                target={themeDropdown.current}
              />
            </li>
            <li>
              <button
                aria-label="Notifications button"
                className={styles['header-icon-button']}
                data-testid="header-notification-center-icon"
                onClick={() => {
                  setActiveDropdown('notification');
                  handleReadAllNotifications();
                  setHasNewNotification(false);
                }}
              >
                <NotificationIcon ref={notificationDropdown} />
                {hasNewNotification && (
                  <span
                    data-testid="new-notification"
                    className={styles['header-notification-badge']}
                  ></span>
                )}
              </button>
              <NotificationDropdown
                hasAllNotifications={hasAllNotifications}
                isNotificationDropdownActive={activeDropdown === 'notification'}
                isLoading={isLoading}
                notifications={notifications}
                page={page}
                setActiveDropdown={setActiveDropdown}
                setPage={setPage}
                target={notificationDropdown.current}
              />
            </li>
            <li>
              <button
                aria-label="Account button"
                className={styles['header-content-account-icon']}
                data-testid="header-content-account-icon"
                onClick={() => setActiveDropdown('account')}
                ref={settingsDropdown}
              >
                <p>{firstName?.charAt(0)}</p>
              </button>
              <AccountDropdown
                isAccountDropdownActive={activeDropdown === 'account'}
                setActiveDropdown={setActiveDropdown}
                target={settingsDropdown.current}
              />
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  routes: PropTypes.array,
};

export default Header;
