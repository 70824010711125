export default {
  data: [
    {
      id: '3',
      type: 'ESSAY',
      segments: [
        {
          id: 'Overall',
          name: 'Overall',
          table: {
            columns: [
              {
                accessor: 'response_value',
                header: 'Responses',
              },
            ],
            data: [
              {
                response_value:
                  "Your product is good but the prices are outrageous! Profit and bonuses are extremely important, but someone at Kellogg's needs to look at how these bonuses are affecting everyday Americans. Bonuses of of over $2.5 million at a time when everyday prices are killing the consumers.",
              },
              {
                response_value:
                  'Make a version without artificial dyes (use ones from vegetables or other similar sources) , significantly less sugar, and maybe no folic acid. At least one that has maybe 1/4 less the amount of sugar and no artificial sweeteners or stevia to replace it. ',
              },
              {
                response_value:
                  "Dear CEO,\r\nThe product is amazing, it's been consistently the same for at least 39 yrs that I can remember.\r\nI honestly can't think of a way to change it. \r\n\r\nMaybe make it a small change to be a little softer, but otherwise. It's amazing.\r\n",
              },
              {
                response_value:
                  "I honestly don't think there's anything wrong with this product. However it might be exciting to try out new & unique variations or flavors of froot\r\nLoops in the future. I would definitely\r\nBe inclined to purchase & try something like that!",
              },
              {
                response_value:
                  'There may be interest in giving fruitloops another element to compliment the base product. Fruit loops is pretty "one note" and could be complimented with something additional that adds texture and complimentary flavor like oat clusters',
              },
              {
                response_value:
                  "I would say that I like the fact that the cereal stays fairly crispy in the bowl with milk Added. This is a good feature to me, because there's nothing worse than cereal That gets soggy shortly after adding milk.",
              },
              {
                response_value:
                  'I love that the coloring has been changed from artificial to real food. I wish there was a way to use a more natural sweetening option rather than sugar. Will always be one of my top 3 favorite cereals.',
              },
              {
                response_value:
                  'I do not eat many different cereal types. Froot Loops are one of my favorite cereals to choose from when I order cereal. Thank you so much for producing Froot Loops just the way they are. ',
              },
              {
                response_value:
                  "I would ask for more convenience with having a product that's portioned and has a lid or easier ability to travel with as adults who love cereal usually find themselves on the go",
              },
              {
                response_value:
                  'I would have them change any sugar substitute that could be harmful to your child or yourself such as sucralose or any substitute sugar that is harmful or known to be harmful.',
              },
              {
                response_value:
                  "To whom it may concern:I would appreciate it so very much if you would keep Kellogg's Froot Loops the same. The taste is wonderful as I've enjoyed this taste since childhood.",
              },
              {
                response_value:
                  'I really enjoy the Kelloggs Fruit Loops! Love the delicious fruit flavors so much that we purchase it a good bit but could you please make it more affordable! ',
              },
              {
                response_value:
                  'Keep the colorfulness and the flavors because they are good to the kids and adults. We love the colors and how they taste with or without milk. ',
              },
              {
                response_value:
                  'I would like to thank Kelloggs Foot Loop for their product. Foot Loops has been around for a long time in my family. Nothing needs to be changed',
              },
              {
                response_value:
                  "Dear CEO, your product is great just the way it is. I respectfully ask that you don't mess with a good thing and keep the product as it is. ",
              },
              {
                response_value:
                  "I have purchased this product for years.  My sons ate this product and now my grandchildren eat this product.  I wouldn't change anything.",
              },
              {
                response_value:
                  'Dear CEO\r\n\r\nI would love if you made larger quantity or tgst it was sold at BJs or Costco. \r\n\r\nThanking you in advance.\r\n\r\nJackie Sanabria',
              },
              {
                response_value:
                  "If I  was writing to the CEO of Kellogg's Fruit Loops I  would like more marshmallow  shapes and your seasonal colors.",
              },
              {
                response_value:
                  'Keep Fruit Loops the same now and forever! Have enjoyed eating and sharing it with children and now grandchildren.',
              },
              {
                response_value:
                  'A little less sugar and maybe lower the price.   One income family makes it hard to splurge on this type of cereal',
              },
              {
                response_value:
                  'Please do not change a single ingredient of this product as all of my family loves the flavor just the way it is.',
              },
              {
                response_value:
                  "Overall, I highly enjoy this cereal. I wouldn't eat it often, due to sugar content, but the flavors are tasty!",
              },
              {
                response_value:
                  "I don't know    I am not suppose to have sugary foods     They r for my grandson    Maybe add some fiber to   ",
              },
              {
                response_value:
                  'FROOT LOOPS HAVE ALWAYS BEEN A FAVORITE OF MINE SIBCE I WAS A CHILD.   THANK YOU FOR  ALWAYS BRING THE BEST.',
              },
              {
                response_value:
                  "Please don't change anything. I love these and the taste of them. I've had them since I was a child myself ",
              },
              {
                response_value:
                  'To always sale this product because is really good cereal and I grew up eating this cereal … I love it ',
              },
              {
                response_value:
                  "Keep up the good work! Our family has always loved Froot Loops! (Even me 50 years ago in the 70's!!!)",
              },
              {
                response_value:
                  "Honestly I don't think I would change a thing It's a very popular brand and it speaks for itself. ",
              },
              {
                response_value:
                  'We love that we can make edible jewelry for fun snacks :) helps the picky eaters actually eat.',
              },
              {
                response_value:
                  "Don't change a thing!!!  Great product.  No need to change a product that doesn't need change",
              },
              {
                response_value:
                  'My children ate this when they were little and grandchildren and now my great grand children ',
              },
              {
                response_value:
                  'Nothing. Fruit loops is a great product. It has remained consistent throughout the years.',
              },
              {
                response_value:
                  'I would change nothing. Keep everything the way it is. Probably more organic ingredients ',
              },
              {
                response_value:
                  "I wish there's a non GMO, sugar free, dye free and artificials free of the same product.",
              },
              {
                response_value:
                  'I would say keep everything like it is especially the marshmallows they make it better',
              },
              {
                response_value:
                  "Please don't change anything with FruiLoops like so many other flavors have had done ",
              },
              {
                response_value:
                  "Please keep the Kellogg's fruit loops exactly like they are because they are perfect.",
              },
              {
                response_value:
                  "Please keep the product the same  nothing needs changed it's absolutely delicious ",
              },
              {
                response_value:
                  "More in the box. It's constantly getting less and less cereal in the same size box",
              },
              {
                response_value:
                  'Good afternoon \r\n\r\nMy family just love to eat front loops at home for breakfast. ',
              },
              {
                response_value:
                  "Please don't make the loops smaller to save money or change the fruity flavor ",
              },
              {
                response_value:
                  "The flavor is great, my kids love it, I just wish there wasn't so much sugar!",
              },
              {
                response_value:
                  "Don't change Anything, the recipe is perfect , maybe try different shapes! ",
              },
              {
                response_value:
                  'I would ask the CEO of Kelloggs to donate to charities in my neighborhood ',
              },
              {
                response_value:
                  'This is an awesome cereal and you can make more flavors such as mango etc',
              },
              {
                response_value:
                  'I would say make even more exotic flavors.  Keep making what you make. ',
              },
              {
                response_value:
                  'The artificial colors of the cereal. Add nature flavors like coca . ',
              },
              {
                response_value:
                  'Please add more marshmallows. Can never have too many marshmallows!',
              },
              {
                response_value:
                  "It's eye catching with the colors but makes you stay for the taste",
              },
              {
                response_value: 'Remove toxic ingredients and manufacture same as sold in Europe ',
              },
              {
                response_value: "I don't believe anything should be changed about Froot Loops.",
              },
              {
                response_value: "I don't think they should change any about it. Perfect as is!",
              },
              {
                response_value: 'There is nothing wrong with froot loops, leave the original ',
              },
              {
                response_value: 'Please keep prices down. Cereal is so important to families ',
              },
              {
                response_value: 'I love your product but can you offer more coupons for them.',
              },
              {
                response_value: 'Flavor to keep & improve nutrition/reduce sugar to change.',
              },
              {
                response_value: 'I like the fruitiness of the flavor and the marshmallows',
              },
              {
                response_value: 'I would ask them to add marshmallows to it and keep it.',
              },
              {
                response_value: 'More coupons. Everyone is on a tight budget these days.',
              },
              {
                response_value: 'Lower prices on all your products. Stop price gouging.',
              },
              {
                response_value: 'Natural food coloring. Stop using artificial coloring ',
              },
              {
                response_value: 'I wish they had different variety, colors and shapes.',
              },
              {
                response_value: 'I buy this for my grandchildren and they love them',
              },
              {
                response_value: 'I would ask them to add less sugar to the product',
              },
              {
                response_value: "Keep the product the same don't change it at all ",
              },
              {
                response_value: 'Lower the prices back. Stop shrinking the boxes. ',
              },
              {
                response_value: 'I emjoy them but would likemore natural coloring',
              },
              {
                response_value: "I wouldn't really ask for any specific changes. ",
              },
              {
                response_value: 'Lower the price not the volume of the product ',
              },
              {
                response_value: 'The kids love the flavor and taste. Thank you ',
              },
              {
                response_value: "Make them how they used to be in the 1980's.",
              },
              {
                response_value: 'Grandchildren love them! Great as they are. ',
              },
              {
                response_value: 'Leave out all the food colorings and dyes. ',
              },
              {
                response_value: "Don't change a thing. They are the best!! ",
              },
              {
                response_value: 'Please keep the quality of the froot loopa',
              },
              {
                response_value: 'Perhaps you could add some natural color.',
              },
              {
                response_value: 'I like the way it is, nothing to change ',
              },
              {
                response_value: 'Keep Fruit Loops EXACTLY as they are!!!',
              },
              {
                response_value: 'Please keep these on the shelves to buy',
              },
              {
                response_value: 'Nothing.. my son likes it the way it is',
              },
              {
                response_value: "Don't change anything! It's delicious!",
              },
              {
                response_value: "Manufacturer's suggested retail price ",
              },
              {
                response_value: "Keep it the way it is it's very good ",
              },
              {
                response_value: "Please don't ever change froot loops!",
              },
              {
                response_value: 'Keep the variety of cereal Box sizes.',
              },
              {
                response_value: 'Thank you for this delicious cereal ',
              },
              {
                response_value: 'Please make this cereal healthier. ',
              },
              {
                response_value: 'Please get rid of toxic ingredients',
              },
              {
                response_value: 'My spouse loves this product as is',
              },
              {
                response_value: 'I would ask to make it less sweet ',
              },
              {
                response_value: 'Change nothing great way they are ',
              },
              {
                response_value: 'I wouldnt change nothing about it',
              },
              {
                response_value: "I'd ask for a reduction in sugar ",
              },
              {
                response_value: 'Nothing it is good the way it is',
              },
              {
                response_value: 'Keep the good flavor and value ',
              },
              {
                response_value: 'Send some free samples please. ',
              },
              {
                response_value: 'I love the taste and the colors',
              },
              {
                response_value: 'Gets soggy a little too fast. ',
              },
              {
                response_value: 'Please keep the fruiti pelican',
              },
              {
                response_value: 'Everything is good and I liked',
              },
              {
                response_value: 'Le pediría más sabores nuevos ',
              },
              {
                response_value: 'All is fine with the product ',
              },
              {
                response_value: 'Nothing everything is awesome',
              },
              {
                response_value: 'The The Big Lie was the most ',
              },
              {
                response_value: 'My child loves Froot Loops ',
              },
              {
                response_value: 'Please keep the price down ',
              },
              {
                response_value: 'Make a gluten free version',
              },
              {
                response_value: 'If I can have bulk cereal ',
              },
              {
                response_value: "It's good the way it is \r\n",
              },
              {
                response_value: 'Keep the value and flavor ',
              },
              {
                response_value: 'The product is really good',
              },
              {
                response_value: 'My grand kids loved them!',
              },
              {
                response_value: "I won't chance a thing \r\n",
              },
              {
                response_value: 'Nothing, my kids enjoy it',
              },
              {
                response_value: 'Nothing they are perfect',
              },
              {
                response_value: 'Add a little more.flavor',
              },
              {
                response_value: 'It is so good keep it up',
              },
              {
                response_value: 'Lower the price please! ',
              },
              {
                response_value: 'Keep it the same please',
              },
              {
                response_value: 'Add more fruity flavors',
              },
              {
                response_value: 'The price and quantity ',
              },
              {
                response_value: "Don't change anything ",
              },
              {
                response_value: "Don't change anything ",
              },
              {
                response_value: 'Is very good the fruit',
              },
              {
                response_value: 'Lower cost, less sugar',
              },
              {
                response_value: "Don't change anything ",
              },
              {
                response_value: 'Do not change anything',
              },
              {
                response_value: 'More cereal in the box',
              },
              {
                response_value: "Don't change anything ",
              },
              {
                response_value: 'Keep product the same',
              },
              {
                response_value: "Nothing it's perfect ",
              },
              {
                response_value: 'Great cereal for kids',
              },
              {
                response_value: 'Make it more heslthyt',
              },
              {
                response_value: 'My grandkids love it',
              },
              {
                response_value: 'Lower sugar content.',
              },
              {
                response_value: 'Keep. Tastes great. ',
              },
              {
                response_value: 'Everything is great',
              },
              {
                response_value: 'Make more flavors ',
              },
              {
                response_value: 'Mes filles adores',
              },
              {
                response_value: 'Do not change it.',
              },
              {
                response_value: 'Keep it the same ',
              },
              {
                response_value: "Nothing it's fine",
              },
              {
                response_value: 'Less sugar please',
              },
              {
                response_value: 'Make them cheaper',
              },
              {
                response_value: 'Keep.up.good work',
              },
              {
                response_value: 'Change the price',
              },
              {
                response_value: 'Love the flavor ',
              },
              {
                response_value: 'Keep it the same',
              },
              {
                response_value: 'Keep it the same',
              },
              {
                response_value: 'Keep it the same',
              },
              {
                response_value: 'Great product \r\n',
              },
              {
                response_value: 'Not applicable ',
              },
              {
                response_value: 'Flavor is great',
              },
              {
                response_value: 'It tastes great',
              },
              {
                response_value: "I wouldn't know",
              },
              {
                response_value: "It's delicious",
              },
              {
                response_value: 'Sugar content',
              },
              {
                response_value: 'Good quality ',
              },
              {
                response_value: 'Not a thing',
              },
              {
                response_value: "I wouldn't ",
              },
              {
                response_value: 'Fine as is',
              },
              {
                response_value: "I wouldn't",
              },
              {
                response_value: 'Delicioso ',
              },
              {
                response_value: 'We love it',
              },
              {
                response_value: 'Delicious ',
              },
              {
                response_value: "Don't know",
              },
              {
                response_value: 'Thank you',
              },
              {
                response_value: 'The price',
              },
              {
                response_value: 'Not sure ',
              },
              {
                response_value: 'No change',
              },
              {
                response_value: 'nothing ',
              },
              {
                response_value: 'Nothing ',
              },
              {
                response_value: 'Nothing ',
              },
              {
                response_value: 'Thanks! ',
              },
              {
                response_value: 'Nothing ',
              },
              {
                response_value: 'Nothing.',
              },
              {
                response_value: 'Nothing ',
              },
              {
                response_value: 'Nothing ',
              },
              {
                response_value: 'Nothing ',
              },
              {
                response_value: 'Nothing ',
              },
              {
                response_value: 'Nothing ',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothing',
              },
              {
                response_value: 'Nothin',
              },
              {
                response_value: 'Great',
              },
              {
                response_value: 'None ',
              },
              {
                response_value: 'None ',
              },
              {
                response_value: 'Ftugg',
              },
              {
                response_value: 'None',
              },
              {
                response_value: 'Not ',
              },
              {
                response_value: 'None',
              },
              {
                response_value: 'None',
              },
              {
                response_value: 'Nonr',
              },
              {
                response_value: 'Wow',
              },
              {
                response_value: 'N/A',
              },
              {
                response_value: 'N/A',
              },
            ],
          },
        },
      ],
    },
  ],
};
