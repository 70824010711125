import PropTypes from 'prop-types';
import { default as ReactSelect } from 'react-select';
import classNames from 'classnames';

import MultiOption from './components/MultiOption';

import styles from './_index.module.scss';

const Select = ({
  className,
  'data-testid': dataTestId,
  error,
  isDisabled,
  isLoading,
  isMulti,
  label,
  onChange,
  options,
  placeholder,
  value,
}) => {
  const otherClassNames = {
    [styles['is-error']]: error,
  };

  // SEARCH COMPATIBILITY FOR LABELS RENDERED AS REACT COMPONENTS, I.E. <Tag />

  const transformOptionLabel = (label) => {
    if (label?.props?.label) return label.props.label;
    if (typeof label === 'number') return label.toString();
    return label;
  };

  const filterOption = ({ label, value }, string) => {
    const optionLabel = transformOptionLabel(label);
    const isMatch = (option) =>
      typeof option === 'string' && option.toLowerCase().includes(string.toLowerCase());

    return isMatch(optionLabel) || isMatch(value);
  };

  return (
    <div data-testid={dataTestId}>
      {label && <label className={styles['select-label']}>{label}</label>}
      <ReactSelect
        className={classNames(styles['select'], className, otherClassNames)}
        classNamePrefix="select"
        closeMenuOnSelect={!isMulti}
        components={isMulti ? { Option: MultiOption } : {}}
        filterOption={filterOption}
        formatGroupLabel={(data) => <h4>{data?.label}</h4>}
        hideSelectedOptions={false}
        isClearable={isMulti}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        onChange={onChange}
        options={options}
        placeholder={placeholder || 'Select option'}
        styles={{
          menu: (base) => ({
            ...base,
            minWidth: '100%',
            width: 'max-content',
          }),
        }}
        value={value}
      />
      {error && <p className={styles['select-error']}>{error}</p>}
    </div>
  );
};

Select.defaultProps = {
  isMulti: false,
  onChange: () => {},
};

Select.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Select;
