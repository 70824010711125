import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Select } from '@utilities';

import { readProfileOptions } from '@api/user';
import { updatePersona } from '@redux/slices/user';

import styles from './_index.module.scss';

const PersonaCapture = ({ isNewUser, setIsActive }) => {
  const { id, persona } = useSelector((state) => state?.user?.data);

  const [selectedIndustry, setSelectedIndustry] = useState(
    persona?.industry ? { label: persona?.industry, value: persona?.industry } : null
  );
  const [industries, setIndustries] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState(
    persona?.discipline
      ? {
          label: persona?.discipline,
          value: persona?.discipline,
        }
      : null
  );
  const [disciplines, setDisciplines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [selectedJobTitle, setSelectedJobTitle] = useState(persona?.jobTitle);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await readProfileOptions();
        setIndustries(response?.data?.industries);
        setDisciplines(response?.data?.disciplines);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };
    getData();
  }, []);

  const updateUser = async () => {
    setIsUpdatingUser(true);

    try {
      await dispatch(
        updatePersona({
          userId: id,
          persona: {
            discipline: selectedDiscipline?.value,
            industry: selectedIndustry?.value,
            jobTitle: selectedJobTitle,
          },
        })
      );
      setIsUpdatingUser(false);
      setIsActive(false);
    } catch (error) {
      console.error(error, 'Could not update user persona.');
      setIsUpdatingUser(false);
    }
  };

  const isDisabled =
    selectedDiscipline === null ||
    selectedIndustry === null ||
    selectedJobTitle === '' ||
    selectedJobTitle.trim().length === 0;

  const personaTitle = isNewUser
    ? 'Help us create the best experience for you'
    : 'Is your information up-to-date?';

  return (
    <div className={styles['persona-capture']}>
      <h1>{personaTitle}</h1>
      <p>
        This information helps us ensure you have the best experience within the platform and the
        most relevant content and reports. <br /> You can also update your profile any time under
        "Settings" in the top right corner. <br /> Any concerns? Feel free to check out our{' '}
        <a
          href="https://www.numerator.com/privacy-notice"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        .
      </p>
      <ul>
        <li>
          <Select
            className={styles['persona-capture-select']}
            isLoading={isLoading}
            label="Industry"
            onChange={setSelectedIndustry}
            options={industries}
            placeholder="Select an industry"
            value={selectedIndustry}
          />
        </li>
        <li>
          <Select
            className={styles['persona-capture-select']}
            isLoading={isLoading}
            label="Discipline"
            onChange={setSelectedDiscipline}
            options={disciplines}
            placeholder="Select a discipline"
            value={selectedDiscipline}
          />
        </li>
        <li>
          <Input
            label="Job Title"
            onChange={setSelectedJobTitle}
            placeholder="Enter job title"
            value={selectedJobTitle}
          />
        </li>
      </ul>
      <hr />
      <div className={styles['persona-capture-footer']}>
        <Button
          isDisabled={isDisabled}
          isLoading={isUpdatingUser}
          onClick={updateUser}
          text="Done"
        />
      </div>
    </div>
  );
};

PersonaCapture.propTypes = {
  isNewUser: PropTypes.bool,
  setIsActive: PropTypes.func,
};

export default PersonaCapture;
