export const GATES = {
  INSTANT_SURVEY_SERVICE: 'instant_survey_service',
  MULTI_QUOTA: 'multi_quota',
  NUMERATOR_AI_BETA: 'numerator_ai_beta',
  PHOTO_UPLOAD: 'photo_upload',
  SURVEY_DASHBOARD: 'survey_dashboard',
  SURVEY_LENGTH_EXTENDED: 'survey_length_extended',
  SURVEY_PRICING_SPLIT: 'survey_pricing_split',
  SURVEY_TEMPLATES: 'survey_templates',
};
