import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { NumeratorInsightsLogoCC } from '@utilities';

import Consultant from './components/Consultant';
import SSO from './components/SSO';

import { containers } from './utilities/helpers';

import styles from './_index.module.scss';

const Login = () => {
  const [container, setContainer] = useState(containers.SSO);

  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const next = queryString.parse(location?.search)?.next || '/';

  return (
    <div className={styles['login']}>
      <div className={styles['login-container']}>
        <div className={styles['login-wrapper']}>
          <NumeratorInsightsLogoCC alt="Numerator Logo" data-testid="sso-logo" />
          {container === containers.SSO && <SSO next={next} setContainer={setContainer} />}
          {container === containers.CONSULTANT && (
            <Consultant next={next} setContainer={setContainer} />
          )}
        </div>
        <small className={styles['login-footer']}>© {currentYear} All Rights Reserved</small>
      </div>
    </div>
  );
};

export default Login;
