import { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGate } from 'statsig-react';

import { Error, LoaderPage } from '@utilities';
import Module from '../../components/Layout/components/Module';
import ModuleList from '../../components/ModuleList';

import { GATES } from '@constants';
import { getFirstAvailableRoute } from '../../utilities/helpers';
import { mapSurveyTypeToRoute, SURVEY_TABS } from './utilities/helpers';
import { readInstantSurveyTypes } from '@api/instant_survey';

const Surveys = ({ surveyRoutes }) => {
  const { isLoading: isStatsigLoading, value: useInstantSurveyService } = useGate(
    GATES.INSTANT_SURVEY_SERVICE
  );

  if (isStatsigLoading) return <LoaderPage />;
  if (!useInstantSurveyService)
    return (
      <Module route={{ routes: surveyRoutes }}>
        <Routes>
          {surveyRoutes.map((route) => (
            <Route
              element={<ModuleList routes={route.routes} type="survey" />}
              key={route.id}
              path={route.path}
            />
          ))}
          <Route
            element={<Navigate replace to={getFirstAvailableRoute(surveyRoutes)} />}
            path="*"
          />
        </Routes>
      </Module>
    );

  return <InstantSurveys />;
};

Surveys.propTypes = {
  surveyRoutes: PropTypes.array,
};

const InstantSurveys = () => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const requestRef = useRef(null);
  const [surveyRoutes, setSurveyRoutes] = useState([]);

  useEffect(() => {
    getSurveyTypes();

    return () => {
      requestRef.current?.abort();
    };
  }, []);

  const getSurveyTypes = async () => {
    requestRef.current = new AbortController();

    try {
      const { results } = await readInstantSurveyTypes({ signal: requestRef.current.signal });
      const surveyTypes = results
        .filter(({ label }) => label === SURVEY_TABS[0].id)
        .map(mapSurveyTypeToRoute);
      const surveyLabs = results
        .filter(({ label }) => label === SURVEY_TABS[1].id)
        .map(mapSurveyTypeToRoute);

      setSurveyRoutes([
        {
          ...SURVEY_TABS[0],
          routes: surveyTypes,
        },
        ...(surveyLabs.length > 0
          ? [
              {
                ...SURVEY_TABS[1],
                routes: surveyLabs,
              },
            ]
          : []),
      ]);
    } catch (error) {
      if (!requestRef.current.signal.aborted) {
        console.log('error', error);
        setError(error);
      }
    }
    setIsLoading(false);
  };

  if (isLoading) return <LoaderPage />;
  if (error) {
    if (error?.code === 'ERR_NETWORK')
      return (
        <Error
          description="Apologies, but our Instant Survey option is currently unavailable. If the problem persists please contact your consultant."
          header="Service Unavailable"
          status={404}
        />
      );
    return <Error />;
  }

  return (
    <Module route={{ routes: surveyRoutes }}>
      <Routes>
        {surveyRoutes.map((route) => (
          <Route
            element={<ModuleList routes={route.routes} type="survey" />}
            key={route.id}
            path={route.path}
          />
        ))}
        <Route element={<Navigate replace to={surveyRoutes[0].path} />} path="*" />
      </Routes>
    </Module>
  );
};

export default Surveys;
