/**
 * transformEssayResponses
 * @param {Object} response
 * @return {Object}
 */
export const transformEssayResponses = (response) => response;

/**
 * transformGlobalFilters
 * @param {Object} filters
 * @param {Object[]} filters.demographic_filters
 * @return {Object}
 */
export const transformGlobalFilters = ({ demographic_filters: demographicFilters }) => ({
  demographicFilters,
});

/**
 * transformSurveyResponses
 * @param {Object} response
 * @return {Object}
 */
export const transformSurveyResponses = (response) => response;
