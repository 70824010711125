import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { maxQuestionError, minQuestions } from '@api/surveys';

import {
  ContractIcon,
  ExpandIcon,
  HelpIcon,
  OverlayTriggerTooltip,
  PlusIcon,
  SaveIcon,
  Toolbar,
} from '@utilities';

import AddQuestionDropdown from '../AddQuestionDropdown';

const QuestionnaireToolbar = ({
  children,
  isDetailedQuestionView,
  maxQuestions,
  onAddQuestion,
  onCreateQuestion,
  onSaveDraft,
  selectedQuestions,
  setIsDetailedQuestionView,
  surveyType,
}) => {
  const [isAddQuestionDropdownActive, setIsAddQuestionDropdownActive] = useState(false);

  const addQuestionButtonRef = useRef(null);

  return (
    <>
      <Toolbar
        tools={[
          ...[
            <div ref={addQuestionButtonRef}>
              <OverlayTriggerTooltip
                content={
                  selectedQuestions.length >= maxQuestions ? maxQuestionError : 'Add Question'
                }
              >
                <button
                  data-testid="add-question-toolbar-button"
                  disabled={selectedQuestions.length >= maxQuestions}
                  onClick={() => setIsAddQuestionDropdownActive(true)}
                >
                  <PlusIcon />
                </button>
              </OverlayTriggerTooltip>
            </div>,
          ],
          ...[
            <OverlayTriggerTooltip
              content={'Change to ' + (isDetailedQuestionView ? 'Overview' : 'Detailed')}
            >
              <button
                data-testid="change-view-button"
                onClick={() => setIsDetailedQuestionView(!isDetailedQuestionView)}
              >
                {isDetailedQuestionView ? <ContractIcon /> : <ExpandIcon />}
                <span>View</span>
              </button>
            </OverlayTriggerTooltip>,
          ],
          ...[
            <OverlayTriggerTooltip content="Save Draft">
              <button
                data-testid="save-draft-button"
                onClick={onSaveDraft}
                disabled={selectedQuestions.length < minQuestions}
              >
                <SaveIcon />
              </button>
            </OverlayTriggerTooltip>,
          ],
          ...[
            <OverlayTriggerTooltip aria-label="Help" content="Help">
              <a
                data-testid="help-link-button"
                href={surveyType.help_url}
                rel="noreferrer"
                target="_blank"
              >
                <HelpIcon />
              </a>
            </OverlayTriggerTooltip>,
          ],
        ]}
      >
        {children}
      </Toolbar>
      <AddQuestionDropdown
        buttonRef={addQuestionButtonRef}
        isDropdownActive={isAddQuestionDropdownActive}
        onAddQuestion={onAddQuestion}
        onCreateQuestion={onCreateQuestion}
        setIsDropdownActive={setIsAddQuestionDropdownActive}
      />
    </>
  );
};

QuestionnaireToolbar.propTypes = {
  children: PropTypes.node,
  isDetailedQuestionView: PropTypes.bool.isRequired,
  maxQuestions: PropTypes.number.isRequired,
  onAddQuestion: PropTypes.func.isRequired,
  onCreateQuestion: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
  setIsDetailedQuestionView: PropTypes.func.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default QuestionnaireToolbar;
