import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  AddToFolderIcon,
  Button,
  IconCTA,
  Input,
  isMaxNameLength,
  ModalV2 as Modal,
  OverlayTriggerTooltip,
  Radio,
  Select,
} from '@utilities';

import styles from './_index.module.scss';

const AddToFolder = ({
  createFolder,
  expandedFolder,
  hasException,
  hasExpiration,
  isHoverAction,
  isSelectedOwned,
  onSubmit,
  readFolders,
  selectedRows,
  type,
  updateFolder,
}) => {
  const OPTIONS = {
    EXISTING: 'EXISTING',
    NEW: 'NEW',
  };

  const [folders, setFolders] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedOption, setSelectedOption] = useState(OPTIONS.EXISTING);

  const isNewFolderSelected = selectedOption === OPTIONS.NEW;
  const isVisible = !hasException && isSelectedOwned && !hasExpiration;

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const selectedRowsIds = selectedRows.map((selectedRow) => selectedRow?.folderEntryId);

      let response;

      if (isNewFolderSelected) {
        response = await createFolder({
          name: newFolderName,
        });
      }

      await updateFolder({
        folderId: isNewFolderSelected ? response?.data?.id : selectedFolder?.value,
        ids: selectedRowsIds,
      });

      setIsActive(false);
      onSubmit({ refreshFolders: true, refreshItems: true });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setNewFolderName('');
    if (isActive) {
      const getFolders = async () => {
        try {
          const response = await readFolders({});

          const data = response?.data?.folders?.map((folder) => {
            return { label: folder?.name, value: folder?.id };
          });

          setFolders(expandedFolder ? [{ label: `All ${type}s`, value: 'root' }, ...data] : data);
          setSelectedFolder(data[0]);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
        }
      };

      getFolders();
    }
  }, [isActive]);

  if (!isVisible) return null;

  return (
    <>
      <OverlayTriggerTooltip content={isHoverAction ? 'Add to Folder' : ''}>
        <IconCTA
          data-testid="add-to-folder-cta"
          icon={<AddToFolderIcon />}
          onClick={() => setIsActive(true)}
          text={isHoverAction ? '' : 'Add to Folder'}
        />
      </OverlayTriggerTooltip>
      <Modal
        buttons={[
          <Button
            key="cancel-btn"
            onClick={() => setIsActive(false)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            key="submit-btn"
            isDisabled={
              isNewFolderSelected
                ? isMaxNameLength(newFolderName) || newFolderName.trim().length === 0
                : !selectedFolder
            }
            isLoading={isLoading}
            onClick={handleSubmit}
            text="Save"
          />,
        ]}
        className={styles['add-to-folder']}
        isActive={isActive}
        onClose={() => setIsActive(false)}
        title="Add to Folder"
      >
        <p>Create a new folder or move to an existing one.</p>
        <ul>
          <li>
            <Radio
              isChecked={selectedOption === OPTIONS.EXISTING}
              label="Existing Folder"
              onChange={setSelectedOption}
              value={OPTIONS.EXISTING}
            />
            {selectedOption === OPTIONS.EXISTING && (
              <Select
                isLoading={isLoading}
                onChange={setSelectedFolder}
                options={folders}
                value={selectedFolder}
              />
            )}
          </li>
          <li>
            <Radio
              isChecked={isNewFolderSelected}
              label="New Folder"
              onChange={setSelectedOption}
              value={OPTIONS.NEW}
            />
            {isNewFolderSelected && (
              <Input
                error={
                  isMaxNameLength(newFolderName)
                    ? 'Maximum number of characters reached. Try something shorter.'
                    : ''
                }
                onChange={setNewFolderName}
                placeholder="Enter name"
                value={newFolderName}
              />
            )}
          </li>
        </ul>
      </Modal>
    </>
  );
};

AddToFolder.propTypes = {
  createFolder: PropTypes.func,
  expandedFolder: PropTypes.object,
  hasException: PropTypes.bool,
  hasExpiration: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  isSelectedOwned: PropTypes.bool,
  onSubmit: PropTypes.func,
  readFolders: PropTypes.func,
  selectedRows: PropTypes.array,
  type: PropTypes.string,
  updateFolder: PropTypes.func,
};

export default AddToFolder;
