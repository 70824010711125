import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  FOLDER_ID_CUSTOM,
  FOLDER_ID_RECOMMENDED,
  MODEL_TYPES,
  QUESTIONS_PER_PAGE,
  readQuestionFolder,
} from '@api/instant_survey';

import {
  Breadcrumbs,
  Button,
  Error,
  LoaderSkeleton,
  ModalV2 as Modal,
  Pagination,
  Search,
  Tabs,
  useDebounce,
} from '@utilities';

import Results from './components/Results';

import styles from '../../../../../CreateSurvey/components/Questionnaire/components/ModalQuestionsLibrary/_index.module.scss';

const folders = [
  { label: 'Recommended', value: FOLDER_ID_RECOMMENDED },
  { label: 'Custom', value: FOLDER_ID_CUSTOM },
];

const ModalQuestionLibrary = ({
  maxQuestions,
  onClose,
  selectedQuestions,
  setSelectedQuestions,
  surveyType,
}) => {
  const [activeTab, setActiveTab] = useState(folders[0].value); // this could possibly be selectedFolders[0]
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [questionsToAdd, setQuestionsToAdd] = useState([]);
  const [results, setResults] = useState([]);
  const [searchCount, setSearchCount] = useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [searchQuery, setSearchQuery, { isDebouncing, signal }] = useDebounce('');
  const searchSignal = useRef(null);
  const [selectedFolders, setSelectedFolders] = useState([folders[0]]);

  const selectedFolderId = selectedFolders[selectedFolders.length - 1].value;

  useEffect(() => {
    if (searchPage !== 0) setSearchPage(0);
  }, [selectedFolderId, signal]);

  useEffect(() => {
    getQuestions();
    return () => searchSignal.current?.abort();
  }, [searchPage, selectedFolderId, signal]);

  // const availableQuestions = useMemo(() => {
  //   return results.filter((question) => !selectedQuestions.some(({ id }) => id === question.id));
  // }, [results, selectedQuestions]);

  const addQuestionsToSurvey = () => {
    setSelectedQuestions([...selectedQuestions, ...questionsToAdd]);
    onClose();
  };

  const getQuestions = async () => {
    if (searchSignal.current) {
      searchSignal.current.abort();
    }
    searchSignal.current = new AbortController();
    setIsSearching(true);
    setError(null);

    try {
      const response = await readQuestionFolder({
        folder: selectedFolderId,
        instantSurveyType: surveyType.id,
        offset: searchPage > 0 ? searchPage * QUESTIONS_PER_PAGE : null,
        ...(searchQuery ? { search: searchQuery } : {}),
        signal: searchSignal.current.signal,
      });
      setSearchCount(response.count);
      setResults(response.contents);
      setIsSearching(false);
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        setError(error);
        setIsSearching(false);
      }
    }
  };

  const handleTabSelect = (value) => {
    setActiveTab(value);
    setSearchPage(0);
    setSelectedFolders([folders.find((folder) => folder.value === value)]);
  };

  const onSelectQuestion = (question) => {
    if (question.type === MODEL_TYPES.QUESTION_FOLDER) {
      setSelectedFolders([...selectedFolders, { label: question.title, value: question.id }]);
    } else {
      const selectedQuestion = results.find(({ id }) => id === question.id);
      const questionIndex = questionsToAdd.findIndex(({ id }) => id === question.id);
      if (questionIndex >= 0) {
        questionsToAdd.splice(questionIndex, 1);
        setQuestionsToAdd([...questionsToAdd]);
      } else if (questionsToAdd.length < maxQuestions) {
        setQuestionsToAdd([...questionsToAdd, selectedQuestion]);
      }
    }
  };

  const actionButtons = [
    <Button key="modal-cancel-button" onClick={onClose} text="Cancel" variant="secondary" />,
    <Button
      key="modal-confirm-button"
      isDisabled={!questionsToAdd.length}
      onClick={addQuestionsToSurvey}
      text="Add to Survey"
    />,
  ];

  return (
    <Modal
      buttons={actionButtons}
      className="question-library-modal"
      isActive={true}
      onClose={onClose}
      size="large"
      title="Question Library"
    >
      {error ? (
        <Error status={error?.response?.status} />
      ) : (
        <>
          <Search
            className={styles['modal-questions-library-search']}
            isSearching={(isDebouncing || isSearching) && searchQuery !== ''}
            onChange={setSearchQuery}
            value={searchQuery}
          />
          <Tabs onChange={handleTabSelect} options={folders} value={activeTab} />
          <div className={styles['modal-questions-library-list']}>
            {selectedFolders.length > 1 && (
              <Breadcrumbs
                options={[
                  ...selectedFolders.slice(1).map(({ label }, index) => ({
                    label,
                    onClick: () => setSelectedFolders(selectedFolders.slice(0, index + 1)),
                  })),
                ]}
              />
            )}
            {(isDebouncing || isSearching) && (
              <LoaderSkeleton>
                <rect x="0" y="0" rx="2" ry="2" width="200" height="15" />
                <rect x="0" y="25" rx="2" ry="2" width="175" height="15" />
                <rect x="0" y="50" rx="2" ry="2" width="225" height="15" />
                <rect x="0" y="75" rx="2" ry="2" width="200" height="15" />
                <rect x="0" y="100" rx="2" ry="2" width="225" height="15" />
              </LoaderSkeleton>
            )}
            {!(isDebouncing || isSearching) && results && (
              <>
                <Results
                  isSearching={isDebouncing || isSearching}
                  maxSelectableQuestions={maxQuestions - selectedQuestions.length}
                  onSelectQuestion={onSelectQuestion}
                  questions={results}
                  questionsToAdd={questionsToAdd}
                  searchQuery={searchQuery}
                  selectedQuestions={selectedQuestions}
                />
                {searchCount > QUESTIONS_PER_PAGE && (
                  <Pagination
                    forcePage={searchPage}
                    onPageChange={(event) => setSearchPage(event.selected)}
                    pageCount={Math.ceil(searchCount / QUESTIONS_PER_PAGE)}
                    show
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

ModalQuestionLibrary.propTypes = {
  maxQuestions: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
  setSelectedQuestions: PropTypes.func.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default ModalQuestionLibrary;
