import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ModalV2 as Modal } from '@utilities';

import { updateAreaOfInterest } from '@api/user';
import { updatePersona } from '@redux/slices/user';

import AreaOfInterest from './components/AreaOfInterest';
import PersonaCapture from './components/PersonaCapture';
import Profile from './components/Profile';
import RecommendedReports from './components/RecommendedReports';

import { getDifferenceInDays } from './utilities/helpers';

import styles from './_index.module.scss';

const ModalOnboarding = () => {
  const { id, isNewUser, persona, routes } = useSelector((state) => state?.user?.data);

  const [isActive, setIsActive] = useState(
    process.env.REACT_APP_ONBOARDING ? !persona?.proficiency : isNewUser
  );
  const [step, setStep] = useState(1);
  const [selectedProfile, setSelectedProfile] = useState({
    discipline: persona?.discipline
      ? {
          label: persona?.discipline,
          value: persona?.discipline,
        }
      : null,
    industry: persona?.industry ? { label: persona?.industry, value: persona?.industry } : null,
    jobTitle: persona?.jobTitle,
    proficiency: persona?.proficiency
      ? { label: persona?.proficiency, value: persona?.proficiency }
      : null,
  });

  const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState({
    product: null,
    categories: null,
    place: null,
  });

  const dispatch = useDispatch();
  const isSurveyOnly =
    routes?.find((route) => route?.id === 'insights-reports')?.routes?.length === 0;

  const handleStep1Next = async () => {
    try {
      await dispatch(
        updatePersona({
          userId: id,
          persona: {
            discipline: selectedProfile?.discipline?.value,
            industry: selectedProfile?.industry?.value,
            jobTitle: selectedProfile?.jobTitle,
            proficiency: selectedProfile?.proficiency?.value,
          },
        })
      );
      if (isNewUser && !isSurveyOnly) {
        setStep(2);
      } else {
        setIsActive(false);
      }
    } catch (error) {
      console.error(error, 'Could not update user persona.');
    }
  };

  const handleStep2Next = async () => {
    try {
      await updateAreaOfInterest({
        areaOfInterest: selectedAreaOfInterest,
      });
      setStep(3);
    } catch (error) {
      console.error(error, 'Could not update area of interest.');
    }
  };

  const handleBack = () => {
    setStep(1);
  };

  useEffect(() => {
    if (getDifferenceInDays({ date: persona?.lastUpdated }) > 365) {
      setIsActive(true);
    }
  }, []);

  const isStep1Disabled =
    !selectedProfile?.discipline ||
    !selectedProfile?.industry ||
    !selectedProfile?.jobTitle ||
    !selectedProfile?.proficiency;

  const isStep2Disabled = !selectedAreaOfInterest?.product || !selectedAreaOfInterest?.categories;
  const totalSteps = 3;

  return process.env.REACT_APP_ONBOARDING ? (
    <Modal
      buttons={
        (step === 1 && [
          <Button
            isDisabled={isStep1Disabled}
            key="button"
            onClick={handleStep1Next}
            text={isNewUser ? 'Next' : 'Done'}
          />,
        ]) ||
        (step === 2 && [
          <Button key="back-button" onClick={handleBack} text="Back" variant="secondary" />,
          <Button
            isDisabled={isStep2Disabled}
            key="next-button"
            onClick={handleStep2Next}
            text="Next"
          />,
        ]) ||
        (step === 3 && [
          <Button
            key="button"
            onClick={() => setIsActive(false)}
            text="Skip"
            variant="secondary"
          />,
        ])
      }
      isActive={isActive}
    >
      <div className={styles['modal-onboarding']}>
        {step === 1 && (
          <Profile selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile} />
        )}
        {step === 2 && (
          <AreaOfInterest
            selectedAreaOfInterest={selectedAreaOfInterest}
            setSelectedAreaOfInterest={setSelectedAreaOfInterest}
          />
        )}
        {step === 3 && <RecommendedReports />}
      </div>
      {isNewUser && (
        <div className={styles['modal-onboarding-step-indicator']}>
          {Array.from({ length: totalSteps }, (_, i) => (
            <span className={`${step === i + 1 ? styles.active : ''}`} key={i} />
          ))}
        </div>
      )}
    </Modal>
  ) : (
    <Modal isActive={isActive} size="large">
      <PersonaCapture isNewUser={isNewUser} key={nanoid()} setIsActive={setIsActive} />
    </Modal>
  );
};

export default ModalOnboarding;
