import breakfasTrends from './assets/breakfas-trends.png';
import breakthroughBeauty from './assets/breakthrough-beauty.png';
import privateLabelTracker from './assets/private-label-tracker.png';
import tiktokBan from './assets/tiktok-ban.png';

import styles from './_index.module.scss';

const ThoughtLeadership = () => {
  return (
    <div className={styles['thought-leadership']}>
      <h2>
        <strong>Thought Leadership</strong>
      </h2>
      <p>Know more about the changing omnichannel marketplace.</p>
      <hr></hr>
      <ul>
        <li>
          <a
            href="https://www.numerator.com/resources/report/breakthrough-beauty?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={breakthroughBeauty} alt="breakthrough-beauty" />
            <small>REPORT</small>
            <p>Top Trends in Beauty</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.numerator.com/private-label-trends?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={privateLabelTracker} alt="private-label-trends" />
            <small>TRACKER</small>
            <p>Private Label Tracker</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.numerator.com/resources/webinar/winning-consumer-loyalty-breakfast?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={breakfasTrends} alt="breakfas-trends" />
            <small>WEBINAR</small>
            <p>Eating Out Trends</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.numerator.com/resources/blog/tiktok-ban-insights?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={tiktokBan} alt="tiktok-ban-insights" />
            <small>ARTICLE</small>
            <p>Insights on the TikTok Ban</p>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ThoughtLeadership;
