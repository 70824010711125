import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useGate } from 'statsig-react';

import {
  Accordion,
  Announcement,
  Button,
  Card,
  EditableTitle,
  Error,
  FlowArrowIcon,
  formatData,
  LoaderView,
  Tag,
  TargetIcon,
} from '@utilities';
import { GATES } from '@constants';
import { createSurvey, minSampleSize, readSampleValidation } from '@api/surveys';

import AdvancedPrompts from '../../../../components/Prompts/components/AdvancedPrompts';
import Cart from '../../../Checkout/components/Cart';
import Madlibs from '../../../../components/Prompts/components/Madlibs';
import ModalSurveyNameValidation from './components/ModalSurveyNameValidation';
import { payWithCredits } from '../../../Checkout/helpers/constants';
import SurveyToolbar from '../SurveyToolbar';

import styles from './_index.module.scss';

const Review = ({
  onSaveDraft,
  onSubmit,
  questionnairePrice,
  samplePrice,
  setActiveStep,
  survey,
  surveyType,
  updateSurvey,
}) => {
  const { firstName, lastName, surveyInfo } = useSelector((state) => state?.user?.data);

  // create a copy of userSurveyCreditsRemaining so it doesn't change visually
  // when the onSubmit call updates the redux user credits remaining
  const [creditsRemainingAtReview] = useState(surveyInfo?.userCredits);
  const [error, setError] = useState(null);
  const [isReviewValidationModalVisible, setIsReviewValidationModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const [sample, setSample] = useState(null);

  const { value: isSurveyPricingSplit } = useGate(GATES.SURVEY_PRICING_SPLIT);

  const controller = new AbortController();
  const signal = controller.signal;
  const timeoutRef = useRef(null);

  const totalResponsesRequested = survey.groups.reduce(
    (acc, { responseCount }) => acc + responseCount,
    0
  );
  const panelistRatio = sample?.totalPotentialUsers / totalResponsesRequested;
  const expectedCompletionDays = panelistRatio >= 10 ? '1-2' : panelistRatio >= 5 ? '2-5' : '3-7';

  useEffect(() => {
    if (!survey.validationKey) {
      setActiveStep(1);
    } else {
      getSurveyValidation();
    }

    return () => {
      controller.abort();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const getSurveyValidation = async () => {
    try {
      const validationResponse = await readSampleValidation({
        signal,
        validationKey: survey.validationKey,
      });
      if (validationResponse.finished) {
        setSample(validationResponse);
        setIsValidating(false);
      } else {
        timeoutRef.current = setTimeout(() => getSurveyValidation(survey.validationKey), 5000);
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error(error);
        setError(error);
      }
    }
  };

  const handleSaveDraft = () => {
    if (!survey.title) {
      setIsReviewValidationModalVisible(true);
      return;
    }
    onSaveDraft();
  };

  const handleSubmit = async () => {
    if (!survey.title) {
      setIsReviewValidationModalVisible(true);
      return;
    }

    setIsSubmitting(true);
    try {
      const { groups, ...surveyData } = survey;
      const response = await createSurvey({
        answers: groups[0].answers,
        numberOfResponsesRequested: groups[0].responseCount,
        ...surveyData,
        status: null,
      });
      onSubmit(response);
    } catch (error) {
      console.error(error);
      setError(error);
      setIsSubmitting(false);
    }
  };

  if (error) {
    return <Error status={403} />;
  }

  if (isValidating) {
    return (
      <LoaderView heading="Validating Sample Size...">
        <p>
          Just making sure there are enough panelists available to complete this survey, as
          requested. Validation should only take a moment.
        </p>
      </LoaderView>
    );
  }

  return (
    <div className={styles['review']}>
      <div>
        <SurveyToolbar helpUrl={surveyType.help_url} onSaveDraft={handleSaveDraft}>
          <EditableTitle onChange={(title) => updateSurvey({ title })} value={survey.title} />
        </SurveyToolbar>
        <h2>You're almost ready to launch your survey</h2>
        <p>
          Our team will review your survey for quality control before it is set active for
          panelists. Typically this takes less than 24 business hours.
        </p>
      </div>

      <div className={styles['review-column-layout']}>
        <div className={styles['review-column']}>
          <Card className={styles['review-card']} padding="x-large">
            <div className={styles['review-section']}>
              <h3>{surveyType.name}</h3>
              {sample.totalPotentialUsers >= minSampleSize && (
                <div>
                  <p>
                    Estimated Completion:&nbsp;
                    <strong>{expectedCompletionDays} business days</strong>
                  </p>
                  <p>We will send an email notification when complete.</p>
                </div>
              )}
            </div>
            <hr />
            <div className={styles['review-section']}>
              <h5>
                <strong>Panelists</strong> (<button onClick={() => setActiveStep(0)}>edit</button>)
              </h5>
              {sample.totalPotentialUsers < minSampleSize && (
                <Announcement
                  className={styles['review-announcement']}
                  text="There is not sufficient sample for the selected panelist criteria."
                  variant="error"
                />
              )}
              {sample.totalPotentialUsers >= minSampleSize &&
                sample.expectedCompletes < totalResponsesRequested && (
                  <Announcement
                    className={styles['review-announcement']}
                    text={`Your survey may not collect ${totalResponsesRequested} total responses. You may modify your prompts to include more panelists.`}
                    variant="warn"
                  />
                )}
              <div>
                <Madlibs
                  answers={survey.groups[0].answers}
                  isCompleted
                  madlib={surveyType?.prompts_madlib}
                  madlibAdditional={surveyType?.prompts_madlib_additional}
                  madlibAdditionalLimit={surveyType?.prompts_madlib_additional_limit}
                  prompts={surveyType.prompts}
                />
                {surveyType.advancedPrompts.length > 0 && (
                  <AdvancedPrompts
                    advancedPrompts={surveyType.advancedPrompts}
                    answers={survey.groups[0].answers}
                    isCompleted
                  />
                )}
              </div>
              <div>
                <h4>Available Panelists</h4>
                <p>
                  {formatData({ format: '0,0', value: sample.totalPotentialUsers })}
                  {sample.totalPotentialUsers === 3000 ? '+' : ''}
                </p>
              </div>
              <div>
                <h4>Expected Responses</h4>
                <p>
                  <strong>{formatData({ format: '0,0', value: sample.expectedCompletes })}</strong>
                </p>
              </div>
            </div>

            <div className={styles['review-section']}>
              <h5>
                <strong>Questionnaire</strong> (
                <button onClick={() => setActiveStep(1)}>edit</button>)
              </h5>
              <Accordion
                className={styles['review-questions']}
                isDefaultOpen
                label={`${survey.questions.length} Questions`}
              >
                <ol className={styles['review-question-list']}>
                  {survey.questions.map(({ id, text }) => (
                    <li key={id}>
                      <div>
                        {text}
                        {survey.questionLogic[id]?.skip && (
                          <Tag icon={<FlowArrowIcon />} tooltip="Skippable" />
                        )}
                        {survey.questionLogic[id]?.disqualify && (
                          <Tag icon={<TargetIcon />} tooltip="Disqualifier" />
                        )}
                      </div>
                    </li>
                  ))}
                </ol>
              </Accordion>
            </div>
          </Card>
        </div>

        <div className={styles['review-column']}>
          <Cart
            items={
              isSurveyPricingSplit
                ? [
                    {
                      description: questionnairePrice?.description,
                      name: 'Questionnaire Length',
                      prices: {
                        credits: questionnairePrice?.amount,
                      },
                    },
                    {
                      description: `${totalResponsesRequested} completes`,
                      name: 'Survey Sample',
                      prices: {
                        credits: samplePrice?.amount,
                      },
                    },
                  ]
                : [
                    {
                      name: surveyType.name,
                      prices: {
                        credits: questionnairePrice?.amount + samplePrice?.amount,
                      },
                    },
                  ]
            }
            paymentOption={payWithCredits}
          >
            <Card className={styles['review-credits-card']} padding="small">
              <h4>Credits for {`${firstName} ${lastName}`}</h4>
              <div>
                <p>Available</p>
                <span>{creditsRemainingAtReview.toLocaleString()} Credits</span>
              </div>
              {creditsRemainingAtReview < samplePrice?.amount + questionnairePrice?.amount && (
                <p className={styles['review-credits-warning']}>
                  Not enough credits to launch. Please contact your Numerator team for more
                  information on credits.
                </p>
              )}
            </Card>
          </Cart>

          <div className={styles['review-actions']}>
            <Button
              data-testid="previous-button"
              onClick={() => setActiveStep(1)}
              text="Previous"
              variant="secondary"
            />
            <Button
              data-testid="launch-button"
              isDisabled={
                sample.totalPotentialUsers < minSampleSize ||
                creditsRemainingAtReview < samplePrice?.amount + questionnairePrice?.amount
              }
              isLoading={isSubmitting}
              onClick={handleSubmit}
              text="Launch"
            />
          </div>
        </div>
      </div>

      <ModalSurveyNameValidation
        isActive={isReviewValidationModalVisible}
        onClose={() => setIsReviewValidationModalVisible(false)}
      />
    </div>
  );
};

Review.propTypes = {
  onSaveDraft: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  questionnairePrice: PropTypes.object,
  samplePrice: PropTypes.object,
  setActiveStep: PropTypes.func.isRequired,
  survey: PropTypes.object.isRequired,
  surveyType: PropTypes.object.isRequired,
  updateSurvey: PropTypes.func.isRequired,
};

export default Review;
