import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LogRocket from 'logrocket';
import PropTypes from 'prop-types';

import { ERROR_SAVE_DRAFT } from '@api/instant_survey';
import { HelpIcon, OverlayTriggerTooltip, SaveIcon, toast, Toolbar } from '@utilities';

import ModalSaveAsDraft from '../../../CreateSurvey/components/ModalSaveAsDraft';

const InstantSurveyToolbar = ({
  children,
  insightsType,
  instantSurveyType,
  saveAsDraft,
  survey,
  tools = [],
}) => {
  const [isSaveAsDraftOpen, setIsSaveAsDraftOpen] = useState(false);
  const navigate = useNavigate();

  const activeGroups = survey.groups.filter(({ _delete }) => !_delete);
  const currentTotalResponses = activeGroups.reduce((acc, group) => acc + group.quotaLimit, 0);
  const minimumResponsesRequired = Math.min(
    ...insightsType.samplePricing
      .filter(({ quotaGroups }) => quotaGroups === activeGroups.length)
      .map(({ responses }) => responses)
  );

  const handleSaveAndExit = async () => {
    try {
      await saveAsDraft();
    } catch (error) {
      toast(ERROR_SAVE_DRAFT, { status: 'error' });
      return;
    }

    LogRocket.track('InstantSurvey_SaveAsDraft', { type: survey.instantSurveyType });
    navigate({
      pathname: '/dashboard/workspace/surveys',
      search: new URLSearchParams({ filters: 'is_draft_item' }).toString(),
    });
  };

  return (
    <Toolbar
      tools={[
        ...tools,
        <OverlayTriggerTooltip content="Save Draft">
          <button
            data-testid="save-draft-button"
            disabled={
              currentTotalResponses < minimumResponsesRequired ||
              survey.groups.some(({ name }) => !name.trim()) ||
              !survey.title.trim()
            }
            onClick={() => setIsSaveAsDraftOpen(true)}
          >
            <SaveIcon />
          </button>
        </OverlayTriggerTooltip>,
        ...(instantSurveyType.helpUrl
          ? [
              <OverlayTriggerTooltip aria-label="Help" content="Help">
                <a
                  data-testid="help-link-button"
                  href={instantSurveyType.helpUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <HelpIcon />
                </a>
              </OverlayTriggerTooltip>,
            ]
          : []),
      ]}
    >
      <>
        {children}
        {isSaveAsDraftOpen && (
          <ModalSaveAsDraft
            isActive
            onClose={() => setIsSaveAsDraftOpen(false)}
            onSubmit={handleSaveAndExit}
          />
        )}
      </>
    </Toolbar>
  );
};

InstantSurveyToolbar.propTypes = {
  children: PropTypes.node,
  insightsType: PropTypes.object.isRequired,
  instantSurveyType: PropTypes.object.isRequired,
  saveAsDraft: PropTypes.func.isRequired,
  survey: PropTypes.object.isRequired,
  tools: PropTypes.array,
};

export default InstantSurveyToolbar;
