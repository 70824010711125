import PropTypes from 'prop-types';

import { HelpIcon, OverlayTriggerTooltip, SaveIcon, Toolbar } from '@utilities';

const SurveyToolbar = ({ children, helpUrl, onSaveDraft }) => {
  return (
    <Toolbar
      tools={[
        ...(onSaveDraft
          ? [
              <OverlayTriggerTooltip content="Save Draft">
                <button data-testid="save-draft-button" onClick={onSaveDraft}>
                  <SaveIcon />
                </button>
              </OverlayTriggerTooltip>,
            ]
          : []),
        ...(helpUrl
          ? [
              <OverlayTriggerTooltip aria-label="Help" content="Help">
                <a data-testid="help-link-button" href={helpUrl} rel="noreferrer" target="_blank">
                  <HelpIcon />
                </a>
              </OverlayTriggerTooltip>,
            ]
          : []),
      ]}
    >
      {children}
    </Toolbar>
  );
};

SurveyToolbar.propTypes = {
  children: PropTypes.node,
  helpUrl: PropTypes.string,
  onSaveDraft: PropTypes.func,
};

export default SurveyToolbar;
