import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { LoaderSkeleton } from '@utilities';

import Options from '../../../Options';

import styles from './_index.module.scss';

const AttributeOptionsResults = ({
  activeAnswers,
  activePrompt,
  hasActivePromptLimit,
  isLoading,
  isSurvey,
  onSelectAnswer,
  results,
}) => {
  const attributes = useMemo(
    () =>
      activePrompt?.attributes?.map((attribute) => {
        const data = results?.filter((result) => result?.attributeId === attribute?.id);
        return { id: attribute?.id, name: attribute?.name, data };
      }),
    [results]
  );
  if (isLoading)
    return (
      <LoaderSkeleton height={115}>
        <rect x="0" y="0" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="25" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="50" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="75" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="100" rx="2" ry="2" width="225" height="15" />
      </LoaderSkeleton>
    );

  if (attributes && attributes?.some((attribute) => attribute?.data?.length > 0)) {
    return attributes?.map((attribute, index) => {
      if (attribute?.data?.length === 0) return null;

      return (
        <div className={styles['attribute-options-results']} key={`attribute-result-${index}`}>
          <span className={styles['attribute-options-results-left-column']}>{attribute?.name}</span>
          <div className={styles['attribute-options-results-right-column']}>
            <Options
              activeAnswers={activeAnswers}
              activePrompt={activePrompt}
              hasActivePromptLimit={hasActivePromptLimit}
              isSurvey={isSurvey}
              max={activePrompt?.max}
              onSelectAnswer={onSelectAnswer}
              options={attribute?.data}
            />
          </div>
        </div>
      );
    });
  }

  return <div>No results found.</div>;
};

AttributeOptionsResults.propTypes = {
  activeAnswers: PropTypes.array,
  activePrompt: PropTypes.object,
  hasActivePromptLimit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSurvey: PropTypes.bool,
  onSelectAnswer: PropTypes.func,
  results: PropTypes.array,
};

export default AttributeOptionsResults;
