import { useState } from 'react';
import PropTypes from 'prop-types';
import { useGate } from 'statsig-react';

import { Card, MinusPlusButtons } from '@utilities';
import { GATES } from '@constants';
import { responseCountIncrement, responseCountMinimum } from '@api/surveys';

import AdvancedPrompts from '../../../../../../components/Prompts/components/AdvancedPrompts';
import Madlibs from '../../../../../../components/Prompts/components/Madlibs';
import ModalPromptSearch from '../../../../../../components/ModalPromptSearch';
import { transformSearchAnswers } from '../../../../../../components/Builder/utilities/helpers';

import styles from './_index.module.scss';

const QuotaGroupCard = ({
  canDecrementResponses,
  canIncrementResponses,
  group,
  onUpdate,
  surveyType,
}) => {
  const [activePrompt, setActivePrompt] = useState(null);

  const { value: canMultiQuota } = useGate(GATES.MULTI_QUOTA);

  const maxSingleGroupResponses = surveyType?.samplePricing
    ?.filter(({ quotaGroups }) => quotaGroups === 1)
    .reduce((prev, { responses }) => Math.max(prev, responses), 0);

  const onClearAnswer = (promptId) => {
    let updatedAnswers = { ...group.answers };
    delete updatedAnswers[promptId];
    onUpdate({ answers: updatedAnswers });
  };

  const onClearAnswers = ({ promptId, promptIds }) => {
    if (promptId) {
      onClearAnswer(promptId);
    }
    if (promptIds) {
      let updatedAnswers = { ...group.answers };
      for (const id of promptIds) {
        delete updatedAnswers[id];
      }
      onUpdate({ answers: updatedAnswers });
    }
  };

  const onUpdateAnswers = (newAnswers) => {
    onUpdate({
      answers: {
        ...group.answers,
        [activePrompt.id]: newAnswers,
      },
    });
    setActivePrompt(null);
  };

  const searchAnswers = group.answers
    ? transformSearchAnswers(activePrompt, group.answers, surveyType?.hierarchy_search_prompts)
    : {};

  return (
    <>
      <Card className={styles['quota-group-card']} status="info">
        <h2>{group.name}</h2>
        <div className={styles['quota-group-card-layout']}>
          <div>
            <Madlibs
              answers={group.answers}
              madlib={surveyType?.prompts_madlib}
              madlibAdditional={surveyType?.prompts_madlib_additional}
              madlibAdditionalLimit={surveyType?.prompts_madlib_additional_limit}
              onClearAnswer={onClearAnswer}
              onClearAnswers={onClearAnswers}
              prompts={surveyType.prompts}
              setActivePrompt={setActivePrompt}
            />
            {surveyType.advancedPrompts?.length > 0 && (
              <AdvancedPrompts
                activePrompt={activePrompt}
                advancedPrompts={surveyType.advancedPrompts}
                answers={group.answers}
                onClearAnswer={onClearAnswer}
                setActivePrompt={setActivePrompt}
              />
            )}
          </div>
          <div className={styles['quota-group-card-line']} />
          <div>
            <p>
              Responses
              <span className={styles['quota-group-card-responses']}>
                <strong>{group.responseCount}</strong>
                <MinusPlusButtons
                  disableMinusButton={
                    !canDecrementResponses ||
                    group.responseCount - responseCountIncrement < responseCountMinimum
                  }
                  disablePlusButton={!canIncrementResponses}
                  isNeutral
                  onClickMinus={() =>
                    onUpdate({ responseCount: group.responseCount - responseCountIncrement })
                  }
                  onClickPlus={() =>
                    onUpdate({ responseCount: group.responseCount + responseCountIncrement })
                  }
                />
              </span>
            </p>
            {!canMultiQuota && maxSingleGroupResponses && (
              <>
                <hr />
                <p>
                  Maximum <span>{maxSingleGroupResponses}</span>
                </p>
              </>
            )}
          </div>
        </div>
      </Card>
      {activePrompt && (
        <ModalPromptSearch
          // document={surveyType}
          isSurvey={true}
          onClose={() => setActivePrompt(null)}
          onSubmit={onUpdateAnswers}
          prompt={activePrompt}
          searchAnswers={searchAnswers}
          searchPrompts={surveyType?.hierarchy_search_prompts}
          selectedAnswers={group.answers[activePrompt?.id]}
        />
      )}
    </>
  );
};

QuotaGroupCard.propTypes = {
  canDecrementResponses: PropTypes.bool,
  canIncrementResponses: PropTypes.bool,
  group: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default QuotaGroupCard;
