import axios from '@api';

/**
 * read prompt search results
 * @param  {Array} attributeIds   
 * @param  {String} documentId
 * @param  {String} entryId  
 * @param  {String} promptId
 * @param  {Object} searchAnswers
 * @param  {Array} searchPrompts
 * @param  {String} searchQuery 
 * @param  {String} signal AbortController signal
= * @return {Promise}
 */
export const readSearchResults = ({
  attributeIds,
  documentId,
  entryId,
  promptId,
  searchAnswers = {},
  searchPrompts = [],
  searchQuery,
  signal,
}) => {
  const answers = searchPrompts?.flat().includes(promptId) ? JSON.stringify(searchAnswers) : {};
  return axios({
    method: 'get',
    params: {
      answers,
      attributeId: attributeIds.join(','),
      documentId,
      parent_id: entryId,
      search: searchQuery,
    },
    signal,
    url: `/api/prompts/${promptId}/search`,
    withCredentials: true,
  });
};
