import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { StatsigProvider } from 'statsig-react';

import { switchToBaseUser, userFetch } from '@redux/slices/user';

import { Announcement, LoaderPage, Notification } from '@utilities';

import Footer from '../../components/Footer';
import Header from './components/Header';
import Maintenance from './components/Maintenance';
import ModalOnboarding from './components/ModalOnboarding';
import Page from './components/Page';

import { getFirstAvailableRoute } from './utilities/helpers';

import styles from './_index.module.scss';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user);
  const { baseAccountUser, clientInfo, email, firstName, id, lastName, permissions, routes } =
    user?.data;
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
  const baseAccountId = baseAccountUser?.id;

  useEffect(() => {
    dispatch(userFetch());
  }, []);

  if (user.isAuthenticating || user?.isLoading || routes < 1) return <LoaderPage />;

  if (isMaintenanceMode && !permissions?.canBypassMaintenance) return <Maintenance />;

  return (
    <StatsigProvider
      options={{
        environment: { tier: process.env.REACT_APP_ENVIRONMENT },
      }}
      sdkKey={process.env.REACT_APP_STATSIG}
      user={{
        custom: {
          client_id: clientInfo?.id,
          client_name: clientInfo?.name,
          name: `${firstName} ${lastName}`,
        },
        email,
        userId: id,
      }}
    >
      <div className={styles['dashboard-wrapper']}>
        {isMaintenanceMode && (
          <Announcement
            header="Maintenance Mode"
            isCentered
            text="You are currently bypassing Maintenance Mode"
            variant="warn"
          />
        )}
        {baseAccountId && (
          <Announcement
            className={styles['dashboard-proxy']}
            header="Client Account"
            isCentered
            text={
              <>
                <span>You are logged in as {email} - </span>
                <button onClick={() => dispatch(switchToBaseUser({ baseAccountId, navigate }))}>
                  Switch to Personal Account
                </button>
              </>
            }
            variant="info"
          />
        )}
        <div className={styles['dashboard-body']}>
          <Header routes={routes} />
          <div id="dashboard" className={styles['dashboard']}>
            <Routes>
              {routes
                .filter((route) => !route.is_locked)
                .map((route) => {
                  return (
                    <Route
                      element={<Page route={route} url="dashboard" />}
                      key={route.id}
                      path={`${route.path}/*`}
                    />
                  );
                })}
              <Route element={<Navigate replace to={getFirstAvailableRoute(routes)} />} path="*" />
            </Routes>
          </div>
        </div>
        <Notification />
        <ModalOnboarding />
        <Footer />
      </div>
    </StatsigProvider>
  );
};

export default Dashboard;
