import axios from 'axios';

/**
 * Instant Survey service api instance requiring JWT auth header (setup in redux/sagas/user)
 * @type {Object}
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_INSTANT_SURVEY_ENDPOINT}/instantsurvey`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});

export default api;
