/* eslint-disable react/prop-types */

import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Mousetrap from 'mousetrap';
import { nanoid } from 'nanoid';

import { TableVirtuoso } from 'react-virtuoso';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import { useCellRangeSelection } from 'react-table-plugins';

import CopyToClipboard from '../../components/CopyToClipboard';
import Loader from '../../components/Loader';
import LoaderSkeleton from '../../components/LoaderSkeleton';
import OverlayTriggerTooltip from '../../components/OverlayTriggerTooltip';

import { useScrollSync, useOutsideClick } from '../../helpers';

import SortIndicator from '../SortIndicator';

import {
  copySelectedCellValues,
  getCopyTableValue,
  getCellDown,
  getCellLeft,
  getCellRight,
  getCellUp,
  getSortType,
  sortTypes,
} from './utilities/helpers';

import styles from './_index.module.scss';

const tableId = nanoid();

const TableData = ({
  cellIdSplitBy,
  columns,
  data,
  isLoading,
  isUpdating,
  renderEmptyMessage,
  size,
}) => {
  const [selectedCell, setSelectedCell] = useState(null);

  const {
    cellsById,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    setSelectedCellIds,
    state: { currentSelectedCellIds, selectedCellIds, sortBy },
  } = useTable(
    {
      cellIdSplitBy: '-',
      columns: useMemo(
        () =>
          columns.map((column) => {
            if (column?.columns) {
              return {
                ...column,
                columns: column?.columns.map((subColumn) => ({
                  ...subColumn,
                  sortType: getSortType(subColumn?.format),
                })),
              };
            }
            return { ...column, sortType: getSortType(column?.format) };
          }),
        [columns]
      ),
      data,
      sortTypes: useMemo(() => sortTypes),
    },
    useFlexLayout,
    useSortBy,
    useCellRangeSelection
  );

  const table = useRef(null);
  const cellsSelected = { ...currentSelectedCellIds, ...selectedCellIds };

  const clearSelectedCells = () => {
    setSelectedCell(null);
    setSelectedCellIds({});
  };

  useEffect(() => {
    if (selectedCell) {
      Mousetrap.bind(['up'], (e) => {
        e.preventDefault();

        return setSelectedCell(getCellUp({ cellsById, selectedCell }));
      });
      Mousetrap.bind(['down'], (e) => {
        e.preventDefault();

        return setSelectedCell(getCellDown({ cellsById, rowsLength: rows?.length, selectedCell }));
      });
      Mousetrap.bind(['left'], (e) => {
        e.preventDefault();

        return setSelectedCell(getCellLeft({ cellsById, selectedCell }));
      });
      Mousetrap.bind(['right'], (e) => {
        e.preventDefault();

        return setSelectedCell(getCellRight({ cellsById, selectedCell }));
      });
    }
  }, [selectedCell]);

  useEffect(() => {
    if (selectedCell || Object.keys(selectedCellIds).length > 0) {
      Mousetrap.bind(['command+c', 'ctrl+c'], () => {
        return copySelectedCellValues({ cellsById, cellsSelected, selectedCell });
      });
    }
  }, [selectedCellIds]);

  useEffect(() => {
    clearSelectedCells();
  }, [sortBy]);

  useOutsideClick({ onCallback: () => clearSelectedCells(), ref: table });

  return (
    <div className={styles['table-data']}>
      <div className={styles['table-data-actions']}>
        <CopyToClipboard
          text="Copy Table"
          value={getCopyTableValue({
            columns,
            data: rows,
          })}
        />
      </div>
      {isLoading ? (
        <>
          <div className={styles['table-data-thead']}>
            <TableHeader headerGroups={headerGroups} rows={rows} />
          </div>
          <div>
            {[...Array(size)].map((row, index) => (
              <div
                className={classNames(styles['table-data-tr'], {
                  [styles['is-loading']]: isLoading,
                })}
                key={`row-${index}`}
              >
                <LoaderSkeleton height={19.5} width={2000}>
                  <rect x="0" y="0" rx="2" ry="2" width="100%" height="19.5" />
                </LoaderSkeleton>
              </div>
            ))}
          </div>
        </>
      ) : (
        <TableVirtuoso
          components={COMPONENTS}
          context={{ getTableProps, table, rows }}
          fixedHeaderContent={() => <TableHeader headerGroups={headerGroups} rows={rows} />}
          itemContent={(index) => {
            let row = rows[index];
            row.index = index;

            prepareRow(row);
            return row?.cells?.map((cell) => {
              const gradientBackgroundColor = cell?.column?.gradient
                ? `rgba(0, 227, 107, ${cell?.value / 2})`
                : null;
              let highlight = false;

              if (typeof cell?.column?.getCellHighlight === 'function') {
                highlight = cell?.column?.getCellHighlight({
                  rowIndex: row?.index,
                  value: cell?.value,
                });
              }

              return (
                <div
                  className={classNames(styles['table-data-td'], {
                    [styles['is-active']]: selectedCell?.id === cell?.id,
                    [styles['is-selected']]: cellsSelected[cell?.id],
                    [styles[`is-${highlight}`]]: highlight,
                  })}
                  {...cell.getCellProps({
                    style: { backgroundColor: gradientBackgroundColor },
                  })}
                  {...cell.getCellRangeSelectionProps()}
                  onMouseDown={(e) => {
                    setSelectedCell(cell);
                    cell.getCellRangeSelectionProps().onMouseDown(e);
                  }}
                >
                  <p>{cell.render('Cell')}</p>
                </div>
              );
            });
          }}
          totalCount={rows?.length}
          useWindowScroll
        />
      )}
      {isUpdating && (
        <div className={styles['table-data-loader']}>
          <Loader />
        </div>
      )}
      {!isLoading && !isUpdating && rows?.length === 0 && (
        <div className={styles['table-data-empty-message']}>{renderEmptyMessage()}</div>
      )}
    </div>
  );
};

const TableHeader = ({ headerGroups, rows }) => {
  return (
    <>
      {headerGroups.map((headerGroup, index) => (
        <div
          className={styles['table-data-tr']}
          key={`row-${index}`}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup?.headers?.map((column, index) => {
            const header = column.render('Header');

            return (
              <div
                className={classNames(styles['table-data-th'], {
                  [styles['is-sortable']]: column?.canSort,
                })}
                key={`row-${index}`}
                {...column.getHeaderProps(
                  column.getSortByToggleProps({ title: column.render('Header') })
                )}
              >
                <p>
                  <OverlayTriggerTooltip content={column?.headerTooltip} placement="top">
                    {header} {header && index === 0 && `(${rows?.length})`}
                  </OverlayTriggerTooltip>
                </p>
                <SortIndicator
                  direction={column?.isSortedDesc ? 'desc' : 'asc'}
                  isActive={column?.isSorted}
                  isDisabled={!column?.canSort}
                />
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

const COMPONENTS = {
  FillerRow: ({ height }) => (
    <div>
      <div style={{ height }}></div>
    </div>
  ),
  Table: ({ context: { getTableProps, table }, ...props }) => {
    return (
      <div className={styles['table-data-table']} {...getTableProps()} {...props} ref={table} />
    );
  },
  TableHead: forwardRef(({ ...props }, ref) => {
    const thead = useScrollSync(tableId);

    useImperativeHandle(ref, () => thead.current);
    return <div className={styles['table-data-thead']} {...props} ref={thead} />;
  }),
  TableBody: forwardRef(({ ...props }, ref) => {
    const tbody = useScrollSync(tableId);

    useImperativeHandle(ref, () => tbody.current);

    return <div className={styles['table-data-tbody']} {...props} ref={tbody} />;
  }),
  TableRow: ({ context: { rows }, ...props }) => {
    const index = props['data-index'];
    const row = rows[index];

    return <div className={styles['table-data-tr']} {...props} {...row.getRowProps()} />;
  },
};

TableData.defaultProps = {
  renderEmptyMessage: () => {},
  size: 15,
};

TableData.propTypes = {
  cellIdSplitBy: PropTypes.object,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  renderEmptyMessage: PropTypes.func,
  size: PropTypes.number,
};

export default TableData;
