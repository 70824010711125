import { all, call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import queryString from 'query-string';

import { STORAGE } from '@constants';

import { default as instantSurveyApiService } from '@api/instant_survey/api';
import { readApiServiceToken } from '@api/tokens';
import { readAppContext as readAppContextApi } from '@api/app_context';
import {
  readUser as readUserApi,
  switchToBaseUser as switchToBaseUserApi,
  switchToProxyUser as switchToProxyUserApi,
  updateEmailPreferences,
  updateProfile,
} from '@api/user';
import { getWebsocketEndpoint } from '@api/websocket';
import { login as userLogin, logout as userLogout } from '@api/auth';

import { websocketConnect } from '../../slices/websocket';

import {
  login as loginRequested,
  logout as logoutRequested,
  loginFailed,
  loginSucceeded,
  logoutFailed,
  logoutSucceeded,
  switchToBaseUser,
  switchToProxyUser,
  switchUserFailed,
  switchUserSucceeded,
  userFetch,
  userFetchFailed,
  userFetchSucceeded,
  userUpdateEmailPreferencesFailed,
  userUpdateEmailPreferencesSucceeded,
  updatePersona,
  updatePersonaFailed,
  updatePersonaSucceeded,
  userUpdateEmailPreferencesRequested,
} from '../../slices/user';

export function* readUser() {
  try {
    const [userResponse, appContextResponse, jwtToken] = yield all([
      call(readUserApi),
      call(readAppContextApi),
      call(readApiServiceToken),
    ]);

    const payload = {
      ...userResponse.data,
      ...appContextResponse.data,
    };

    yield put(
      websocketConnect(
        getWebsocketEndpoint({
          clientId: payload?.clientInfo?.id,
          userId: payload?.id,
        })
      )
    );

    instantSurveyApiService.interceptors.request.use(
      (config) => {
        if (jwtToken) {
          config.headers['insights-authorization'] = jwtToken;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    yield put(userFetchSucceeded(payload));
  } catch (error) {
    yield put(userFetchFailed(error));
    if (error?.response && error?.response?.status === 401) {
      yield put(push('/login'));
    }
  }
}

export function* login(action) {
  const { data, navigate, next } = action.payload;

  try {
    yield call(userLogin, data);
    yield call(navigate, next);
    yield put(loginSucceeded());
  } catch (error) {
    yield put(loginFailed({ error }));
  }
}

export function* logout(action) {
  const { isAuthenticated = true, location, navigate } = action.payload;

  try {
    if (isAuthenticated) {
      yield call(userLogout);
    }
    yield put(logoutSucceeded());
  } catch (error) {
    yield put(logoutFailed({ error }));
  } finally {
    yield call(navigate, {
      pathname: '/login',
      replace: true,
      search: queryString.stringify({
        next: location?.search ? location?.pathname + location?.search : location?.pathname,
      }),
    });
  }
}

export function* switchUserToBase({ payload: { baseAccountId, navigate } }) {
  try {
    localStorage.removeItem(STORAGE.filters);
    yield call(switchToBaseUserApi, { baseAccountId });
    yield call(navigate, '/dashboard/user/info');
    yield put(switchUserSucceeded());
    yield put(userFetch());
  } catch (error) {
    yield put(switchUserFailed(error));
    yield put(userFetch());
  }
}

export function* switchUserToProxy({ payload: { navigate, proxyAccountId } }) {
  try {
    localStorage.removeItem(STORAGE.filters);
    yield call(switchToProxyUserApi, { proxyAccountId });
    yield call(navigate, '/');
    yield put(switchUserSucceeded());
  } catch (error) {
    yield put(switchUserFailed(error));
    yield put(userFetch());
  }
}

export function* updateUserEmailPreferences({ payload: { emailOptions, userId }, meta }) {
  try {
    const response = yield call(updateEmailPreferences, { emailOptions, userId });
    yield put(userUpdateEmailPreferencesSucceeded(response.data));
  } catch (error) {
    yield put(userUpdateEmailPreferencesFailed(error));
  }
}

export function* updateUserPersona({ payload: { userId, persona }, meta }) {
  try {
    const response = yield call(updateProfile, { id: userId, profile: persona });

    yield put(updatePersonaSucceeded(response.data));
  } catch (error) {
    yield put(updatePersonaFailed(error));
  }
}

export function* watchForLogin() {
  yield takeLatest(loginRequested.type, login);
}

export function* watchForLogout() {
  yield takeLatest(logoutRequested.type, logout);
}

export function* watchForReadUser() {
  yield takeLatest(userFetch.type, readUser);
}

export function* watchForSwitchToBaseUser() {
  yield takeLatest(switchToBaseUser.type, switchUserToBase);
}

export function* watchForSwitchToProxyUser() {
  yield takeLatest(switchToProxyUser.type, switchUserToProxy);
}

export function* watchForUpdatePersona() {
  yield takeLatest(updatePersona.type, updateUserPersona);
}

export function* watchForUpdateUserEmailPreferences() {
  yield takeLatest(userUpdateEmailPreferencesRequested.type, updateUserEmailPreferences);
}

export function* userSagas() {
  yield all([
    watchForLogin(),
    watchForLogout(),
    watchForReadUser(),
    watchForSwitchToBaseUser(),
    watchForSwitchToProxyUser(),
    watchForUpdatePersona(),
    watchForUpdateUserEmailPreferences(),
  ]);
}
