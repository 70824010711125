import PropTypes from 'prop-types';

import { Button, Card, ModalV2 as Modal, truncateTextWithEllipsis } from '@utilities';

import QuestionLogicCondition from './components/QuestionLogicCondition';

import styles from './_index.module.scss';

const ModalChangesConfirmation = ({
  affectedDisqualifyQuestion,
  affectedLogic,
  destinationIndex,
  onClose,
  question,
  questionLogic,
  reorderQuestions,
  selectedQuestions,
}) => {
  const applyChanges = () => {
    reorderQuestions();
    onClose();
  };

  const actionButtons = [
    <Button key="modal-cancel-button" onClick={onClose} text="Cancel" variant="secondary" />,
    <Button key="modal-confirm-button" onClick={applyChanges} text="Save" />,
  ];

  return (
    <Modal
      buttons={actionButtons}
      className={styles['modal-changes-confirmation']}
      isActive={true}
      onClose={onClose}
      size="large"
      title="Confirm Changes"
    >
      <div className={styles['modal-changes-confirmation-body']}>
        <p>
          Are you sure you want to move the question to{' '}
          <strong>position {destinationIndex + 1}</strong>?
        </p>
        {affectedLogic.skip.length > 0 && (
          <>
            <p>The following will also be removed as it will break the survey flow:</p>
            <Card>
              <h4>CONDITION</h4>
              {affectedLogic.skip.map((row, index) => {
                return (
                  <QuestionLogicCondition
                    key={`affected-logic-${index}`}
                    question={row.question}
                    questionLogic={questionLogic}
                    rowIndex={row.index}
                    selectedQuestions={selectedQuestions}
                    type="skip"
                  />
                );
              })}
            </Card>
          </>
        )}
        {affectedLogic.disqualify.length > 0 && (
          <>
            <p>
              The following will also be removed as disqualification can only be applied at position
              1 or 2 in your questionnaire:
            </p>
            <Card>
              <h4>CONDITION</h4>
              <QuestionLogicCondition
                question={question}
                questionLogic={questionLogic}
                type="disqualify"
              />
            </Card>
          </>
        )}
        {affectedDisqualifyQuestion && (
          <>
            <p>
              The following for{' '}
              <strong>
                “{truncateTextWithEllipsis({ length: 30, text: affectedDisqualifyQuestion.text })}”
              </strong>{' '}
              will also be removed as disqualification can only be applied at position 1 or 2 in
              your questionnaire:
            </p>
            <Card>
              <h4>CONDITION</h4>
              <QuestionLogicCondition
                question={affectedDisqualifyQuestion}
                questionLogic={questionLogic}
                type="disqualify"
              />
            </Card>
          </>
        )}
      </div>
    </Modal>
  );
};

ModalChangesConfirmation.propTypes = {
  affectedDisqualifyQuestion: PropTypes.object,
  affectedLogic: PropTypes.object.isRequired,
  destinationIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  questionLogic: PropTypes.object.isRequired,
  reorderQuestions: PropTypes.func.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
};

export default ModalChangesConfirmation;
