export const COLOR_AZURE = '#eff4f4';
export const COLOR_BANTAMWEIGHT = '#b3c9cb';
export const COLOR_COBALT = '#007aff';
export const COLOR_DEEPTEAL = '#004a52';
export const COLOR_ERROR = '#d9534f';
export const COLOR_FLYWEIGHT = '#e0e9ea';
export const COLOR_MAGENTA = '#F621FB';
export const COLOR_MIDDLEWEIGHT = '#336e74';
export const COLOR_MINT = '#0aefb8';
export const COLOR_NUMERATOR_GREEN = '#00e36b';
export const COLOR_ORANGE = '#FE971F';
export const COLOR_PINK = '#ea526f';
export const COLOR_PURPLE = '#8053FF';
export const COLOR_SAPPHIRE = '#00a8b8';
export const COLOR_SAPPHIRE_200 = '#007985';
export const COLOR_SAPPHIRE_300 = '#004b52';
export const COLOR_SAPPHIRE_DARK = '#008c99';
export const COLOR_SUMO = '#002c31';
export const COLOR_WELTERWEIGHT = '#669297';
export const COLOR_WHITE = '#ffffff';
export const COLOR_YELLOW = '#ffd61e';
