import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGate } from 'statsig-react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { nanoid } from 'nanoid';

import {
  createQuestion,
  questionTypes,
  readQuestion,
  uploadImageToS3,
  updateQuestion,
  uploadQuestionImage,
} from '@api/surveys';
import {
  Announcement,
  Button,
  ButtonText,
  Checkbox,
  Fieldset,
  Input,
  LoaderSkeleton,
  MentionsInput,
  ModalV2 as Modal,
  RichTextInput,
  Select,
  TextArea,
} from '@utilities';
import { GATES } from '@constants';
import { PlusIcon } from '@utilities/icons';

import Answer from './components/Answer';

import {
  availableQuestionVariables,
  availableTypes,
  noneOfTheAbove,
  otherAlternates,
  otherPleaseSpecify,
} from './utilities/constants';

import styles from './_index.module.scss';

const ANSWER_MAX_LENGTH = 150;
const QUESTION_MAX_LENGTH = 1000;

const ANSWER_LENGTH_ERROR = `Answer text exceeds the max-length of ${ANSWER_MAX_LENGTH}. Trim or remove it to continue.`;
const QUESTION_LENGTH_ERROR = `Question text length is too long. Trim it to ${QUESTION_MAX_LENGTH} characters or less to continue.`;

const ModalSurveyQuestion = ({
  id,
  isDuplicate,
  isReadOnly,
  onClose,
  onCreate,
  onUpdate,
  surveyType,
}) => {
  const [hasImageError, setHasImageError] = useState({ answers: [] });
  const [images, setImages] = useState({ answers: [] });
  const [isImageLoading, setIsImageLoading] = useState({ answers: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noneType, setNoneType] = useState(false);
  const [otherType, setOtherType] = useState(false);
  const [otherAnswer, setOtherAnswer] = useState({
    isAnchored: true,
    isExclusive: false,
    isNoneOfTheAbove: false,
    isOther: true,
    text: otherPleaseSpecify,
  });
  const [noneOfTheAboveAnswer, setNoneOfTheAboveAnswer] = useState({
    isAnchored: true,
    isExclusive: true,
    isNoneOfTheAbove: true,
    isOther: false,
    text: noneOfTheAbove,
  });
  const [question, setQuestion] = useState({
    answers: [
      { id: nanoid(), text: '' },
      { id: nanoid(), text: '' },
      { id: nanoid(), text: '' },
    ],
    image: {
      imageBucketName: null,
      imageCdnUrl: null,
      imageObjectKey: null,
      imageObjectType: null,
    },
    randomizeOptions: false,
    text: '',
    type: questionTypes.checkbox,
  });
  const [submitError, setSubmitError] = useState(null);

  const { value: canPhotoUpload } = useGate(GATES.PHOTO_UPLOAD);
  // pasting text into <MentionsInput /> can exceed its maxLength, these checks are still necessary
  // https://github.com/signavio/react-mentions/issues/616
  const isAnAnswerTooLong = question.answers.some(({ text }) => text.length > ANSWER_MAX_LENGTH);
  const isQuestionTextTooLong = question.text?.length > QUESTION_MAX_LENGTH;

  useEffect(() => {
    if (id) {
      getCustomQuestion(id);
    }
  }, []);

  const addOneInputAnswer = () => {
    setQuestion({ ...question, answers: [...question.answers, { id: nanoid(), text: '' }] });
  };

  const anchorAnswer = (index) => {
    const answers = [...question.answers];
    answers[index].isAnchored = !answers[index].isAnchored;
    setQuestion({ ...question, answers });
  };

  const deleteAnswer = (index) => {
    question.answers.splice(index, 1);
    setQuestion({ ...question });
  };

  const deleteOtherAnswer = () => {
    // change other back to a default of not exclusive
    setOtherAnswer({ ...otherAnswer, isExclusive: false });
    setOtherType(false);
  };

  const disableSaveButton = () => {
    if (
      question.type.value === questionTypes.radio.value ||
      question.type.value === questionTypes.checkbox.value
    ) {
      return (
        !question.text ||
        isQuestionTextTooLong ||
        question.answers.some(({ text }) => !text?.trim()) ||
        question.answers.length === 0 ||
        isAnAnswerTooLong
      );
    }
    return !question.text || isQuestionTextTooLong;
  };

  const getAnswerInputError = (answer) => {
    if (answer.text?.length > ANSWER_MAX_LENGTH) return ANSWER_LENGTH_ERROR;
    if (!answer.text?.trim() && answer.imageCdnUrl) return 'Question options require text.';

    return null;
  };

  const getCustomQuestion = async (id) => {
    setIsLoading(true);
    try {
      const data = await readQuestion(id);
      setValues(data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const makeAnswerExclusive = (index, isOther) => {
    if (isOther) {
      setOtherAnswer({ ...otherAnswer, isExclusive: !otherAnswer.isExclusive });
    } else {
      const answers = [...question.answers];
      answers[index].isExclusive = !answers[index].isExclusive;
      setQuestion({ ...question, answers });
    }
  };

  const onAnswerDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }
    const answers = [...question.answers];
    const [removed] = answers.splice(result?.source?.index, 1);
    answers.splice(result?.destination?.index, 0, removed);
    setQuestion({ ...question, answers });
  };

  const onAnswerPaste = (event, value, index) => {
    const values = value
      .split(/[\n\r]+/) // split on newlines and returns
      .filter((n) => n) // filter removes empty string in cases of repeated line breaks
      .map((text) => text.trim().replace(/^•[\t\s]*/, '')) // trim whitespace and remove bullet points
      .filter((text) => {
        if (otherAlternates.includes(text.toLowerCase())) {
          setOtherType(true);
          return false;
        } else if (text.toLowerCase() === noneOfTheAbove.toLowerCase()) {
          setNoneType(true);
          return false;
        }
        return true;
      })
      .map((text) => ({
        id: nanoid(),
        text,
      }));

    if (values.length <= 1) {
      return;
    }
    event.preventDefault();
    question.answers.splice(index, values.length, ...values);
    setQuestion({ ...question });
  };

  const onSave = async () => {
    setIsSubmitting(true);
    setSubmitError(null);
    const answers = [...question.answers].map((answer, index) => {
      return { ...answer, ...images.answers[index] };
    });
    const { image, randomizeOptions, text, type } = question;
    if (otherType) {
      answers.push(otherAnswer);
    }
    if (noneType) {
      answers.push(noneOfTheAboveAnswer);
    }

    try {
      if (question.id) {
        const data = await updateQuestion({
          answers: type.value === questionTypes.essay.value ? [] : answers,
          documentId: surveyType.id,
          id: question.id,
          image,
          randomizeOptions,
          text,
          type: type.value,
        });
        onUpdate(data);
      } else {
        const data = await createQuestion({
          answers: type.value === questionTypes.essay.value ? [] : answers,
          documentId: surveyType.id,
          image,
          randomizeOptions,
          text,
          type: type.value,
        });
        onCreate(data);
      }
    } catch (error) {
      setIsSubmitting(false);
      setSubmitError(error);
    }
  };

  const onTypeChange = (type) => {
    const answers = [...question.answers];
    for (const answer of answers) {
      answer.isExclusive = false;
    }
    setQuestion({ ...question, answers, type });
    setOtherAnswer({ ...otherAnswer, isExclusive: false });
    setNoneOfTheAboveAnswer({ ...noneOfTheAboveAnswer, isExclusive: type.value === 'CHECKBOX' });
  };

  const setImageError = (value, index) => {
    const updatedImageError = { ...hasImageError };
    if (index !== undefined) {
      updatedImageError.answers[index] = value;
    } else {
      updatedImageError.question = value;
    }
    setHasImageError(updatedImageError);
  };

  const setImageLoading = (value, index) => {
    const updatedImageLoading = { ...isImageLoading };
    if (index !== undefined) {
      updatedImageLoading.answers[index] = value;
    } else {
      updatedImageLoading.question = value;
    }
    setIsImageLoading(updatedImageLoading);
  };

  const setUploadedImage = (value, index) => {
    const updatedImages = { ...images };
    if (index !== undefined) {
      updatedImages.answers[index] = value;
      const answers = [...question.answers];
      answers[index].imageCdnUrl = value?.imageCdnUrl;
      setQuestion({ ...question, answers });
    } else {
      setQuestion({
        ...question,
        image: value || {
          imageBucketName: null,
          imageCdnUrl: null,
          imageObjectKey: null,
          imageObjectType: null,
        },
      });
    }
    setImages(updatedImages);
  };

  const setValues = ({ answers, id, type, ...rest }) => {
    const allAnswers = [...answers];
    if (allAnswers.length) {
      const isUsingOtherField = allAnswers.findIndex((answer) => answer.isOther);
      if (isUsingOtherField !== -1) {
        setOtherType(true);
        setOtherAnswer({ ...otherAnswer, isExclusive: allAnswers[isUsingOtherField].isExclusive });
        allAnswers.splice(isUsingOtherField, 1);
      }
      const isUsingNoneField = allAnswers.findIndex((answer) => answer.isNoneOfTheAbove);
      if (isUsingNoneField !== -1) {
        setNoneType(true);
        allAnswers.splice(isUsingNoneField, 1);
      }
    }

    setQuestion({
      answers: allAnswers.map((answer) => ({ ...answer, id: nanoid() })),
      id: isDuplicate ? undefined : id,
      type:
        availableTypes.find((availableType) => availableType.value === type) || questionTypes.essay,
      ...rest,
    });
  };

  const updateAnswer = (value, index) => {
    const answers = [...question.answers];
    answers[index].text = value;
    setQuestion({ ...question, answers });
  };

  const onImageUpload = (file, index) => {
    setImageError(false, index);
    setImageLoading(true, index);
    uploadQuestionImage({ objectType: file.type.substring(6) }).then(({ data }) => {
      const isLocal = data.imageCdnUrl.indexOf('http://localhost') !== -1;
      if (!isLocal) {
        uploadS3Image(file, data, index);
      } else {
        setUploadedImage(data, index);
        setImageLoading(false, index);
      }
    });
  };

  const uploadS3Image = async (file, data, index) => {
    try {
      await uploadImageToS3({ file, url: data.url });
      setUploadedImage(data, index);
    } catch {
      setImageError(true, index);
    }
    setImageLoading(false, index);
  };

  const actionButtons = [
    <Button
      data-testid="modal-cancel-button"
      key="modal-cancel-button"
      onClick={onClose}
      text={isReadOnly ? 'Close' : 'Cancel'}
      variant="secondary"
    />,
    ...(!isReadOnly
      ? [
          <Button
            data-testid="modal-confirm-button"
            isDisabled={disableSaveButton()}
            isLoading={isSubmitting}
            key="modal-confirm-button"
            onClick={onSave}
            text={question.id ? 'Update' : 'Create & Add'}
          />,
        ]
      : []),
  ];
  const mentions = [
    {
      data: availableQuestionVariables.map((variable) => ({
        id: variable,
        display: variable,
      })),
      displayTransform: (_, display) => `[${display.toLowerCase()}]`,
      key: 'variables',
      markup: '[__display__]',
      trigger: '[',
    },
  ];
  const suggestions = {
    description: 'Use our dynamic variables to help with recalls for your surveys.',
    helpUrl: surveyType.help_url,
    title: 'Search for Variables',
  };
  const titleModifier = isReadOnly ? 'View' : id && !isDuplicate ? 'Edit' : 'Create';

  return (
    <Modal
      buttons={actionButtons}
      isActive={true}
      onClose={onClose}
      size="large"
      title={`${titleModifier} Survey Question`}
    >
      {isLoading && (
        <LoaderSkeleton height={350}>
          <rect x="0" y="50" rx="4" ry="4" width="1333" height="40" />
          <rect x="0" y="120" rx="4" ry="4" width="1333" height="40" />
          <rect x="0" y="210" rx="4" ry="4" width="1333" height="120" />
        </LoaderSkeleton>
      )}

      {!isLoading && (
        <div className={styles['modal-survey-question']}>
          {isReadOnly && (
            <Announcement
              text={
                <>
                  You are viewing one of our <strong>Recommended Questions</strong> which you cannot
                  edit.
                </>
              }
              variant="info"
            />
          )}
          {submitError && (
            <Announcement
              text={
                submitError?.response?.data?.error ||
                `There was an error ${question.id ? 'updating' : 'creating'} your question.`
              }
              variant="error"
            />
          )}
          <div className={styles['modal-survey-question-text-and-type']}>
            {canPhotoUpload ? (
              <RichTextInput
                autoFocus={question.id ? false : true}
                hasImageError={hasImageError.question}
                error={isQuestionTextTooLong ? QUESTION_LENGTH_ERROR : null}
                id="question-text-rich-input"
                imageUrl={question.image?.imageCdnUrl}
                isDisabled={isReadOnly}
                isImageLoading={isImageLoading.question}
                label="Question"
                maxLength={QUESTION_MAX_LENGTH}
                mentions={mentions}
                onChange={(text) => setQuestion({ ...question, text })}
                onImageRemove={setUploadedImage}
                onImageUpload={onImageUpload}
                placeholder="Enter question"
                setHasImageError={setImageError}
                suggestions={suggestions}
                value={question.text}
              />
            ) : (
              <MentionsInput
                a11ySuggestionsListLabel="Available Variables"
                error={isQuestionTextTooLong ? QUESTION_LENGTH_ERROR : null}
                id="question-text-mentions-input"
                isDisabled={isReadOnly}
                isSingleLine
                label="Question"
                mentions={mentions}
                maxLength={QUESTION_MAX_LENGTH}
                onChange={(text) => setQuestion({ ...question, text })}
                placeholder="Enter question"
                suggestions={suggestions}
                value={question.text}
              />
            )}
            <Select
              className={styles['modal-survey-question-select']}
              isDisabled={isReadOnly}
              label="Type"
              onChange={(type) => onTypeChange(type)}
              options={availableTypes}
              value={question.type}
            />
          </div>

          {question.type.value === questionTypes.essay.value ? (
            <TextArea
              className={styles['modal-survey-question-input']}
              label="Answer (Multiple Lines)"
              isDisabled
            />
          ) : (
            <>
              <Fieldset legend={`Answer Options (${ANSWER_MAX_LENGTH} characters)`}>
                {!isReadOnly && (
                  <div className={styles['modal-survey-question-randomize']}>
                    <Checkbox
                      isChecked={question.randomizeOptions}
                      label="Randomize order"
                      onChange={() =>
                        setQuestion({ ...question, randomizeOptions: !question.randomizeOptions })
                      }
                      value={question.randomizeOptions}
                    />
                  </div>
                )}
                <DragDropContext onDragEnd={onAnswerDragEnd}>
                  <Droppable droppableId="modalSurveyQuestionAnswers">
                    {(provided) => (
                      <ul ref={provided.innerRef} {...provided.droppableProps}>
                        {question.answers.map((answer, index) => {
                          return (
                            <Draggable
                              draggableId={`answer-${answer.id}`}
                              key={`answer-${answer.id}`}
                              index={index}
                              isDragDisabled={
                                question.answers.length === 1 || question.randomizeOptions
                              }
                            >
                              {(provided) => {
                                return (
                                  <li
                                    className={styles['modal-survey-question-answers-li']}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    tabIndex="-1"
                                  >
                                    <Answer
                                      answer={answer}
                                      isDisabled={isReadOnly}
                                      isDraggable
                                      onAnchor={() => anchorAnswer(index)}
                                      onDelete={() => deleteAnswer(index)}
                                      onMakeExclusive={() => makeAnswerExclusive(index)}
                                      type={question.type.value}
                                    >
                                      {canPhotoUpload ? (
                                        <RichTextInput
                                          error={getAnswerInputError(answer)}
                                          hasImageError={hasImageError.answers[index]}
                                          imageUrl={answer.imageCdnUrl}
                                          isDisabled={isReadOnly}
                                          isImageLoading={isImageLoading.answers[index]}
                                          maxLength={ANSWER_MAX_LENGTH}
                                          mentions={mentions}
                                          onChange={(value) => updateAnswer(value, index)}
                                          onImageRemove={() => setUploadedImage(null, index)}
                                          onImageUpload={(file) => onImageUpload(file, index)}
                                          onPaste={(event, value) =>
                                            onAnswerPaste(event, value, index)
                                          }
                                          placeholder="Enter answer"
                                          setHasImageError={(value) => setImageError(value, index)}
                                          suggestions={suggestions}
                                          value={answer.text}
                                        />
                                      ) : (
                                        <Input
                                          className={styles['modal-survey-question-input']}
                                          error={getAnswerInputError(answer)}
                                          isDisabled={isReadOnly}
                                          maxLength={ANSWER_MAX_LENGTH}
                                          onChange={(value) => updateAnswer(value, index)}
                                          onPaste={(event, value) =>
                                            onAnswerPaste(event, value, index)
                                          }
                                          placeholder="Enter answer"
                                          value={answer.text}
                                        />
                                      )}
                                    </Answer>
                                  </li>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                {(noneType || otherType) && (
                  <ul className={styles['modal-survey-question-options']}>
                    {otherType && (
                      <li className={styles['modal-survey-question-answers-li']}>
                        <Answer
                          answer={otherAnswer}
                          isDisabled={isReadOnly}
                          onDelete={deleteOtherAnswer}
                          onMakeExclusive={() => makeAnswerExclusive(null, true)}
                          otherType={otherType}
                          type={question.type.value}
                        >
                          <span className={styles['modal-survey-question-other']}>
                            {otherPleaseSpecify}:
                          </span>
                          <span className={styles['modal-survey-question-other-placeholder']}>
                            Panelist's write-in
                          </span>
                        </Answer>
                      </li>
                    )}
                    {noneType && (
                      <li className={styles['modal-survey-question-answers-li']}>
                        <Answer
                          answer={noneOfTheAboveAnswer}
                          isDisabled={isReadOnly}
                          noneType={noneType}
                          onDelete={() => setNoneType(false)}
                          type={question.type.value}
                        >
                          <span className={styles['modal-survey-question-none']}>
                            {noneOfTheAbove}
                          </span>
                        </Answer>
                      </li>
                    )}
                  </ul>
                )}
              </Fieldset>

              {!isReadOnly && (
                <div className={styles['modal-survey-question-actions']}>
                  <PlusIcon />
                  <ButtonText data-testid="add-answer-button" onClick={addOneInputAnswer}>
                    <strong>New</strong>&nbsp;Option{!otherType || !noneType ? ',' : null}
                  </ButtonText>
                  {!otherType && (
                    <ButtonText data-testid="add-other-button" onClick={() => setOtherType(true)}>
                      <strong>"Other, Please Specify"</strong>&nbsp;Option{!noneType ? ',' : null}
                    </ButtonText>
                  )}
                  {!noneType && (
                    <ButtonText data-testid="add-none-button" onClick={() => setNoneType(true)}>
                      <strong>"None of the above"</strong>&nbsp;Option
                    </ButtonText>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

ModalSurveyQuestion.defaultProps = {
  isDuplicate: false,
  isReadOnly: false,
};

ModalSurveyQuestion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDuplicate: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default ModalSurveyQuestion;
