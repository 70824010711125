import keyBy from 'lodash/keyBy';

/**
 * transforms and formats single trip group folder
 * @param  {Object} trip group
 * @return {Object}
 */
export const transformTripGroupFolder = (folder) => {
  return {
    createdDatetime: folder?.created_datetime,
    creator: folder?.creator,
    folderId: folder?.id,
    id: folder?.folderentry_id,
    isFolder: true,
    isLocked: folder?.is_locked ?? false,
    isOwner: !folder?.is_locked,
    isShared: folder?.is_shared ?? false,
    name: folder?.name,
  };
};

/**
 * transforms and formats trip group folders
 * @param  {Object} data
 * @return {Object}
 */
export const transformTripGroupFolders = (data) => {
  return {
    folders: data?.items?.map((folder) => {
      return transformTripGroupFolder(folder);
    }),
    page: data?.page,
    size: data?.size,
    total: data?.total,
  };
};

/**
 * transforms and formats single trip group
 * @param  {Object} trip group
 * @return {Object}
 */
export const transformTripGroup = (item) => {
  return {
    count: item?.count,
    createdDatetime: item?.created_datetime,
    creator: item?.creator,
    deleted: item?.deleted,
    description: item?.description,
    folderEntryId: item?.folderentry_id,
    hasException: item?.status === 4,
    id: item?.id,
    isCompleted: item?.status === 3,
    isItem: true,
    isJoin: item?.is_join ?? false,
    isLocked: item?.is_locked ?? false,
    isOwner: !item?.is_locked,
    isSmart: item?.is_smart ?? false,
    isShared: item?.is_shared ?? false,
    lastUpdated: item?.last_updated,
    name: item?.name,
    panel: item?.panel,
    parentId: item?.parent_id,
    refreshDatetime: item?.refresh_datetime,
    status: item?.status,
    userId: item?.user_id,
  };
};

/**
 * transforms and formats trip groups
 * @param  {Object} data
 * @return {Object}
 */
export const transformTripGroups = (data) => {
  return {
    items: data?.items?.map((item) => {
      return transformTripGroup(item);
    }),
    page: data?.page,
    size: data?.size,
    total: data?.total,
  };
};

/**
 * transforms and forms trip group prompts
 * @param  {Object} data
 * @return {Object}
 */
export const transformTripGroupPrompts = (data) => {
  return {
    ...keyBy(data?.prompts, 'id'),
    shopper_group: {
      name: 'Groups',
    },
  };
};
