import { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { QUESTION_TYPES } from '@api/instant_survey';
import { readEssayResponses, readSurveyResponses } from '@api/survey_dashboard';
import { Error, FilterGroup, Layout, LoaderSkeleton, Select } from '@utilities';

import styles from './_index.module.scss';

const Responses = ({ globalFilters, survey }) => {
  const compareByOptions = [
    {
      label: 'Primary Segments',
      options: [
        { label: 'Overall', value: 'overall' },
        { isDisabled: survey.groups?.length === 1, label: 'Quota Group', value: 'quota_group' },
      ],
    },
    {
      label: 'Demographic Segments',
      options: globalFilters.demographicFilters
        ?.filter(({ filter_only }) => !filter_only)
        .map(({ id, title }) => ({
          label: title,
          value: id,
        })),
    },
  ];
  const displayOptions = [
    { label: 'Percentages', value: 'pct' },
    { label: 'Count', value: 'count' },
  ];

  const [compareBy, setCompareBy] = useState(
    survey.groups?.length > 1 ? compareByOptions[0].options[1] : compareByOptions[0].options[0]
  );
  const [displayDataIn, setDisplayDataIn] = useState(displayOptions[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});

  const requestController = useRef(null);

  useEffect(() => {
    getData();
    return () => requestController?.current?.abort();
  }, [selectedFilters]);

  const getCompareByGroups = () => {
    switch (compareBy.value) {
      case 'overall':
        return [];
      case 'quota_group':
        return survey.groups?.map(({ id, name }) => ({
          id,
          name,
        }));
      default:
        return (
          globalFilters.demographicFilters
            ?.find(({ id }) => id === compareBy.value)
            ?.options.map(({ id, label }) => ({ id, name: label })) || []
        );
    }
  };

  const getData = async () => {
    const controller = new AbortController();
    requestController?.current?.abort();
    requestController.current = controller;

    const filters = Object.keys(selectedFilters).reduce((acc, key) => {
      return {
        ...acc,
        [key]: Object.keys(selectedFilters[key])
          .filter((optionKey) => selectedFilters[key][optionKey])
          .join(';'),
      };
    }, {});

    setIsLoading(true);
    setLoadingError(null);
    try {
      const requests = [
        readEssayResponses({
          query: {
            compare_by: compareBy,
            ...filters,
          },
          signal: controller.signal,
          surveyId: survey.id,
        }),
        readSurveyResponses({
          query: {
            compare_by: compareBy,
            ...filters,
          },
          signal: controller.signal,
          surveyId: survey.id,
        }),
      ];
      await Promise.all(requests);
      setIsLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setLoadingError(error);
      }
    }
  };

  if (loadingError) return <Error status={loadingError?.response?.status} />;

  return (
    <Layout.Flex className={styles['responses']} gap="large">
      <Layout.Sidebar className={styles['responses-sidebar']}>
        <Layout.Flex.Column gap="x-large">
          <div className={styles['responses-compare-by']}>
            <Select
              className={styles['responses-filter-select']}
              label="Compare By"
              onChange={setCompareBy}
              options={compareByOptions}
              value={compareBy}
            />
            <ul>
              {getCompareByGroups().map((group) => (
                <li key={group.id}>• {group.name}</li>
              ))}
            </ul>
          </div>

          <Select
            className={styles['responses-filter-select']}
            label="Display Data In"
            onChange={setDisplayDataIn}
            options={displayOptions}
            value={displayDataIn}
          />

          {globalFilters?.demographicFilters && (
            <Layout.Flex.Column gap="small">
              <h4>Demographic Filters</h4>
              {globalFilters.demographicFilters.map((filter) => (
                <FilterGroup
                  filter={filter}
                  key={filter.id}
                  onChange={(value) =>
                    setSelectedFilters({ ...selectedFilters, [filter.id]: value })
                  }
                  value={selectedFilters[filter.id]}
                />
              ))}
            </Layout.Flex.Column>
          )}
        </Layout.Flex.Column>
      </Layout.Sidebar>

      <Layout.Fill className={styles['responses-main']}>
        {survey?.questions.map((question, index) => (
          <Fragment key={question.id}>
            <h3>
              Q{index + 1}. {question.text}
            </h3>
            {isLoading && (
              <LoaderSkeleton height={200}>
                <rect x="0" y="0" rx="2" ry="2" width="1000" height="200" />
              </LoaderSkeleton>
            )}
            {!isLoading && (
              <>
                {question.type !== QUESTION_TYPES.ESSAY.value && <div>Bar Chart</div>}
                {question.type === QUESTION_TYPES.ESSAY.value && <div>Essay Response Table</div>}
              </>
            )}
          </Fragment>
        ))}
      </Layout.Fill>
    </Layout.Flex>
  );
};

Responses.propTypes = {
  globalFilters: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired,
};

export default Responses;
