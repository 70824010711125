import axios from '@api';
import axiosDefault from 'axios';

import { transformQuestionImage } from './utilities/helpers';

/**
 * upload image to S3
 * @param {File} options.file
 * @param {String} options.url
 * @return {Promise}
 */
export const uploadImageToS3 = ({ file, url }) => {
  return axiosDefault.put(url, file, { headers: { 'Content-Type': file.type } });
};

/**
 * upload question image
 * @param {String} options.objectType
 * @return {Promise}
 */
export const uploadQuestionImage = ({ objectType }) => {
  return axios({
    data: {
      object_type: objectType,
    },
    method: 'post',
    transformResponse: [...axios.defaults.transformResponse, transformQuestionImage],
    url: `/api/questions/image_upload_presigned_url`,
    withCredentials: true,
  });
};
