import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CloseBoldV2Icon, ExclamationTriangleIcon } from '../../icons';

import styles from './_index.module.scss';

const Pill = ({
  buttonRef,
  className,
  icon,
  isActive,
  isCompleted,
  isDisabled,
  isError,
  isModified,
  isRequired,
  onClick,
  onClose,
  value,
  ...props
}) => {
  const handleOnClose = (event) => {
    if (onClose) {
      event.stopPropagation();
      onClose();
    }
  };
  const otherClassNames = {
    [styles['is-active']]: isActive && !isDisabled && !isCompleted,
    [styles['is-completed']]: isCompleted,
    [styles['is-disabled']]: isDisabled && !isRequired && !isCompleted,
    [styles['is-error']]: isError,
  };

  return (
    <button
      aria-label="pill"
      className={classNames(styles['pill'], className, otherClassNames)}
      onClick={isCompleted || isDisabled ? () => {} : onClick}
      ref={buttonRef}
      {...props}
    >
      {isError && (
        <span className={styles['pill-icon']}>
          <ExclamationTriangleIcon data-testid="error-icon" />
        </span>
      )}
      {!isError && icon && <span className={styles['pill-icon']}>{icon}</span>}
      <strong>{value}</strong>
      {isModified && !isDisabled && !isCompleted && (
        <CloseBoldV2Icon
          className={styles['pill-close-icon']}
          data-testid="close-bold-icon"
          onClick={handleOnClose}
        />
      )}
      {isRequired && !isModified && !isCompleted && (
        <span className={styles['pill-required-icon']}>*</span>
      )}
    </button>
  );
};

Pill.defaultProps = {
  'data-testid': 'pill',
  onClick: () => {},
};

Pill.propTypes = {
  buttonRef: PropTypes.object,
  className: PropTypes.string,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isModified: PropTypes.bool,
  isRequired: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.string,
};

export default Pill;
