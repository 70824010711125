import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { maxQuestionError } from '@api/surveys';

import { OverlayTriggerTooltip, PlusIcon } from '@utilities';

import AddQuestionDropdown from '../AddQuestionDropdown';
import Question from './components/Question';
import QuestionPlaceholder from './components/QuestionPlaceholder';

import styles from './_index.module.scss';
import ModalChangesConfirmation from './components/ModalChangesConfirmation';

const QuestionnaireList = ({
  firstErrorRef,
  isDetailedQuestionView,
  maxQuestions,
  onAddQuestion,
  onCreateQuestion,
  onDuplicateQuestion,
  onRemoveQuestion,
  onSelectQuestion,
  questionLogic,
  selectedQuestions,
  setQuestionLogic,
  setSelectedQuestions,
}) => {
  const [affectedDisqualifyQuestion, setAffectedDisqualifyQuestion] = useState();
  const [affectedLogic, setAffectedLogic] = useState();
  const [questionDestinationIndex, setQuestionDestinationIndex] = useState();
  const [isAddQuestionDropdownActive, setIsAddQuestionDropdownActive] = useState(false);
  const [reorderedQuestionLogic, setReorderedQuestionLogic] = useState();
  const [showChangesConfirmationModal, setShowChangesConfirmationModal] = useState(false);
  const [sourceQuestion, setSourceQuestion] = useState();
  const [questionSourceIndex, setQuestionSourceIndex] = useState();

  const addQuestionButtonRef = useRef(null);

  const handleChangesConfirmationModalClose = () => {
    setShowChangesConfirmationModal(false);
    setAffectedLogic({});
    setAffectedDisqualifyQuestion();
    setQuestionDestinationIndex();
    setQuestionSourceIndex();
    setReorderedQuestionLogic();
    setSourceQuestion();
  };

  const onDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }

    const sourceIndex = result?.source?.index;
    const destinationIndex = result?.destination?.index;

    if (sourceIndex === destinationIndex) {
      return;
    }

    const selectedQuestion = selectedQuestions[sourceIndex];
    const updatedQuestions = [...selectedQuestions];
    const [removed] = updatedQuestions.splice(sourceIndex, 1);
    updatedQuestions.splice(destinationIndex, 0, removed);

    let hasLogicChanges = false;
    const modifiedQuestionLogic = cloneDeep(questionLogic);
    const logicChanges = { disqualify: [], skip: [] };
    const selectedQuestionLogic = modifiedQuestionLogic[updatedQuestions[destinationIndex].id];

    // if moving question from outside of first 2
    if (sourceIndex > 1) {
      if (destinationIndex === 0) {
        // remove any skip logic since first question cannot have any
        if (selectedQuestionLogic?.skip) {
          for (const index in selectedQuestionLogic.skip.logic) {
            const row = selectedQuestionLogic.skip.logic[index];
            if (row.question) {
              logicChanges.skip = [
                ...logicChanges.skip,
                {
                  index: parseInt(index),
                  question: selectedQuestion,
                },
              ];
            }
          }
          delete selectedQuestionLogic.skip;
        }
      }
      if (destinationIndex <= 1) {
        // remove any disqualify logic from what used to be the 2nd question
        if (modifiedQuestionLogic[updatedQuestions[2].id]?.disqualify) {
          hasLogicChanges = true;
          setAffectedDisqualifyQuestion(updatedQuestions[2]);
          delete modifiedQuestionLogic[updatedQuestions[2].id].disqualify;
        }
      }
    }
    // if moving question from within the first 2
    else {
      if (destinationIndex > 1) {
        // remove any disqualify logic since only first 2 questions can have it
        if (selectedQuestionLogic?.disqualify) {
          hasLogicChanges = true;
          logicChanges.disqualify = [...logicChanges.disqualify, selectedQuestionLogic.disqualify];
          delete selectedQuestionLogic.disqualify;
        }
      }
    }

    // if source question is moving down, make sure
    // questions between sourceIndex and destinationIndex do not reference source question
    if (destinationIndex > sourceIndex) {
      for (let i = sourceIndex; i < destinationIndex; i++) {
        const questionId = updatedQuestions[i].id;
        if (modifiedQuestionLogic[questionId]?.skip) {
          let updatedLogic = [];
          for (const index in modifiedQuestionLogic[questionId].skip.logic) {
            const row = modifiedQuestionLogic[questionId].skip.logic[index];
            if (row.question) {
              if (row.question.id === selectedQuestion.id) {
                hasLogicChanges = true;
                logicChanges.skip = [
                  ...logicChanges.skip,
                  {
                    index: parseInt(index),
                    question: updatedQuestions[i],
                  },
                ];
              } else {
                updatedLogic = [...updatedLogic, row];
              }
            }
          }
          if (updatedLogic.length > 0) {
            modifiedQuestionLogic[questionId].skip.logic = updatedLogic;
          } else {
            delete modifiedQuestionLogic[questionId].skip;
          }
        }
      }
    }
    // if source question is moving up, check skip logic on source question to
    // make sure all question indexes are less than destinationIndex
    else {
      const questionId = selectedQuestion.id;
      if (modifiedQuestionLogic[questionId]?.skip) {
        let updatedLogic = [];
        for (const index in modifiedQuestionLogic[questionId].skip.logic) {
          const row = modifiedQuestionLogic[questionId].skip.logic[index];
          if (row.question) {
            const isValid =
              updatedQuestions.findIndex((question) => question.id === row.question.id) <
              destinationIndex;
            if (isValid) {
              updatedLogic = [...updatedLogic, row];
            } else {
              hasLogicChanges = true;
              logicChanges.skip = [
                ...logicChanges.skip,
                {
                  index: parseInt(index),
                  question: selectedQuestion,
                },
              ];
            }
          }
        }
        if (updatedLogic.length > 0) {
          modifiedQuestionLogic[questionId].skip.logic = updatedLogic;
        } else {
          delete modifiedQuestionLogic[questionId].skip;
        }
      }
    }

    if (hasLogicChanges) {
      setQuestionSourceIndex(sourceIndex);
      setQuestionDestinationIndex(destinationIndex);
      setSourceQuestion(selectedQuestion);
      setReorderedQuestionLogic(modifiedQuestionLogic);
      setAffectedLogic(logicChanges);
      setShowChangesConfirmationModal(true);
    } else {
      // if no logic confirmtion modal, pass values directly in since state takes time to update
      reorderQuestions(updatedQuestions, modifiedQuestionLogic);
    }
  };

  const reorderQuestions = (updatedQuestions, modifiedQuestionLogic) => {
    let questions;
    if (updatedQuestions) {
      questions = updatedQuestions;
    } else {
      questions = selectedQuestions;
      const [removed] = questions.splice(questionSourceIndex, 1);
      questions.splice(questionDestinationIndex, 0, removed);
    }
    setSelectedQuestions(questions, modifiedQuestionLogic || reorderedQuestionLogic);
  };

  return (
    <>
      <div className={styles['questionnaire-list-container']}>
        {selectedQuestions.length === 0 && (
          <QuestionPlaceholder onCreateQuestion={onCreateQuestion} />
        )}
        {selectedQuestions.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className={styles['selections']}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {selectedQuestions.map((question, index) => {
                    return (
                      <Draggable
                        draggableId={`activeAnswer-${index}`}
                        key={`activeAnswer-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          return (
                            <Question
                              firstErrorRef={firstErrorRef}
                              index={index}
                              isDetailedQuestionView={isDetailedQuestionView}
                              key={`question-${question.id}`}
                              maxQuestions={maxQuestions}
                              number={index + 1}
                              onRemoveQuestion={onRemoveQuestion}
                              onDuplicateQuestion={onDuplicateQuestion}
                              onEditQuestion={onSelectQuestion}
                              provided={provided}
                              question={question}
                              questionLogic={questionLogic}
                              selectedQuestions={selectedQuestions}
                              setQuestionLogic={setQuestionLogic}
                            />
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <div
          className={styles['questionnaire-list-add-question-button']}
          ref={addQuestionButtonRef}
        >
          <OverlayTriggerTooltip
            content={selectedQuestions.length >= maxQuestions ? maxQuestionError : 'Add Question'}
            position="bottom"
          >
            <button
              data-testid="add-question-button"
              disabled={selectedQuestions.length >= maxQuestions}
              onClick={() => setIsAddQuestionDropdownActive(true)}
            >
              <PlusIcon />
            </button>
          </OverlayTriggerTooltip>
        </div>
        <AddQuestionDropdown
          buttonRef={addQuestionButtonRef}
          isDropdownActive={isAddQuestionDropdownActive}
          onAddQuestion={onAddQuestion}
          onCreateQuestion={onCreateQuestion}
          placement="right"
          setIsDropdownActive={setIsAddQuestionDropdownActive}
        />
      </div>
      {showChangesConfirmationModal && (
        <ModalChangesConfirmation
          affectedDisqualifyQuestion={affectedDisqualifyQuestion}
          affectedLogic={affectedLogic}
          destinationIndex={questionDestinationIndex}
          onClose={handleChangesConfirmationModalClose}
          question={sourceQuestion}
          questionLogic={questionLogic}
          reorderQuestions={reorderQuestions}
          selectedQuestions={selectedQuestions}
        />
      )}
    </>
  );
};

QuestionnaireList.propTypes = {
  firstErrorRef: PropTypes.object.isRequired,
  isDetailedQuestionView: PropTypes.bool.isRequired,
  maxQuestions: PropTypes.number,
  onAddQuestion: PropTypes.func.isRequired,
  onCreateQuestion: PropTypes.func.isRequired,
  onDuplicateQuestion: PropTypes.func.isRequired,
  onRemoveQuestion: PropTypes.func.isRequired,
  onSelectQuestion: PropTypes.func.isRequired,
  questionLogic: PropTypes.object.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
  setQuestionLogic: PropTypes.func.isRequired,
  setSelectedQuestions: PropTypes.func.isRequired,
};

export default QuestionnaireList;
