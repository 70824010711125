import { useState } from 'react';
import PropTypes from 'prop-types';
import { useGate } from 'statsig-react';

import { ActionOverlayPopover, Card, EditableTitle, MinusPlusButtons, Toolbar } from '@utilities';
import { GATES } from '@constants';
import { CopyIcon, TrashIcon } from '@utilities/icons';

import { SURVEY_GROUP_VALIDATION_STATUS } from '@api/instant_survey';
import { responseCountIncrement, responseCountMinimum } from '@api/surveys';

import GroupAdvancedPrompts from './components/GroupAdvancedPrompts';
import GroupMadlib from './components/GroupMadlib';
import ModalPromptSearch from '../../../../../../components/ModalPromptSearch';
import { transformSearchAnswers } from '../../../../../../components/Builder/utilities/helpers';
import { transformPromptSelections } from '../../utilities/helpers';

import styles from './_index.module.scss';

const QuotaGroupCard = ({
  canDecrementResponses,
  canDelete,
  canDuplicate,
  canIncrementResponses,
  group,
  onDelete,
  onDuplicate,
  onUpdate,
  surveyType,
}) => {
  const [activePrompt, setActivePrompt] = useState(null);
  const [isActionOverlayOpen, setIsActionOverlayOpen] = useState(false);

  const { value: canMultiQuota } = useGate(GATES.MULTI_QUOTA);

  // REHYDRATE PROMPT AND SELECTED VALUES FOR MODALPROMPTSEARCH
  const activePromptForSearch = activePrompt
    ? {
        ...activePrompt,
        hasSearch: activePrompt.isSearchEnabled,
        id: activePrompt.insightsKey,
        max: activePrompt.maxValues,
        min: activePrompt.minAnswers,
      }
    : null;
  const activeSelectedAnswers = activePrompt
    ? group.promptAnswers
        .filter(({ _delete, promptId }) => !_delete && promptId === activePrompt.id)
        .map(({ values }) => values)
        .flat(1)
    : null;

  const getGroupStatus = () => {
    switch (group.validationStatus) {
      case SURVEY_GROUP_VALIDATION_STATUS.ERROR:
      case SURVEY_GROUP_VALIDATION_STATUS.NOT_ENOUGH_SAMPLE:
        return 'warn';
      case SURVEY_GROUP_VALIDATION_STATUS.VALIDATED:
        return 'success';
      default:
        return 'info';
    }
  };
  const maxSingleGroupResponses = surveyType?.samplePricing
    ?.filter(({ quotaGroups }) => quotaGroups === 1)
    .reduce((prev, { responses }) => Math.max(prev, responses), 0);

  const onClearAnswer = (promptId) => {
    const promptAnswers = [...group.promptAnswers];
    promptAnswers.forEach((promptAnswer) => {
      if (promptAnswer.promptId === promptId) {
        promptAnswer._delete = true;
        promptAnswer.values = [];
      }
    });

    onUpdate({ promptAnswers });
  };

  const onUpdateAnswers = (newAnswers) => {
    const answers = [...group.promptAnswers];
    const promptAnswers = transformPromptSelections({ answers: newAnswers, prompt: activePrompt });

    // any existing answer for the promptId that no longer exists (field is not in new promptAnswers) will be deleted
    answers
      .filter(
        ({ field, promptId }) =>
          !!promptAnswers.find(
            (promptAnswer) => field !== promptAnswer.field && promptId === promptAnswer.promptId
          )
      )
      .forEach((answer) => {
        answer._delete = true;
      });

    promptAnswers.forEach((promptAnswer) => {
      const existingAnswer = answers.find(({ field, operator, promptId }) => {
        if (activePrompt.promptKey === 'date_range') {
          return (
            field === promptAnswer.field &&
            promptId === promptAnswer.promptId &&
            operator === promptAnswer.operator
          );
        }
        return field === promptAnswer.field && promptId === promptAnswer.promptId;
      });

      if (existingAnswer) {
        existingAnswer._delete = false; // if this updates a deleted prompt, un-mark it for deletion
        existingAnswer.operator = promptAnswer.operator;
        existingAnswer.values = promptAnswer.values;
      } else {
        answers.push(promptAnswer);
      }
    });

    onUpdate({ promptAnswers: answers });
    setActivePrompt(null);
  };

  const searchAnswers = group.answers
    ? transformSearchAnswers(activePrompt, group.answers, surveyType?.hierarchy_search_prompts)
    : {};

  return (
    <Card className={styles['quota-group-card']} status={getGroupStatus()}>
      {canMultiQuota ? (
        <Toolbar
          tools={[
            <ActionOverlayPopover
              isActive={isActionOverlayOpen}
              onClick={() => setIsActionOverlayOpen(!isActionOverlayOpen)}
              onHide={() => setIsActionOverlayOpen(false)}
            >
              <ul>
                <li>
                  <button
                    disabled={!canDuplicate}
                    onClick={() => {
                      onDuplicate();
                      setIsActionOverlayOpen(false);
                    }}
                  >
                    <CopyIcon />
                    <span>Duplicate</span>
                  </button>
                </li>
                <hr />
                <li>
                  <button disabled={!canDelete} onClick={onDelete}>
                    <TrashIcon />
                    <span>Delete</span>
                  </button>
                </li>
              </ul>
            </ActionOverlayPopover>,
          ]}
        >
          <EditableTitle onChange={(value) => onUpdate({ name: value })} value={group.name} />
        </Toolbar>
      ) : (
        <h2>{group.name}</h2>
      )}

      <div className={styles['quota-group-card-layout']}>
        <div>
          <GroupMadlib
            activePrompt={activePrompt}
            group={group}
            onClearAnswer={onClearAnswer}
            onSetActivePrompt={setActivePrompt}
            surveyType={surveyType}
          />
          {surveyType.advancedPrompts?.length > 0 && (
            <GroupAdvancedPrompts
              activePrompt={activePrompt}
              advancedPrompts={surveyType.advancedPrompts}
              group={group}
              onClearAnswer={onClearAnswer}
              setActivePrompt={setActivePrompt}
            />
          )}
        </div>
        <div className={styles['quota-group-card-line']} />
        <div>
          <p>
            Responses
            <span className={styles['quota-group-card-responses']}>
              <strong>{group.quotaLimit}</strong>
              <MinusPlusButtons
                disableMinusButton={
                  !canDecrementResponses ||
                  group.quotaLimit - responseCountIncrement < responseCountMinimum
                }
                disablePlusButton={!canIncrementResponses}
                isNeutral
                onClickMinus={() =>
                  onUpdate({ quotaLimit: group.quotaLimit - responseCountIncrement })
                }
                onClickPlus={() =>
                  onUpdate({ quotaLimit: group.quotaLimit + responseCountIncrement })
                }
              />
            </span>
          </p>
          {!canMultiQuota && maxSingleGroupResponses && (
            <>
              <hr />
              <p>
                Maximum <span>{maxSingleGroupResponses}</span>
              </p>
            </>
          )}
        </div>
      </div>
      {activePrompt && (
        <ModalPromptSearch
          isSurvey
          onClose={() => setActivePrompt(null)}
          onSubmit={onUpdateAnswers}
          prompt={activePromptForSearch}
          searchAnswers={searchAnswers}
          searchPrompts={surveyType.hierarchy_search_prompts}
          selectedAnswers={activeSelectedAnswers.length === 0 ? null : activeSelectedAnswers} // null is a date range fix
        />
      )}
    </Card>
  );
};

QuotaGroupCard.propTypes = {
  canDecrementResponses: PropTypes.bool,
  canDelete: PropTypes.bool,
  canDuplicate: PropTypes.bool,
  canIncrementResponses: PropTypes.bool,
  group: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default QuotaGroupCard;
