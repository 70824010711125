import PropTypes from 'prop-types';

import { Button, ModalV2 as Modal } from '@utilities';

const ModalSurveyNameValidation = ({ isActive, onClose }) => {
  return (
    <Modal
      buttons={[<Button key="modal-confirm-button" onClick={onClose} text="Confirm" />]}
      isActive={isActive}
      onClose={onClose}
      title="Missing Survey Name"
    >
      <p>Please name your Survey in the provided field so you can access it later.</p>
    </Modal>
  );
};

ModalSurveyNameValidation.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalSurveyNameValidation;
