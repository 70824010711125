export default {
  data: [
    {
      id: '2',
      type: 'RADIO',
      chart_count: {
        format: '0,0',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [95, 71, 27, 6, 5],
          },
        ],
        title:
          "The next time you purchase cereal, how likely are you to consider purchasing Kellogg's Froot Loops? ",
        xAxis: {
          categories: [
            'Very likely',
            'Somewhat likely',
            'Neither likely nor unlikely',
            'Very unlikely',
            'Somewhat unlikely',
          ],
        },
      },
      chart_pct: {
        format: '0.0%',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [0.465686, 0.348039, 0.132353, 0.029412, 0.02451],
          },
        ],
        title:
          "The next time you purchase cereal, how likely are you to consider purchasing Kellogg's Froot Loops? ",
        xAxis: {
          categories: [
            'Very likely',
            'Somewhat likely',
            'Neither likely nor unlikely',
            'Very unlikely',
            'Somewhat unlikely',
          ],
        },
      },
      table_count: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Very likely',
            Overall: 0.465686,
          },
          {
            ans_opt: 'Somewhat likely',
            Overall: 0.348039,
          },
          {
            ans_opt: 'Neither likely nor unlikely',
            Overall: 0.132353,
          },
          {
            ans_opt: 'Very unlikely',
            Overall: 0.029412,
          },
          {
            ans_opt: 'Somewhat unlikely',
            Overall: 0.02451,
          },
        ],
      },
      table_pct: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Very likely',
            Overall: 95,
          },
          {
            ans_opt: 'Somewhat likely',
            Overall: 71,
          },
          {
            ans_opt: 'Neither likely nor unlikely',
            Overall: 27,
          },
          {
            ans_opt: 'Very unlikely',
            Overall: 6,
          },
          {
            ans_opt: 'Somewhat unlikely',
            Overall: 5,
          },
        ],
      },
    },
    {
      id: '4',
      type: 'CHECKBOX',
      chart_count: {
        format: '0,0',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [178, 18, 13, 9, 7, 4, 1],
          },
        ],
        title: 'Where was the product used / consumed? Select all that apply.',
        xAxis: {
          categories: [
            'Home',
            'Vacation / Trip',
            'School',
            'Work',
            'In transit / On-the-go',
            'Special event / Party',
            "Unsure/don't know",
          ],
        },
      },
      chart_pct: {
        format: '0.0%',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [0.872549, 0.088235, 0.063725, 0.044118, 0.034314, 0.019608, 0.004902],
          },
        ],
        title: 'Where was the product used / consumed? Select all that apply.',
        xAxis: {
          categories: [
            'Home',
            'Vacation / Trip',
            'School',
            'Work',
            'In transit / On-the-go',
            'Special event / Party',
            "Unsure/don't know",
          ],
        },
      },
      table_count: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Home',
            Overall: 0.872549,
          },
          {
            ans_opt: 'Vacation / Trip',
            Overall: 0.088235,
          },
          {
            ans_opt: 'School',
            Overall: 0.063725,
          },
          {
            ans_opt: 'Work',
            Overall: 0.044118,
          },
          {
            ans_opt: 'In transit / On-the-go',
            Overall: 0.034314,
          },
          {
            ans_opt: 'Special event / Party',
            Overall: 0.019608,
          },
          {
            ans_opt: "Unsure/don't know",
            Overall: 0.004902,
          },
        ],
      },
      table_pct: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Home',
            Overall: 178,
          },
          {
            ans_opt: 'Vacation / Trip',
            Overall: 18,
          },
          {
            ans_opt: 'School',
            Overall: 13,
          },
          {
            ans_opt: 'Work',
            Overall: 9,
          },
          {
            ans_opt: 'In transit / On-the-go',
            Overall: 7,
          },
          {
            ans_opt: 'Special event / Party',
            Overall: 4,
          },
          {
            ans_opt: "Unsure/don't know",
            Overall: 1,
          },
        ],
      },
    },
    {
      id: '5',
      type: 'CHECKBOX',
      chart_count: {
        format: '0,0',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [98, 63, 53, 38, 29, 29, 5],
          },
        ],
        title: 'Who was (or will be) the primary user of this product? Select all that apply.',
        xAxis: {
          categories: [
            'Myself',
            'Child (age 6-12)',
            'Spouse/Significant other',
            'Child (age 13-17)',
            'Other adult (age 18+)',
            'Child (age 2-5)',
            'Child (younger than 2)',
          ],
        },
      },
      chart_pct: {
        format: '0.0%',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [0.480392, 0.308824, 0.259804, 0.186275, 0.142157, 0.142157, 0.02451],
          },
        ],
        title: 'Who was (or will be) the primary user of this product? Select all that apply.',
        xAxis: {
          categories: [
            'Myself',
            'Child (age 6-12)',
            'Spouse/Significant other',
            'Child (age 13-17)',
            'Other adult (age 18+)',
            'Child (age 2-5)',
            'Child (younger than 2)',
          ],
        },
      },
      table_count: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Myself',
            Overall: 0.480392,
          },
          {
            ans_opt: 'Child (age 6-12)',
            Overall: 0.308824,
          },
          {
            ans_opt: 'Spouse/Significant other',
            Overall: 0.259804,
          },
          {
            ans_opt: 'Child (age 13-17)',
            Overall: 0.186275,
          },
          {
            ans_opt: 'Other adult (age 18+)',
            Overall: 0.142157,
          },
          {
            ans_opt: 'Child (age 2-5)',
            Overall: 0.142157,
          },
          {
            ans_opt: 'Child (younger than 2)',
            Overall: 0.02451,
          },
        ],
      },
      table_pct: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Myself',
            Overall: 98,
          },
          {
            ans_opt: 'Child (age 6-12)',
            Overall: 63,
          },
          {
            ans_opt: 'Spouse/Significant other',
            Overall: 53,
          },
          {
            ans_opt: 'Child (age 13-17)',
            Overall: 38,
          },
          {
            ans_opt: 'Other adult (age 18+)',
            Overall: 29,
          },
          {
            ans_opt: 'Child (age 2-5)',
            Overall: 29,
          },
          {
            ans_opt: 'Child (younger than 2)',
            Overall: 5,
          },
        ],
      },
    },
    {
      id: '6',
      type: 'CHECKBOX',
      chart_count: {
        format: '0,0',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [59, 33, 26, 21, 19, 19, 19, 18, 12, 10, 10, 9, 8, 7, 5, 4, 3, 3],
          },
        ],
        title:
          'Which of the following, if any, occurred before purchasing Kellogg\'s Froot Loops on [url("date")]? S',
        xAxis: {
          categories: [
            'None of these',
            'Had seen it on display / special feature',
            'Made a printed / paper shopping list',
            'Saw / found a digital / mobile coupon',
            'Other, please specify',
            'Received a recommendation from friends / family',
            'Saw a print ad (store circular, magazine, etc.)',
            'Saw a mobile app rebate available (iBotta, Checkout 51, etc.)',
            'Saw it featured on TV / Radio program',
            'Saw an online advertisement',
            'Saw / found a printed coupon for this product',
            'Received a recommendation from a professional',
            'Made a digital / mobile shopping list',
            'Saw an ad on TV / Radio',
            'Received a free sample',
            'Received a recommended product (online, social media, etc.)',
            'Saw it featured online (social media, website, blog, etc.)',
            'Saw an outside advertisement (sign, billboard, etc.)',
          ],
        },
      },
      chart_pct: {
        format: '0.0%',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [
              0.289216, 0.161765, 0.127451, 0.102941, 0.093137, 0.093137, 0.093137, 0.088235,
              0.058824, 0.04902, 0.04902, 0.044118, 0.039216, 0.034314, 0.02451, 0.019608, 0.014706,
              0.014706,
            ],
          },
        ],
        title:
          'Which of the following, if any, occurred before purchasing Kellogg\'s Froot Loops on [url("date")]? S',
        xAxis: {
          categories: [
            'None of these',
            'Had seen it on display / special feature',
            'Made a printed / paper shopping list',
            'Saw / found a digital / mobile coupon',
            'Other, please specify',
            'Received a recommendation from friends / family',
            'Saw a print ad (store circular, magazine, etc.)',
            'Saw a mobile app rebate available (iBotta, Checkout 51, etc.)',
            'Saw it featured on TV / Radio program',
            'Saw an online advertisement',
            'Saw / found a printed coupon for this product',
            'Received a recommendation from a professional',
            'Made a digital / mobile shopping list',
            'Saw an ad on TV / Radio',
            'Received a free sample',
            'Received a recommended product (online, social media, etc.)',
            'Saw it featured online (social media, website, blog, etc.)',
            'Saw an outside advertisement (sign, billboard, etc.)',
          ],
        },
      },
      table_count: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'None of these',
            Overall: 0.289216,
          },
          {
            ans_opt: 'Had seen it on display / special feature',
            Overall: 0.161765,
          },
          {
            ans_opt: 'Made a printed / paper shopping list',
            Overall: 0.127451,
          },
          {
            ans_opt: 'Saw / found a digital / mobile coupon',
            Overall: 0.102941,
          },
          {
            ans_opt: 'Other, please specify',
            Overall: 0.093137,
          },
          {
            ans_opt: 'Received a recommendation from friends / family',
            Overall: 0.093137,
          },
          {
            ans_opt: 'Saw a print ad (store circular, magazine, etc.)',
            Overall: 0.093137,
          },
          {
            ans_opt: 'Saw a mobile app rebate available (iBotta, Checkout 51, etc.)',
            Overall: 0.088235,
          },
          {
            ans_opt: 'Saw it featured on TV / Radio program',
            Overall: 0.058824,
          },
          {
            ans_opt: 'Saw an online advertisement',
            Overall: 0.04902,
          },
          {
            ans_opt: 'Saw / found a printed coupon for this product',
            Overall: 0.04902,
          },
          {
            ans_opt: 'Received a recommendation from a professional',
            Overall: 0.044118,
          },
          {
            ans_opt: 'Made a digital / mobile shopping list',
            Overall: 0.039216,
          },
          {
            ans_opt: 'Saw an ad on TV / Radio',
            Overall: 0.034314,
          },
          {
            ans_opt: 'Received a free sample',
            Overall: 0.02451,
          },
          {
            ans_opt: 'Received a recommended product (online, social media, etc.)',
            Overall: 0.019608,
          },
          {
            ans_opt: 'Saw it featured online (social media, website, blog, etc.)',
            Overall: 0.014706,
          },
          {
            ans_opt: 'Saw an outside advertisement (sign, billboard, etc.)',
            Overall: 0.014706,
          },
        ],
      },
      table_pct: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'None of these',
            Overall: 59,
          },
          {
            ans_opt: 'Had seen it on display / special feature',
            Overall: 33,
          },
          {
            ans_opt: 'Made a printed / paper shopping list',
            Overall: 26,
          },
          {
            ans_opt: 'Saw / found a digital / mobile coupon',
            Overall: 21,
          },
          {
            ans_opt: 'Other, please specify',
            Overall: 19,
          },
          {
            ans_opt: 'Received a recommendation from friends / family',
            Overall: 19,
          },
          {
            ans_opt: 'Saw a print ad (store circular, magazine, etc.)',
            Overall: 19,
          },
          {
            ans_opt: 'Saw a mobile app rebate available (iBotta, Checkout 51, etc.)',
            Overall: 18,
          },
          {
            ans_opt: 'Saw it featured on TV / Radio program',
            Overall: 12,
          },
          {
            ans_opt: 'Saw an online advertisement',
            Overall: 10,
          },
          {
            ans_opt: 'Saw / found a printed coupon for this product',
            Overall: 10,
          },
          {
            ans_opt: 'Received a recommendation from a professional',
            Overall: 9,
          },
          {
            ans_opt: 'Made a digital / mobile shopping list',
            Overall: 8,
          },
          {
            ans_opt: 'Saw an ad on TV / Radio',
            Overall: 7,
          },
          {
            ans_opt: 'Received a free sample',
            Overall: 5,
          },
          {
            ans_opt: 'Received a recommended product (online, social media, etc.)',
            Overall: 4,
          },
          {
            ans_opt: 'Saw it featured online (social media, website, blog, etc.)',
            Overall: 3,
          },
          {
            ans_opt: 'Saw an outside advertisement (sign, billboard, etc.)',
            Overall: 3,
          },
        ],
      },
    },
    {
      id: '7',
      type: 'RADIO',
      chart_count: {
        format: '0,0',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [78, 52, 49, 22, 3],
          },
        ],
        title:
          "How important is having Kellogg's Froot Loops available to purchase when you are buying cereal? Sele",
        xAxis: {
          categories: [
            'Somewhat important',
            'Very important',
            'Extremely important',
            'Not very important',
            'Not at all important',
          ],
        },
      },
      chart_pct: {
        format: '0.0%',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [0.382353, 0.254902, 0.240196, 0.107843, 0.014706],
          },
        ],
        title:
          "How important is having Kellogg's Froot Loops available to purchase when you are buying cereal? Sele",
        xAxis: {
          categories: [
            'Somewhat important',
            'Very important',
            'Extremely important',
            'Not very important',
            'Not at all important',
          ],
        },
      },
      table_count: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Somewhat important',
            Overall: 0.382353,
          },
          {
            ans_opt: 'Very important',
            Overall: 0.254902,
          },
          {
            ans_opt: 'Extremely important',
            Overall: 0.240196,
          },
          {
            ans_opt: 'Not very important',
            Overall: 0.107843,
          },
          {
            ans_opt: 'Not at all important',
            Overall: 0.014706,
          },
        ],
      },
      table_pct: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Somewhat important',
            Overall: 78,
          },
          {
            ans_opt: 'Very important',
            Overall: 52,
          },
          {
            ans_opt: 'Extremely important',
            Overall: 49,
          },
          {
            ans_opt: 'Not very important',
            Overall: 22,
          },
          {
            ans_opt: 'Not at all important',
            Overall: 3,
          },
        ],
      },
    },
    {
      id: '8',
      type: 'RADIO',
      chart_count: {
        format: '0,0',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [96, 87, 20, 1],
          },
        ],
        title: "Which best describes your purchase history of Kellogg's Froot Loops? Select one.",
        xAxis: {
          categories: [
            'This is the brand I usually purchase',
            'This is the brand I sometimes purchase',
            'This is a brand I rarely purchase',
            'This is the first time I have purchased this brand',
          ],
        },
      },
      chart_pct: {
        format: '0.0%',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [0.470588, 0.426471, 0.098039, 0.004902],
          },
        ],
        title: "Which best describes your purchase history of Kellogg's Froot Loops? Select one.",
        xAxis: {
          categories: [
            'This is the brand I usually purchase',
            'This is the brand I sometimes purchase',
            'This is a brand I rarely purchase',
            'This is the first time I have purchased this brand',
          ],
        },
      },
      table_count: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'This is the brand I usually purchase',
            Overall: 0.470588,
          },
          {
            ans_opt: 'This is the brand I sometimes purchase',
            Overall: 0.426471,
          },
          {
            ans_opt: 'This is a brand I rarely purchase',
            Overall: 0.098039,
          },
          {
            ans_opt: 'This is the first time I have purchased this brand',
            Overall: 0.004902,
          },
        ],
      },
      table_pct: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'This is the brand I usually purchase',
            Overall: 96,
          },
          {
            ans_opt: 'This is the brand I sometimes purchase',
            Overall: 87,
          },
          {
            ans_opt: 'This is a brand I rarely purchase',
            Overall: 20,
          },
          {
            ans_opt: 'This is the first time I have purchased this brand',
            Overall: 1,
          },
        ],
      },
    },
    {
      id: '9',
      type: 'RADIO',
      chart_count: {
        format: '0,0',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [204],
          },
        ],
        title:
          'Thanks for uploading a receipt showing a purchase of Kellogg\'s Froot Loops cereal from [url("banner"',
        xAxis: {
          categories: ['Yes'],
        },
      },
      chart_pct: {
        format: '0.0%',
        series: [
          {
            id: 'Overall',
            name: 'Overall',
            data: [1.0],
          },
        ],
        title:
          'Thanks for uploading a receipt showing a purchase of Kellogg\'s Froot Loops cereal from [url("banner"',
        xAxis: {
          categories: ['Yes'],
        },
      },
      table_count: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Yes',
            Overall: 1.0,
          },
        ],
      },
      table_pct: {
        columns: [
          {
            accessor: 'ans_opt',
            header: 'Answer Options',
          },
          {
            accessor: 'Overall',
            header: 'Overall',
          },
        ],
        data: [
          {
            ans_opt: 'Yes',
            Overall: 204,
          },
        ],
      },
    },
  ],
};
