export default {
  demographic_filters: [
    {
      id: 'age_bracket',
      title: 'Age',
      options: [
        {
          count: 54,
          id: '35-44',
          label: '35-44',
        },
        {
          count: 25,
          id: '65+',
          label: '65+',
        },
        {
          count: 1,
          id: '21-24',
          label: '21-24',
        },
        {
          count: 56,
          id: '55-64',
          label: '55-64',
        },
        {
          count: 49,
          id: '45-54',
          label: '45-54',
        },
        {
          count: 18,
          id: '25-34',
          label: '25-34',
        },
      ],
    },
    {
      id: 'age_generation',
      title: 'Age Generation',
      options: [
        {
          count: 62,
          id: 'Millennials [1982-1995]',
          label: 'Millennials [1982-1995]',
        },
        {
          count: 84,
          id: 'Gen X [1965-1981]',
          label: 'Gen X [1965-1981]',
        },
        {
          count: 55,
          id: 'Boomers+ [< 1965]',
          label: 'Boomers+ [< 1965]',
        },
        {
          count: 2,
          id: 'Gen Z [> 1996]',
          label: 'Gen Z [> 1996]',
        },
      ],
    },
    {
      id: 'ethnicity',
      title: 'Ethnicity',
      options: [
        {
          count: 6,
          id: 'Asian',
          label: 'Asian',
        },
        {
          count: 14,
          id: 'Other',
          label: 'Other',
        },
        {
          count: 134,
          id: 'White/Caucasian',
          label: 'White/Caucasian',
        },
        {
          count: 24,
          id: 'Black or African American',
          label: 'Black or African American',
        },
        {
          count: 25,
          id: 'Hispanic/Latino',
          label: 'Hispanic/Latino',
        },
      ],
    },
    {
      id: 'gender',
      title: 'Gender',
      options: [
        {
          count: 3,
          id: 'Other',
          label: 'Other',
        },
        {
          count: 27,
          id: 'Male',
          label: 'Male',
        },
        {
          count: 174,
          id: 'Female',
          label: 'Female',
        },
      ],
    },
    {
      id: 'has_children',
      title: 'Has Children',
      options: [
        {
          count: 91,
          id: 'Yes',
          label: 'Yes',
        },
        {
          count: 113,
          id: 'No',
          label: 'No',
        },
      ],
    },
    {
      id: 'hh_size',
      title: 'Household Size',
      options: [
        {
          count: 19,
          id: '1',
          label: '1',
        },
        {
          count: 37,
          id: '3',
          label: '3',
        },
        {
          count: 12,
          id: '5',
          label: '5',
        },
        {
          count: 3,
          id: '6',
          label: '6',
        },
        {
          count: 48,
          id: '4',
          label: '4',
        },
        {
          count: 7,
          id: '7+',
          label: '7+',
        },
        {
          count: 78,
          id: '2',
          label: '2',
        },
      ],
    },
    {
      id: 'income',
      title: 'Income Dollar',
      options: [
        {
          count: 28,
          id: '$80k-100k',
          label: '$80k-100k',
        },
        {
          count: 26,
          id: '$60k-80k',
          label: '$60k-80k',
        },
        {
          count: 41,
          id: '- $20k',
          label: '- $20k',
        },
        {
          count: 29,
          id: '$40k-60k',
          label: '$40k-60k',
        },
        {
          count: 23,
          id: '$100k-125k',
          label: '$100k-125k',
        },
        {
          count: 31,
          id: '$20k-40k',
          label: '$20k-40k',
        },
        {
          count: 26,
          id: '$125k +',
          label: '$125k +',
        },
      ],
    },
    {
      id: 'urbanicity',
      title: 'Urbanicity',
      options: [
        {
          count: 58,
          id: 'Rural',
          label: 'Rural',
        },
        {
          count: 78,
          id: 'Suburban',
          label: 'Suburban',
        },
        {
          count: 66,
          id: 'Urban',
          label: 'Urban',
        },
      ],
    },
    {
      id: 'region',
      title: 'Region',
      options: [
        {
          count: 34,
          id: 'Midwest',
          label: 'Midwest',
        },
        {
          count: 92,
          id: 'South',
          label: 'South',
        },
        {
          count: 54,
          id: 'Northeast',
          label: 'Northeast',
        },
        {
          count: 22,
          id: 'West',
          label: 'West',
        },
      ],
    },
    {
      id: 'education',
      title: 'Education',
      filter_only: true,
      options: [
        {
          count: 3,
          id: 'Some Graduate School',
          label: 'Some Graduate School',
        },
        {
          count: 41,
          id: '4 year College Degree',
          label: '4 year College Degree',
        },
        {
          count: 37,
          id: 'Some College or university',
          label: 'Some College or university',
        },
        {
          count: 9,
          id: 'Less than high school',
          label: 'Less than high school',
        },
        {
          count: 23,
          id: '2 year College Degree',
          label: '2 year College Degree',
        },
        {
          count: 24,
          id: 'Graduate Degree',
          label: 'Graduate Degree',
        },
        {
          count: 15,
          id: 'Trade/Technical Degree',
          label: 'Trade/Technical Degree',
        },
        {
          count: 52,
          id: 'High School/GED',
          label: 'High School/GED',
        },
      ],
    },
    {
      id: 'marital_status',
      title: 'Marital Status',
      filter_only: true,
      options: [
        {
          count: 3,
          id: 'Separated',
          label: 'Separated',
        },
        {
          count: 22,
          id: 'Divorced',
          label: 'Divorced',
        },
        {
          count: 12,
          id: 'Widower',
          label: 'Widower',
        },
        {
          count: 13,
          id: 'Living with partner',
          label: 'Living with partner',
        },
        {
          count: 34,
          id: 'Never married',
          label: 'Never married',
        },
        {
          count: 120,
          id: 'Married',
          label: 'Married',
        },
      ],
    },
  ],
};
