import PropTypes from 'prop-types';

import { questionTypes } from '@api/surveys';

import {
  Checkbox,
  FolderIcon,
  handlePageClick,
  LoaderSkeleton,
  Pagination,
  usePaginatedItems,
} from '@utilities';

import styles from './_index.module.scss';

const Results = ({
  isSearching,
  maxSelectableQuestions,
  onSelectQuestion,
  questions,
  questionsToAdd,
  searchQuery,
}) => {
  const { currentItems, itemsPerPage, pageCount, setOffset } = usePaginatedItems({
    items: questions,
  });

  const onPageChange = (event) => {
    handlePageClick({ event, items: questions, setOffset });
  };

  if (isSearching) {
    return (
      <LoaderSkeleton>
        <rect x="0" y="0" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="25" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="50" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="75" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="100" rx="2" ry="2" width="225" height="15" />
      </LoaderSkeleton>
    );
  }

  if (!questions.length) {
    return (
      <p>
        {searchQuery ? 'Nothing matches your search criteria.' : 'No questions in this folder.'}
      </p>
    );
  }

  return (
    <>
      <ul className={styles['results-container']}>
        {currentItems.map((question) => (
          <li key={question.id}>
            {question.isFolder && (
              <button
                className={styles['results-folder']}
                onClick={() => onSelectQuestion(question)}
              >
                <FolderIcon /> {question.text}
              </button>
            )}
            {!question.isFolder && (
              <div className={styles['results-question']}>
                <div>
                  <div className={styles['results-checkbox-wrapper']}>
                    <Checkbox
                      isChecked={questionsToAdd.some(({ id }) => id === question.id)}
                      isDisabled={
                        !questionsToAdd.some(({ id }) => id === question.id) &&
                        questionsToAdd.length >= maxSelectableQuestions
                      }
                      label={question.text}
                      onChange={() => onSelectQuestion(question)}
                      value={question.id}
                    />
                  </div>
                  <span className={styles['results-caption']}>
                    {question.type === questionTypes.essay.value
                      ? 'Open-ended response'
                      : question.caption}
                  </span>
                </div>
                {question.image?.imageCdnUrl && (
                  <img
                    alt="Uploaded File"
                    className={styles['results-image']}
                    src={question.image.imageCdnUrl}
                  />
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
      <Pagination
        onPageChange={onPageChange}
        pageCount={pageCount}
        show={questions.length > itemsPerPage}
      />
    </>
  );
};

Results.propTypes = {
  isSearching: PropTypes.bool,
  maxSelectableQuestions: PropTypes.number.isRequired,
  onSelectQuestion: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  questionsToAdd: PropTypes.array.isRequired,
  searchQuery: PropTypes.string,
};

export default Results;
