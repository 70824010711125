/**
 * sanitize email preferences data
 * @param {Object} response
 * @returns {Object}
 */
export const transformEmailPreferences = (response) => {
  return {
    emailPreferences: {
      comments: response?.COMMENTS ?? false,
      schedules: response?.SCHEDULES ?? false,
      share: response?.SHARE ?? false,
    },
  };
};

/**
 * sanitize user data
 * @param {Object} response
 * @returns {Object}
 */
export const transformUser = (response) => {
  if (!response) return {};
  const { emailPreferences } = transformEmailPreferences(response?.email_preferences) || {};

  return {
    baseAccountUser: response?.sso_user,
    clientInfo: response?.client_info,
    email: response?.email,
    emailPreferences,
    firstName: response?.first_name,
    id: response?.id,
    isNewUser: response?.is_new_user,
    lastName: response?.last_name,
    name: response?.username,
    permissions: {
      canAccessAskWhy: response?.permissions?.can_access_askwhy
        ? {
            canPayWithCard: response?.permissions?.can_access_askwhy?.can_pay_with_card,
            canPayWithCredits: response?.permissions?.can_access_askwhy?.can_pay_with_credits,
            canPayWithInvoice: response?.permissions?.can_access_askwhy?.can_pay_with_invoice,
            canPayWithLicense: response?.permissions?.can_access_askwhy?.can_pay_with_license,
          }
        : false,
      canAccessBrand: response?.permissions?.can_access_brand,
      canAccessDateGroups: response?.permissions?.can_access_date_groups,
      canAccessDataWave: response?.permissions?.can_access_data_wave,
      canAccessGroups: response?.permissions?.can_access_groups,
      canAccessHomepage: response?.permissions?.can_access_homepage,
      canAccessNewItem: response?.permissions?.can_access_new_item,
      canAccessPortfolio: response?.permissions?.can_access_portfolio,
      canAccessPromo: response?.permissions?.can_access_promo,
      canAutomateReports: response?.permissions?.can_create_schedules,
      canBypassMaintenance: response?.permissions?.can_bypass_maintenance,
      canCreateSurveys: response?.permissions?.can_create_surveys,
      canLoginAsProxy: response?.permissions?.can_login_as_proxy,
      canShareFolders: response?.permissions?.can_share_folders,
      canShareGroups: response?.permissions?.can_share_groups,
      canShareReports: response?.permissions?.can_share_reports,
      canShareReportsPublicly: response?.permissions?.can_share_reports_publicly,
    },
    persona: {
      discipline: response?.discipline,
      industry: response?.industry,
      jobTitle: response?.job_title,
      lastUpdated: response?.persona_last_updated,
      proficiency: response?.proficiency,
    },
    surveyInfo: {
      clientCredits: response?.survey_info?.client_survey_credits_remaining || 0,
      userCredits: response?.survey_info?.user_survey_credits_remaining || 0,
    },
  };
};

/**
 * transforms and formats sharable users data
 * @param {Object} response
 * @return {Object} combines first_name last_name object values under the display key
 */
export const transformUsersData = (response) => {
  return response?.results?.map((user) => {
    return {
      display: user?.first_name + ' ' + user?.last_name,
      id: user?.id,
    };
  });
};
