import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { customFolderId, readQuestions, recommendedFolderId } from '@api/surveys';

import {
  Breadcrumbs,
  Button,
  Error,
  ModalV2 as Modal,
  Search,
  Tabs,
  useDebounce,
} from '@utilities';

import Results from './components/Results';

import styles from './_index.module.scss';

const customQuestionTab = 'custom';
const recommendedQuestionTab = 'recommended';

const ModalQuestionsLibrary = ({
  maxQuestions,
  onClose,
  selectedQuestions,
  setSelectedQuestions,
  surveyType,
}) => {
  const [activeTab, setActiveTab] = useState(recommendedQuestionTab);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [questionsToAdd, setQuestionsToAdd] = useState([]);
  const [searchQuery, setSearchQuery, { isDebouncing, signal }] = useDebounce('');
  const [selectedFolders, setSelectedFolders] = useState([
    { text: 'Recommended Questions', id: recommendedFolderId },
  ]);

  const searchRef = useRef(null);

  const selectedFolderId = selectedFolders[selectedFolders.length - 1]?.id;

  useEffect(() => {
    getQuestions();
    return () => searchRef.current?.abort();
  }, [selectedFolderId, signal]);

  const availableQuestions = useMemo(() => {
    return questions.filter((question) => !selectedQuestions.some(({ id }) => id === question.id));
  }, [questions, selectedQuestions]);

  const addQuestionsToSurvey = () => {
    setSelectedQuestions([...selectedQuestions, ...questionsToAdd]);
    onClose();
  };

  const getQuestions = async () => {
    if (searchRef?.current) {
      searchRef.current.abort();
    }
    searchRef.current = new AbortController();
    setIsSearching(true);
    setError(null);

    try {
      const questions = await readQuestions({
        documentId: surveyType.id,
        folderId: selectedFolderId,
        ...(searchQuery ? { search: searchQuery } : {}),
        signal: searchRef.current.signal,
      });
      setQuestions(questions);
      setIsSearching(false);
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        setError(error);
        setIsSearching(false);
      }
    }
  };

  const handleTabSelect = (value) => {
    if (value === customQuestionTab) {
      setActiveTab(customQuestionTab);
      setSelectedFolders([{ text: 'Custom Questions', id: customFolderId }]);
    } else {
      setActiveTab(recommendedQuestionTab);
      setSelectedFolders([{ text: 'Recommended Questions', id: recommendedFolderId }]);
    }
  };

  const onSelectQuestion = (question) => {
    if (question.isFolder) {
      setSelectedFolders([...selectedFolders, question]);
    } else {
      const selectedQuestion = questions.find(({ id }) => id === question.id);
      const questionIndex = questionsToAdd.findIndex(({ id }) => id === question.id);
      if (questionIndex >= 0) {
        questionsToAdd.splice(questionIndex, 1);
        setQuestionsToAdd([...questionsToAdd]);
      } else if (questionsToAdd.length < maxQuestions) {
        setQuestionsToAdd([...questionsToAdd, selectedQuestion]);
      }
    }
  };

  const actionButtons = [
    <Button key="modal-cancel-button" onClick={onClose} text="Cancel" variant="secondary" />,
    <Button
      key="modal-confirm-button"
      isDisabled={!questionsToAdd.length}
      onClick={addQuestionsToSurvey}
      text="Add to Survey"
    />,
  ];

  return (
    <Modal
      buttons={actionButtons}
      className="questions-library-modal"
      isActive={true}
      onClose={onClose}
      size="large"
      title="Questions Library"
    >
      {error ? (
        <Error status={error?.response?.status} />
      ) : (
        <>
          <Search
            className={styles['modal-questions-library-search']}
            isSearching={(isDebouncing || isSearching) && searchQuery !== ''}
            onChange={setSearchQuery}
            value={searchQuery}
          />
          <Tabs
            onChange={handleTabSelect}
            options={[
              {
                label: 'Recommended Questions',
                value: recommendedQuestionTab,
              },
              {
                label: 'Custom Questions',
                value: customQuestionTab,
              },
            ]}
            value={activeTab}
          />
          <div className={styles['modal-questions-library-list']}>
            {selectedFolders.length > 1 && (
              <Breadcrumbs
                options={[
                  ...selectedFolders.slice(1).map(({ text: label }, index) => ({
                    label,
                    onClick: () => setSelectedFolders(selectedFolders.slice(0, index + 1)),
                  })),
                ]}
              />
            )}
            {availableQuestions && (
              <Results
                isSearching={isDebouncing || isSearching}
                maxSelectableQuestions={maxQuestions - selectedQuestions.length}
                onSelectQuestion={onSelectQuestion}
                questions={availableQuestions}
                questionsToAdd={questionsToAdd}
                searchQuery={searchQuery}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

ModalQuestionsLibrary.propTypes = {
  maxQuestions: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
  setSelectedQuestions: PropTypes.func.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default ModalQuestionsLibrary;
