import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';

import {
  Breadcrumbs,
  DateRangePickerV2,
  HelpIcon,
  OverlayTriggerTooltip,
  Radio,
  Select,
} from '@utilities';

import { getTooltipContent } from './utilities/helpers';

import CustomDateGroups from './components/CustomDateGroups';

import styles from './_index.module.scss';

const DateOptions = ({
  activeAnswers,
  activePrompt,
  dateOptions,
  isLoading,
  onSelectAnswer,
  results,
}) => {
  const [customDates, setCustomDates] = useState([null, null]);
  const [isCustomChecked, setIsCustomChecked] = useState(false);

  const [startDate, endDate] = customDates;

  const customDateGroups = results?.filter((result) => result?.id?.includes('date_group'));

  const handleRadioChange = (answer) => {
    onSelectAnswer(answer?.options[0]?.value);
    setIsCustomChecked(false);
  };

  const handleSelectChange = (answer) => {
    onSelectAnswer(answer?.value);
  };

  useEffect(() => {
    const hasDateOptionAnswer = some(
      dateOptions?.reduce((a, b) => a.concat(b?.options), []),
      ['value', activeAnswers]
    );
    const hasCustomDateAnswer =
      activeAnswers &&
      Array.isArray(activeAnswers) &&
      activeAnswers?.length === 2 &&
      !hasDateOptionAnswer;

    if (
      hasCustomDateAnswer ||
      (activePrompt?.dateOptions?.length === 0 &&
        activePrompt?.hasCustomDateOption &&
        !activeAnswers?.[0]?.id?.includes('date_group'))
    ) {
      setCustomDates(activeAnswers);
      setIsCustomChecked(true);
    } else {
      setCustomDates([null, null]);
      setIsCustomChecked(false);
    }
  }, [activePrompt]);

  useEffect(() => {
    if (!activeAnswers) {
      setCustomDates([null, null]);
    }
  }, [activeAnswers]);

  useEffect(() => {
    if (isCustomChecked && !(customDates.includes(null) || customDates.includes(undefined))) {
      onSelectAnswer(customDates);
    }
  }, [customDates, isCustomChecked]);

  return (
    <Row className={styles['date-options']}>
      <Col xs={3}>
        <ul className={styles['date-options-list']}>
          {dateOptions.map((dateOption, index) => {
            const options = dateOption?.options;
            const isChecked = !isCustomChecked && some(options, ['value', activeAnswers]);
            const value = {
              label: options?.find((option) => isEqual(option?.value, activeAnswers))?.label,
              value: activeAnswers,
            };

            return (
              <li key={`date-option-${index}`}>
                <Radio
                  isChecked={isChecked}
                  label={dateOption?.timeFrame}
                  onChange={handleRadioChange}
                  value={dateOption}
                />
                {isChecked && options?.length > 1 && (
                  <div className={styles['date-options-select-container']}>
                    <Select onChange={handleSelectChange} options={options} value={value} />
                    <OverlayTriggerTooltip
                      content={getTooltipContent(dateOption?.type)}
                      placement="right"
                    >
                      <HelpIcon />
                    </OverlayTriggerTooltip>
                  </div>
                )}
              </li>
            );
          })}
          {activePrompt?.hasCustomDateOption && (
            <li>
              <Radio
                isChecked={isCustomChecked}
                label="Custom Time Period"
                name={activePrompt?.id}
                onChange={() => setIsCustomChecked(true)}
              />
            </li>
          )}
        </ul>
        <Breadcrumbs options={[{ label: 'Custom' }]} />
        <CustomDateGroups
          activeAnswers={activeAnswers}
          customDateGroups={customDateGroups}
          isCustomChecked={isCustomChecked}
          isLoading={isLoading}
          onSelectAnswer={onSelectAnswer}
          setIsCustomChecked={setIsCustomChecked}
        />
      </Col>
      <Col xs={9}>
        {isCustomChecked && (
          <DateRangePickerV2
            endDate={endDate}
            maxDate={activePrompt?.maxDate}
            onDatesChange={setCustomDates}
            startDate={startDate}
          />
        )}
      </Col>
    </Row>
  );
};

DateOptions.propTypes = {
  activeAnswers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  activePrompt: PropTypes.object,
  dateOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  onSelectAnswer: PropTypes.func,
  results: PropTypes.array,
};

export default DateOptions;
