import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { readInstantSurvey, readInstantSurveyType } from '@api/instant_survey';
import { readGlobalFilters } from '@api/survey_dashboard';
import { readSurvey, readSurveyType, surveyStatus } from '@api/surveys';

import {
  Announcement,
  Breadcrumbs,
  Error,
  getFormattedDate,
  LoaderSkeleton,
  Tabs,
} from '@utilities';

import Details from './components/Details';
import InsightsDetails from './components/InsightsDetails';
import Responses from './components/Responses';

const Survey = () => {
  const [globalFilters, setGlobalFilters] = useState();
  const [insightsDocument, setInsightsDocument] = useState();
  const [insightsType, setInsightsType] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  const [survey, setSurvey] = useState();
  const [surveyType, setSurveyType] = useState();

  const navigate = useNavigate();
  const { routes } = useSelector((state) => state?.user?.data);
  const instantSurveyRoutes = routes?.find(({ id }) => id === 'instant-survey-reports')?.routes;

  let { survey_id: surveyId, tab_id: tabId } = useParams();

  useEffect(() => {
    if (!tabId) {
      navigate(`/dashboard/survey/${surveyId}/${instantSurveyRoutes[0].id}`, { replace: true });
    }
    getData();
  }, [surveyId]);

  const getData = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const insightsSurvey = await readSurvey({ id: surveyId, signal });
      setInsightsDocument(insightsSurvey);
      if (insightsSurvey.atlasInstantSurveyRef) {
        const atlasRequests = [
          readGlobalFilters({ signal, surveyId: insightsSurvey.id }),
          readInstantSurvey({
            id: insightsSurvey.atlasInstantSurveyRef,
            params: { include_response_count: true },
            signal,
          }),
          readInstantSurveyType({
            id: insightsSurvey.instantSurveyTypeId,
            signal,
          }),
        ];
        const [filtersResponse, instantSurveyResponse, instantSurveyTypeResponse] =
          await Promise.all(atlasRequests);
        setGlobalFilters(filtersResponse);
        setSurvey(instantSurveyResponse);
        setSurveyType(instantSurveyTypeResponse);
      } else {
        const insightsRequests = [
          readGlobalFilters({ signal, surveyId: insightsSurvey.id }),
          readSurveyType({
            documentId: insightsSurvey.instantSurveyTypeId,
            signal,
          }),
        ];
        const [filtersResponse, insightsSurveyType] = await Promise.all(insightsRequests);
        setGlobalFilters(filtersResponse);
        setInsightsType(insightsSurveyType);
      }
      setIsLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setLoadingError(error);
      }
    }
  };

  return (
    <div>
      <Breadcrumbs />
      {loadingError && <Error status={loadingError?.response?.status} />}
      {!loadingError && isLoading && (
        <LoaderSkeleton height={725}>
          <rect x="0" y="0" rx="4" ry="4" width="850" height="95" />
          <rect x="0" y="105" rx="4" ry="4" width="1333" height="40" />
          <rect x="0" y="155" rx="4" ry="4" width="300" height="30" />
          <rect x="0" y="195" rx="4" ry="4" width="1333" height="165" />
          <rect x="0" y="380" rx="4" ry="4" width="300" height="30" />
          <rect x="0" y="420" rx="4" ry="4" width="1333" height="58" />
          <rect x="0" y="488" rx="4" ry="4" width="1333" height="58" />
          <rect x="0" y="556" rx="4" ry="4" width="1333" height="58" />
        </LoaderSkeleton>
      )}

      {!loadingError && !isLoading && (
        <>
          <div>
            <h2>{survey?.title || insightsDocument.title}</h2>
            <div>
              {insightsDocument.status === surveyStatus.DRAFT && (
                <Announcement
                  text="You are currently viewing a draft which does not have reporting available."
                  variant="warn"
                />
              )}
              {survey && (
                <>
                  <p>
                    Survey fielded on{' '}
                    <strong>
                      {survey.issueDate ? getFormattedDate(new Date(survey.issueDate)) : 'N/A'}
                    </strong>
                  </p>
                  {survey.groups.length > 1 && (
                    <p>
                      <strong>{survey.groups.length}</strong> quota groups
                    </p>
                  )}
                  <p>
                    <strong>
                      {survey.groups.reduce((acc, { quotaLimit }) => acc + quotaLimit, 0)}
                    </strong>{' '}
                    total responses
                  </p>
                  <p>
                    <strong>{survey.questions.length}</strong>{' '}
                    {survey.questions.length === 1 ? 'question' : 'questions'}
                  </p>
                </>
              )}
              {!survey && (
                <>
                  <p>
                    Survey created on{' '}
                    <strong>
                      {insightsDocument.created_datetime
                        ? getFormattedDate(new Date(insightsDocument.created_datetime))
                        : 'N/A'}
                    </strong>
                  </p>
                  <p>
                    <strong>
                      {insightsDocument.groups.reduce(
                        (acc, { responseCount }) => acc + responseCount,
                        0
                      )}
                    </strong>{' '}
                    responses
                  </p>
                  <p>
                    <strong>{insightsDocument.questions.length}</strong>{' '}
                    {insightsDocument.questions.length === 1 ? 'question' : 'questions'}
                  </p>
                </>
              )}
            </div>
            <hr />
          </div>

          <Tabs
            routes={[
              { label: 'Survey Details', id: 'details' },
              { label: 'Survey Responses', id: 'responses' },
              { label: 'Raw Data', id: 'raw-data' },
            ].map(({ id, label }) => ({ label, to: `/dashboard/survey/${surveyId}/${id}` }))}
          />
          {tabId === 'details' && survey && <Details survey={survey} surveyType={surveyType} />}
          {tabId === 'details' && !survey && (
            <InsightsDetails survey={insightsDocument} surveyType={insightsType} />
          )}
          {tabId === 'responses' && (
            <Responses globalFilters={globalFilters} survey={survey || insightsDocument} />
          )}
          {tabId === 'raw-data' && (
            <LoaderSkeleton height={115}>
              <rect x="0" y="0" rx="2" ry="2" width="200" height="15" />
              <rect x="0" y="25" rx="2" ry="2" width="175" height="15" />
              <rect x="0" y="50" rx="2" ry="2" width="225" height="15" />
              <rect x="0" y="75" rx="2" ry="2" width="200" height="15" />
              <rect x="0" y="100" rx="2" ry="2" width="225" height="15" />
            </LoaderSkeleton>
          )}
        </>
      )}
    </div>
  );
};

export default Survey;
