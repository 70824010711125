import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ActionArea, Button, OverlayTriggerTooltip, Toolbar } from '@utilities';
import { HelpIcon, SaveIcon } from '@utilities/icons';
import { createSurveyTypePromptValidation, responseCountIncrement } from '@api/surveys';

import ModalMissingRequiredPrompts from './components/ModalMissingRequiredPrompts';
import QuotaGroupCard from './components/QuotaGroupCard';
import { transformBuilderError } from '../../../../components/Builder/utilities/helpers';

import styles from './_index.module.scss';

const Panelists = ({
  groups,
  onNext,
  onSaveDraft,
  questionnairePrice,
  samplePrice,
  setPromptErrors,
  surveyType,
  updateGroups,
}) => {
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [isValidatingPanelists, setIsValidatingPanelists] = useState(false);

  const currentTotalResponses = groups.reduce((acc, group) => acc + group.responseCount, 0);
  const canDecrementResponses = surveyType.samplePricing?.some(
    ({ responses }) => responses === currentTotalResponses - responseCountIncrement
  );
  const canIncrementResponses = surveyType.samplePricing?.some(
    ({ responses }) => responses === currentTotalResponses + responseCountIncrement
  );
  const creditsTotal = samplePrice?.amount + questionnairePrice?.amount;
  const groupsMissingRequiredPrompts = groups
    .map(({ answers, id, name }) => ({
      id,
      name,
      prompts: surveyType.prompts
        .filter((prompt) => prompt.id && prompt.isRequired)
        .filter((prompt) => {
          return (
            answers[prompt.id] === undefined ||
            answers[prompt.id].length === 0 ||
            answers[prompt.id].includes(undefined) ||
            answers[prompt.id].includes(null)
          );
        }),
    }))
    .filter(({ prompts }) => prompts.length > 0);

  const navigate = useNavigate();
  const onContinue = async () => {
    if (groupsMissingRequiredPrompts.length > 0) return setIsValidationModalOpen(true);
    setIsValidatingPanelists(true);
    try {
      await createSurveyTypePromptValidation({
        answers: groups[0].answers,
        documentId: surveyType.id,
      });
      setIsValidatingPanelists(false);
      onNext();
    } catch (error) {
      const data = transformBuilderError(error?.response?.data);
      setPromptErrors(data);
      setIsValidatingPanelists(false);
    }
  };
  const onUpdateGroup = (propsToUpdate, index) => {
    let updatedGroups = [...groups];
    updatedGroups[index] = Object.assign(updatedGroups[index], propsToUpdate);
    updateGroups(updatedGroups);
  };

  return (
    <div className={styles['panelists']}>
      <ActionArea
        buttons={[
          <Button
            data-testid="cancel-button"
            key="cancel-button"
            onClick={() => navigate(-1)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            data-testid="continue-button"
            isLoading={isValidatingPanelists}
            key="continue-button"
            onClick={onContinue}
            text="Continue"
          />,
        ]}
        className={styles['panelists-layout']}
      >
        <Toolbar
          helpUrl={surveyType.help_url}
          tools={[
            <OverlayTriggerTooltip content="Save Draft">
              <button onClick={onSaveDraft}>
                <SaveIcon />
              </button>
            </OverlayTriggerTooltip>,
            <OverlayTriggerTooltip aria-label="Help" content="Help">
              <a href={surveyType.help_url} rel="noreferrer" target="_blank">
                <HelpIcon />
              </a>
            </OverlayTriggerTooltip>,
          ]}
        >
          <h1>{surveyType.name}</h1>
        </Toolbar>
        <div className={styles['panelists-pricing']}>
          <p>
            <strong>{groups.length}</strong> Quota Group{groups.length > 1 ? 's ' : ' '}
            with <strong>{currentTotalResponses}</strong> Total Responses
          </p>
          <p>
            Cost:&nbsp;
            {samplePrice && questionnairePrice ? (
              <strong>{`${creditsTotal} Credit${creditsTotal === 1 ? '' : 's'}`}</strong>
            ) : (
              <span>N/A</span>
            )}
          </p>
        </div>
        <ul className={styles['panelists-cards']}>
          {groups.map((group, index) => (
            <li key={group.id}>
              <QuotaGroupCard
                canDecrementResponses={canDecrementResponses}
                canIncrementResponses={canIncrementResponses}
                group={group}
                onUpdate={(propsToUpdate) => onUpdateGroup(propsToUpdate, index)}
                surveyType={surveyType}
              />
            </li>
          ))}
        </ul>
      </ActionArea>
      {isValidationModalOpen && (
        <ModalMissingRequiredPrompts
          groups={groups}
          invalidGroups={groupsMissingRequiredPrompts}
          onClose={() => setIsValidationModalOpen(false)}
        />
      )}
    </div>
  );
};

Panelists.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  onNext: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  questionnairePrice: PropTypes.object,
  samplePrice: PropTypes.object,
  setPromptErrors: PropTypes.func.isRequired,
  surveyType: PropTypes.object.isRequired,
  updateGroups: PropTypes.func,
};

export default Panelists;
