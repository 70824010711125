import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import { createReportSchedule, readReportFolders } from '@api/reports';

import { Announcement, Button, Input, isMaxNameLength, ModalV2 as Modal, Select } from '@utilities';

import DateSelectors from './components/DateSelectors';

import { isEntryValid } from './utilities/helpers';

import styles from './_index.module.scss';

const ModalAutomate = ({ name, reportId, setShowModalAutomate }) => {
  const [automateGroupName, setAutomateGroupName] = useState(name || '');
  const [entries, setEntries] = useState([{ id: nanoid(), name: `${name} (1)` }]);
  const [error, setError] = useState(false);
  const [foldersList, setFoldersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportDestination, setReportDestination] = useState('');

  const isLastEntryValid = isEntryValid(entries[entries.length - 1]);

  useEffect(() => {
    const readFoldersList = async () => {
      try {
        const response = await readReportFolders({});
        const data = response?.data?.folders?.map((folder) => {
          return { label: folder?.name, value: folder?.id };
        });
        setFoldersList([{ label: `My Reports`, value: null }, ...data]);
      } catch (error) {
        console.warn(error);
      }
    };
    readFoldersList();
  }, []);

  const handleSubmit = async () => {
    setError(false);

    try {
      setIsLoading(true);
      await createReportSchedule({ automateGroupName, entries, reportDestination, reportId });
      handleClose();
    } catch (error) {
      setError(error?.response?.data?.entries?.promptErrors);
      setIsLoading(false);
      console.warn(error);
    }
  };

  const handleClose = () => {
    setShowModalAutomate(false);
  };

  return (
    <Modal
      buttons={[
        <Button
          data-testid="modal-automate-cancel"
          key="cancel"
          onClick={handleClose}
          text="Cancel"
          variant="secondary"
        />,
        <Button
          data-testid="modal-automate-submit"
          isDisabled={
            !automateGroupName?.trim() ||
            reportDestination === '' ||
            !isLastEntryValid ||
            isMaxNameLength(automateGroupName) ||
            entries?.some((entry) => isMaxNameLength(entry?.name))
          }
          isLoading={isLoading}
          key="submit"
          onClick={handleSubmit}
          text="Save"
        />,
      ]}
      className={styles['modal-automate']}
      isActive
      onClose={handleClose}
      size="large"
      title="Automate Report"
    >
      {error && <Announcement text={error} variant="error" />}
      <div className={styles['modal-automate-description']}>
        <span>
          Run {name} with updated data, on a defined cadence. All entries lapse after 12 months of
          use.
        </span>
      </div>
      <div className={styles['modal-automate-details']}>
        <Input
          data-testid="modal-automate-details-input"
          error={
            isMaxNameLength(automateGroupName)
              ? 'Maximum number of characters reached. Try something shorter.'
              : ''
          }
          label="Name"
          onChange={setAutomateGroupName}
          value={automateGroupName}
        />
        <Select
          data-testid="modal-automate-details-select"
          label="Destination"
          onChange={(option) => setReportDestination(option?.value)}
          options={foldersList}
          placeholder="Select Folder..."
        />
      </div>
      <div className={styles['modal-automate-runs']}>
        <h4>RUNS ({entries.length})</h4>
        <DateSelectors
          automateGroupName={automateGroupName}
          entries={entries}
          isLastEntryValid={isLastEntryValid}
          setEntries={setEntries}
          name={name}
        />
      </div>
    </Modal>
  );
};

ModalAutomate.propTypes = {
  name: PropTypes.string,
  reportId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setShowModalAutomate: PropTypes.func,
};

export default ModalAutomate;
