import { useNavigate } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { GATES } from '@constants';

import ListPage from '../../components/ListPage';

import Delete from '../../components/ListPage/components/ActionsToolbar/components/Delete';
import Rename from '../../components/ListPage/components/ActionsToolbar/components/Rename';

import {
  deleteSurveys,
  readSurveys,
  surveyPageSize,
  surveyStatus,
  updateSurveyTitle,
} from '@api/surveys';

import { navigateToSurveyReport } from '../../../../utilities/helpers';
import { FILTER_OPTIONS, ITEM_COLUMNS } from './utilities/helpers.js';

const Surveys = () => {
  const navigate = useNavigate();
  const { value: canSurveyDashboard, isLoading: isStatsigLoading } = useGate(
    GATES.SURVEY_DASHBOARD
  );

  const expandSurveyRow = ({ atlasInstantSurveyRef, id, instantSurveyTypeId, status }) => {
    switch (status) {
      case surveyStatus.DRAFT:
        const params = { id };
        if (atlasInstantSurveyRef) params.ref = atlasInstantSurveyRef;
        navigate({
          pathname: `/dashboard/create-survey/${instantSurveyTypeId}`,
          search: new URLSearchParams(params).toString(),
        });
        break;
      case surveyStatus.COMPLETED:
      case surveyStatus.MET_SAMPLE:
        if (canSurveyDashboard) {
          navigate({ pathname: `/dashboard/survey/${id}` });
        } else {
          navigateToSurveyReport(navigate, { id, forceNewJobRun: false });
        }
        break;
      default:
        break;
    }
  };

  if (isStatsigLoading) return null;
  return (
    <ListPage
      filterOptions={FILTER_OPTIONS}
      itemActions={[
        <Rename type="Survey" updateName={updateSurveyTitle} />,
        <Delete deleteItems={deleteSurveys} />,
      ]}
      itemColumns={ITEM_COLUMNS}
      itemSize={surveyPageSize}
      onCreate={() => navigate('/dashboard/surveys/instant-surveys')}
      onExpandItemRow={expandSurveyRow}
      readItems={readSurveys}
      type="Survey"
    />
  );
};

export default Surveys;
