import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useGate } from 'statsig-react';

import { confirmSurvey, customFolderId, maxQuestions, minQuestions } from '@api/surveys';

import { ActionArea, Button, Pill } from '@utilities';
import { GATES } from '@constants';

import ModalQuestionsLibrary from './components/ModalQuestionsLibrary';
import ModalSurveyQuestion from './components/ModalSurveyQuestion';
import QuestionnaireList from './components/QuestionnaireList';
import QuestionnaireToolbar from './components/QuestionnaireToolbar';
import { transformBuilderError } from '../../../../components/Builder/utilities/helpers';

import styles from './_index.module.scss';
import { checkForLogicChanges } from './utilities/helpers';

const Questionnaire = ({
  onNext,
  onPrevious,
  onSaveDraft,
  questionLogic,
  questionnairePrice,
  samplePrice,
  selectedQuestions,
  setPromptErrors,
  setQuestionLogic,
  setSelectedQuestions,
  survey,
  surveyType,
  updateSurvey,
}) => {
  const [isDetailedQuestionView, setIsDetailedQuestionView] = useState(true);
  const [isDuplicatingQuestion, setIsDuplicatingQuestion] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showCustomQuestionModal, setShowCustomQuestionModal] = useState(false);
  const [showQuestionsLibraryModal, setShowQuestionsLibraryModal] = useState(false);

  const { value: isSurveyLengthExtended } = useGate(GATES.SURVEY_LENGTH_EXTENDED);
  const { value: isSurveyPricingSplit } = useGate(GATES.SURVEY_PRICING_SPLIT);

  const creditsTotal = samplePrice?.amount + questionnairePrice?.amount;
  const firstErrorRef = useRef(null);
  const pricingMaxQuestions = isSurveyLengthExtended ? surveyType.maxQuestions : maxQuestions;

  const addQuestionToSelections = (question) => {
    const questionIndex = selectedQuestions.findIndex(({ id }) => id === question.id);
    if (questionIndex >= 0) {
      const updatedQuestion = selectedQuestions.splice(questionIndex, 1, question)[0];
      setSelectedQuestions([...selectedQuestions]);

      checkForLogicChanges({
        question,
        questionIndex,
        questionLogic,
        setQuestionLogic,
        updatedQuestion,
      });
    } else {
      if (selectedQuestions.length < pricingMaxQuestions) {
        setSelectedQuestions([...selectedQuestions, question]);
      }
    }
  };

  const handleQuestionCreate = (question) => {
    addQuestionToSelections(question);
    handleQuestionModalClose();
  };

  const handleQuestionDuplicate = (question) => {
    setIsDuplicatingQuestion(true);
    setSelectedQuestion(question);
    setShowCustomQuestionModal(true);
  };

  const handleQuestionEdit = (question) => {
    setSelectedQuestion(question);
    setShowCustomQuestionModal(true);
  };

  const handleQuestionLibraryModalClose = () => {
    setShowQuestionsLibraryModal(false);
    setSelectedQuestion(null);
  };

  const handleQuestionModalClose = () => {
    setShowCustomQuestionModal(false);

    setIsDuplicatingQuestion(false);
    setSelectedQuestion(null);
  };

  const onContinue = async () => {
    if (!survey.validationKey) {
      const isLogicValid = validateQuestionLogic();
      if (!isLogicValid) {
        if (firstErrorRef.current) {
          firstErrorRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
        return;
      }

      setIsValidating(true);
      try {
        const { groups, ...surveyData } = survey;
        const { title, validationKey } = await confirmSurvey({
          answers: groups[0].answers,
          documentId: surveyType.id,
          numberOfResponsesRequested: groups[0].responseCount,
          ...surveyData,
        });
        updateSurvey({
          title: survey.title || title,
          validationKey: validationKey,
        });
      } catch (error) {
        setIsValidating(false);
        setPromptErrors(
          error?.response?.data?.promptErrors
            ? transformBuilderError(error.response.data)
            : ['A question you selected does not work with your panelist prompt.']
        );
        return;
      }
    }

    onNext();
  };

  const onRemoveQuestion = (question) => {
    const questionIndex = selectedQuestions.findIndex(({ id }) => id === question.id);
    selectedQuestions.splice(questionIndex, 1);
    setSelectedQuestions([...selectedQuestions]);

    checkForLogicChanges({
      hasQuestionBeenRemoved: true,
      question,
      questionLogic,
      setQuestionLogic,
    });
  };

  const validateQuestionLogic = () => {
    const modifiedQuestionLogic = { ...questionLogic };
    let isValid = true;
    for (const questionId in modifiedQuestionLogic) {
      const question = modifiedQuestionLogic[questionId];

      if (question.skip) {
        for (const row of question.skip.logic) {
          if (!row.question?.id) {
            row.isFirstQuestionError = isValid;
            row.hasQuestionError = true;
            isValid = false;
          } else {
            row.hasQuestionError = false;
          }

          if (row.answers.length === 0) {
            row.isFirstAnswerError = isValid;
            row.hasAnswerError = true;
            isValid = false;
          } else {
            row.hasAnswerError = false;
          }
        }
      }

      if (question.disqualify) {
        if (question.disqualify.answers.length === 0) {
          question.disqualify.isFirstAnswerError = isValid;
          question.disqualify.hasAnswerError = true;
          isValid = false;
        } else {
          question.disqualify.hasAnswerError = false;
        }
      }
    }

    setQuestionLogic(modifiedQuestionLogic);
    return isValid;
  };

  return (
    <>
      <ActionArea
        buttons={[
          <Button
            data-testid="previous-button"
            key="previous-button"
            onClick={onPrevious}
            text="Previous"
            variant="secondary"
          />,
          <Button
            data-testid="review-button"
            isDisabled={selectedQuestions.length < minQuestions}
            isLoading={isValidating}
            key="review-button"
            onClick={onContinue}
            text="Go To Review"
          />,
        ]}
        className={styles['questionnaire']}
      >
        <QuestionnaireToolbar
          isDetailedQuestionView={isDetailedQuestionView}
          maxQuestions={pricingMaxQuestions}
          onAddQuestion={() => setShowQuestionsLibraryModal(true)}
          onCreateQuestion={() => setShowCustomQuestionModal(true)}
          onSaveDraft={onSaveDraft}
          selectedQuestions={selectedQuestions}
          setIsDetailedQuestionView={setIsDetailedQuestionView}
          surveyType={surveyType}
        >
          <h1>
            {surveyType.name}
            <span className={styles['questionnaire-count']}>
              <Pill value={selectedQuestions.length + '/' + pricingMaxQuestions + ' Questions'} />
            </span>
          </h1>
        </QuestionnaireToolbar>
        {isSurveyPricingSplit && (
          <div className={styles['questionnaire-pricing']}>
            <p>{questionnairePrice?.description}</p>
            <p>
              Cost:&nbsp;
              {samplePrice && questionnairePrice ? (
                <strong>{`${creditsTotal} Credit${creditsTotal === 1 ? '' : 's'}`}</strong>
              ) : (
                <span>N/A</span>
              )}
            </p>
          </div>
        )}
        <QuestionnaireList
          firstErrorRef={firstErrorRef}
          isDetailedQuestionView={isDetailedQuestionView}
          maxQuestions={pricingMaxQuestions}
          onAddQuestion={() => setShowQuestionsLibraryModal(true)}
          onCreateQuestion={() => setShowCustomQuestionModal(true)}
          onDuplicateQuestion={handleQuestionDuplicate}
          onRemoveQuestion={onRemoveQuestion}
          onSelectQuestion={handleQuestionEdit}
          questionLogic={questionLogic}
          selectedQuestions={selectedQuestions}
          setQuestionLogic={setQuestionLogic}
          setSelectedQuestions={setSelectedQuestions}
        ></QuestionnaireList>
      </ActionArea>
      {showCustomQuestionModal && (
        <ModalSurveyQuestion
          id={selectedQuestion?.id}
          isDuplicate={isDuplicatingQuestion}
          isReadOnly={
            !isDuplicatingQuestion &&
            selectedQuestion?.parentId &&
            selectedQuestion?.parentId !== customFolderId
          }
          onClose={handleQuestionModalClose}
          onCreate={handleQuestionCreate}
          onUpdate={handleQuestionCreate}
          surveyType={surveyType}
        />
      )}
      {showQuestionsLibraryModal && (
        <ModalQuestionsLibrary
          maxQuestions={pricingMaxQuestions}
          onClose={handleQuestionLibraryModalClose}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          surveyType={surveyType}
        />
      )}
    </>
  );
};

Questionnaire.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func,
  questionLogic: PropTypes.object.isRequired,
  questionnairePrice: PropTypes.object,
  samplePrice: PropTypes.object,
  selectedQuestions: PropTypes.array.isRequired,
  setPromptErrors: PropTypes.func.isRequired,
  setQuestionLogic: PropTypes.func.isRequired,
  setSelectedQuestions: PropTypes.func.isRequired,
  survey: PropTypes.object.isRequired,
  surveyType: PropTypes.object.isRequired,
  updateSurvey: PropTypes.func.isRequired,
};

export default Questionnaire;
