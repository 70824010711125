import React from 'react';
import PropTypes from 'prop-types';
import some from 'lodash/some';

import { getPromptPillValue } from '@helpers';
import { Pill, truncateTextWithEllipsis } from '@utilities';

import styles from './_index.module.scss';

const RecommendedAnswers = ({
  activeAnswers,
  activePrompt,
  hasActivePromptLimit,
  onSelectAnswer,
  prompts,
  recommendedAnswers,
}) => {
  if (!recommendedAnswers) return null;

  return (
    <ul className={styles['recommended-answers']}>
      {recommendedAnswers?.map((recommendedAnswer, index) => {
        const value = truncateTextWithEllipsis({
          text: getPromptPillValue({
            answers: { [activePrompt?.id]: [recommendedAnswer] },
            prompt: activePrompt,
          }),
        });

        return (
          <li key={`recommended-answer-${index}`}>
            <Pill
              isActive={activeAnswers ? some(activeAnswers, recommendedAnswer) : false}
              isDisabled={hasActivePromptLimit}
              onClick={() => {
                onSelectAnswer(recommendedAnswer);
              }}
              value={value}
            />
          </li>
        );
      })}
    </ul>
  );
};

RecommendedAnswers.propTypes = {
  activeAnswers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  activePrompt: PropTypes.object,
  hasActivePromptLimit: PropTypes.bool,
  onSelectAnswer: PropTypes.func,
  prompts: PropTypes.array,
  recommendedAnswers: PropTypes.array,
};

export default RecommendedAnswers;
