import axios from '@api';

import { surveyTypeQuestionId } from './utilities/constants';
import { transformQuestion, transformQuestions } from './utilities/helpers';

/**
 * create question
 * @param {Array} options.answers
 * @param {String} options.documentId
 * @param {Object} options.image
 * @param {Boolean} options.randomizeOptions
 * @param {String} options.text
 * @param {String} options.type
 * @return {Promise}
 */
export const createQuestion = ({
  answers: options,
  documentId,
  image,
  randomizeOptions: randomize_options,
  text,
  type: question_type,
}) => {
  return axios({
    data: {
      image_bucket_name: image?.imageBucketName,
      image_cdn_url: image?.imageCdnUrl,
      image_object_key: image?.imageObjectKey,
      image_object_type: image?.imageObjectType,
      instant_survey_types: documentId,
      options: options.map((option) => {
        return {
          anchored: option.isAnchored,
          exclusive: option.isExclusive,
          image_bucket_name: option.imageBucketName,
          image_cdn_url: option.imageCdnUrl,
          image_object_key: option.imageObjectKey,
          image_object_type: option.imageObjectType,
          none_of_the_above: option.isNoneOfTheAbove,
          other: option.isOther,
          text: option.text,
        };
      }),
      question_type,
      randomize_options,
      text,
    },
    method: 'post',
    url: '/api/questions/create',
    withCredentials: true,
  }).then(({ data }) => transformQuestion(data));
};

/**
 * read question
 * @param {String} id
 * @return {Promise}
 */
export const readQuestion = (id) => {
  return axios({
    method: 'get',
    url: `/api/questions/${id}`,
    withCredentials: true,
  }).then(({ data }) => transformQuestion(data));
};

/**
 * read questions
 * @param {String} options.documentId
 * @param {Number|Null} [options.folderId]
 * @param {String} [options.search]
 * @param {AbortSignal} signal
 * @return {Promise}
 */
export const readQuestions = ({ documentId, folderId: parent_id, search, signal }) => {
  return axios({
    method: 'get',
    params: { attributeId: 'question_folders', parent_id, search },
    signal,
    url: `/api/prompts/${surveyTypeQuestionId[documentId]}/search`,
    withCredentials: true,
  }).then(({ data }) => transformQuestions(data, search));
};

/**
 * update question
 * @param {Object} options.answers
 * @param {String} options.documentId
 * @param {Number} options.id
 * @param {Object} options.image
 * @param {Boolean} options.randomizeOptions
 * @param {String} options.text
 * @param {String} options.type
 * @return {Promise}
 */
export const updateQuestion = ({
  answers: options,
  documentId,
  id,
  image,
  randomizeOptions: randomize_options,
  type: question_type,
  text,
}) => {
  return axios({
    data: {
      image_bucket_name: image?.imageBucketName,
      image_cdn_url: image?.imageCdnUrl,
      image_object_key: image?.imageObjectKey,
      image_object_type: image?.imageObjectType,
      instant_survey_types: documentId,
      options: options.map((option) => {
        return {
          anchored: option.isAnchored,
          exclusive: option.isExclusive,
          image_bucket_name: option.imageBucketName,
          image_cdn_url: option.imageCdnUrl,
          image_object_key: option.imageObjectKey,
          image_object_type: option.imageObjectType,
          none_of_the_above: option.isNoneOfTheAbove,
          other: option.isOther,
          text: option.text,
        };
      }),
      question_type,
      randomize_options,
      text,
    },
    method: 'put',
    url: `/api/questions/${id}/update`,
    withCredentials: true,
  }).then(({ data }) => transformQuestion(data));
};
