import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import LogRocket from 'logrocket';
import { nanoid } from 'nanoid';
import { useGate } from 'statsig-react';

import {
  createSurvey,
  confirmSurvey,
  defaultGroupName,
  defaultResponseCount,
  minQuestions,
  readSurvey,
  readSurveyType,
} from '@api/surveys';
import { GATES } from '@constants';
import { Error, LoaderSkeleton, Stepper, toast, usePrompt } from '@utilities';

import { updateUserCreditsRemaining } from '@redux/slices/user';

import Confirmation from './components/Confirmation';
import CreateInstantSurvey from '../CreateInstantSurvey';
import ModalPromptErrors from '../../components/Builder/components/ModalPromptErrors';
import ModalSaveAsDraft from './components/ModalSaveAsDraft';
import Panelists from './components/Panelists';
import Questionnaire from './components/Questionnaire';
import Review from './components/Review';
import { transformBuilderError } from '../../components/Builder/utilities/helpers';

const CreateSurvey = () => {
  const [searchParams] = useSearchParams();
  const { isLoading: isStatsigLoading, value: useInstantSurveyService } = useGate(
    GATES.INSTANT_SURVEY_SERVICE
  );

  if (isStatsigLoading) return null;

  if (searchParams.get('ref') || (!searchParams.get('id') && useInstantSurveyService)) {
    return <CreateInstantSurvey />;
  }

  return <CreateInsightsSurvey />;
};

const CreateInsightsSurvey = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [hasLaunched, setHasLaunched] = useState(false);
  const [hasSavedAsDraft, setHasSavedAsDraft] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [promptErrors, setPromptErrors] = useState([]);
  const [survey, setSurvey] = useState({
    groups: [
      {
        answers: {},
        id: nanoid(),
        name: defaultGroupName,
        responseCount: defaultResponseCount,
      },
    ],
    id: null,
    questionLogic: {},
    questions: [],
    title: '',
    validationKey: null,
  });
  const [surveyType, setSurveyType] = useState({});
  const [showSaveAsDraft, setShowSaveAsDraft] = useState(false);
  const [updateDraft, setUpdateDraft] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instant_survey_type_id: surveyTypeId } = useParams();
  const [searchParams] = useSearchParams();

  const currentQuestionnairePrice = surveyType?.questionnairePricing?.find(
    ({ questions }) => questions === survey.questions.length
  );
  const currentSamplePrice = surveyType?.samplePricing?.find(
    ({ quotaGroups, responses }) =>
      quotaGroups === survey.groups.length &&
      responses === survey.groups.reduce((acc, group) => acc + group.responseCount, 0)
  );

  useEffect(() => {
    if (surveyTypeId) {
      fetchSurveyData();
    }
  }, [surveyTypeId]);

  useEffect(() => {
    if (updateDraft) {
      saveAsDraft({ preventNavigation: true });
      setUpdateDraft(false);
    }
  }, [updateDraft]);

  const fetchSurveyData = async () => {
    try {
      const requests = [readSurveyType({ documentId: surveyTypeId })];
      const surveyId = searchParams.get('id');
      if (surveyId) {
        requests.push(readSurvey({ id: surveyId }));
      }
      const [{ samplePricing, ...surveyTypeResponse }, surveyResponse] = await Promise.all(
        requests
      );
      setSurveyType({
        samplePricing: samplePricing.filter(
          ({ isAskWhy, type }) => !isAskWhy && type === 'CREDITS'
        ),
        ...surveyTypeResponse,
      });

      if (surveyId) {
        const { hasQuestionLogicError, ...surveyData } = surveyResponse;

        if (hasQuestionLogicError) {
          toast(
            'There was an error while rebuilding your questionnaire logic. Please confirm your selections before continuing.',
            { status: 'error' }
          );
          surveyData.validationKey = null;
        }
        setSurvey(surveyData);
        setActiveStep(hasQuestionLogicError || surveyData.questions.length < minQuestions ? 1 : 2);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const onCreateSurvey = ({ userCreditsRemaining }) => {
    LogRocket.track('InstantSurvey_Published', { type: surveyTypeId });
    dispatch(updateUserCreditsRemaining({ userCreditsRemaining }));
    setHasLaunched(true);
  };

  const saveAsDraft = async ({ preventNavigation }) => {
    let title = survey.title;
    let validationKey = survey.validationKey;

    if (!validationKey) {
      try {
        const { groups, ...surveyProps } = survey;
        const surveyConfirmation = await confirmSurvey({
          answers: groups[0].answers,
          documentId: surveyType.id,
          numberOfResponsesRequested: groups[0].responseCount,
          ...surveyProps,
        });
        validationKey = surveyConfirmation.validationKey;
        if (!title) {
          title = surveyConfirmation.title;
        }
      } catch (error) {
        setShowSaveAsDraft(false);
        setPromptErrors(
          error?.response?.data?.promptErrors
            ? transformBuilderError(error.response.data)
            : ['Ensure all panelist requirements are selected before continuing.']
        );
        return;
      }
    }

    try {
      await createSurvey({
        id: survey.id,
        status: 'draft',
        title,
        validationKey,
      });
      if (!preventNavigation) {
        LogRocket.track('InstantSurvey_SaveAsDraft', { type: surveyTypeId });
        setHasSavedAsDraft(true);
        setTimeout(() => {
          navigate({
            pathname: '/dashboard/workspace/surveys',
            search: new URLSearchParams({ filters: 'is_draft_item' }).toString(),
          });
        }, 50);
      }
    } catch (error) {
      setError(error);
      setShowSaveAsDraft(false);
    }
  };

  const updateSelectedQuestions = (questions, questionLogic) => {
    // sometimes both questions and questionLogic need to be updated at the same time
    updateSurvey({ questions, ...(questionLogic && { questionLogic }), validationKey: null });
    const surveyId = searchParams.get('id');
    if (surveyId) {
      setUpdateDraft(true);
    }
  };

  const updateSurvey = (fields) => {
    if (fields.groups || fields.questionLogic) {
      setSurvey({ ...survey, ...fields, validationKey: null });
    } else {
      setSurvey({ ...survey, ...fields });
    }
  };

  const showBlocker = survey.groups.some(
    ({ answers }) =>
      Object.keys(answers).some((key) => answers[key]?.length > 0) || survey.questions.length > 0
  );
  usePrompt(
    {
      title: 'Are you sure you want to leave the survey?',
    },
    showBlocker && !hasSavedAsDraft && !hasLaunched
  );

  if (error) {
    return <Error status={error?.response?.status} />;
  }

  if (isLoading) {
    return (
      <LoaderSkeleton height={450}>
        <rect x="0" y="0" rx="4" ry="4" width="1333" height="40" />
        <rect x="0" y="60" rx="4" ry="4" width="1333" height="130" />
        <rect x="0" y="210" rx="4" ry="4" width="1333" height="40" />
        <rect x="0" y="270" rx="4" ry="4" width="1333" height="20" />
        <rect x="0" y="310" rx="2" ry="2" width="175" height="20" />
        <rect x="0" y="350" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="375" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="400" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="425" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="450" rx="2" ry="2" width="225" height="15" />
      </LoaderSkeleton>
    );
  }

  if (hasLaunched) {
    return <Confirmation />;
  }

  return (
    <>
      <Stepper
        activeIndex={activeStep}
        navigationType="linear"
        onChange={(index) => setActiveStep(index)}
        steps={[
          {
            label: 'Select Panelists',
            isComplete: survey.groups.some(({ answers }) =>
              Object.keys(answers).some((key) => answers[key]?.length > 0)
            ),
          },
          {
            label: 'Create Questionnaire',
            isComplete: survey.questions.length >= minQuestions,
          },
          {
            label: 'Review Survey',
          },
        ]}
      />
      {activeStep === 0 && (
        <Panelists
          groups={survey.groups}
          onNext={() => setActiveStep(1)}
          onSaveDraft={() => setShowSaveAsDraft(true)}
          questionnairePrice={currentQuestionnairePrice}
          samplePrice={currentSamplePrice}
          setPromptErrors={setPromptErrors}
          surveyType={surveyType}
          updateGroups={(groups) => updateSurvey({ groups })}
        />
      )}
      {activeStep === 1 && (
        <Questionnaire
          onNext={() => setActiveStep(2)}
          onPrevious={() => setActiveStep(0)}
          onSaveDraft={() => setShowSaveAsDraft(true)}
          questionLogic={survey.questionLogic}
          questionnairePrice={currentQuestionnairePrice}
          samplePrice={currentSamplePrice}
          setQuestionLogic={(questionLogic) => updateSurvey({ questionLogic, validationKey: null })}
          selectedQuestions={survey.questions}
          setPromptErrors={setPromptErrors}
          setSelectedQuestions={updateSelectedQuestions}
          survey={survey}
          surveyType={surveyType}
          updateSurvey={updateSurvey}
        />
      )}
      {activeStep === 2 && (
        <Review
          onSaveDraft={() => setShowSaveAsDraft(true)}
          onSubmit={onCreateSurvey}
          questionnairePrice={currentQuestionnairePrice}
          samplePrice={currentSamplePrice}
          setActiveStep={setActiveStep}
          survey={survey}
          surveyType={surveyType}
          updateSurvey={updateSurvey}
        />
      )}
      <ModalSaveAsDraft
        isActive={showSaveAsDraft}
        onClose={() => setShowSaveAsDraft(false)}
        onSubmit={saveAsDraft}
      />
      <ModalPromptErrors promptErrors={promptErrors} setPromptErrors={setPromptErrors} />
    </>
  );
};

export default CreateSurvey;
