import axios from '@api';

import mockEssayResponses from './__mock__/essayResponses';
import mockGlobalFilters from './__mock__/globalFilters';
import mockSurveyResponses from './__mock__/surveyResponses';

import {
  transformEssayResponses,
  transformGlobalFilters,
  transformSurveyResponses,
} from './utilities/helpers';

const delay = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const isLocalHost = window.location.hostname === 'localhost';

/**
 * read essay responses
 * @param {Object} options
 * @param {Object} [options.query]
 * @param {Number} [options.signal] AbortController signal
 * @param {Number|String} options.surveyId
 * @return {Promise}
 */
export const readEssayResponses = async ({ query = {}, signal, surveyId }) => {
  if (isLocalHost) {
    await delay(750);
    return transformEssayResponses(mockEssayResponses);
  }
  return axios({
    method: 'get',
    params: {
      ...query,
      survey_id: surveyId,
    },
    signal,
    url: '/v2/api/survey-dashboard/essay-responses/table',
    withCredentials: true,
  }).then(({ data }) => transformEssayResponses(data));
};

/**
 * read global filters
 * @param {Object} options
 * @param {Number} [options.signal] AbortController signal
 * @param {Number|String} options.surveyId
 * @return {Promise}
 */
export const readGlobalFilters = async ({ signal, surveyId }) => {
  if (isLocalHost) {
    await delay(750);
    return transformGlobalFilters(mockGlobalFilters);
  }
  return axios({
    method: 'get',
    signal,
    url: `/v2/api/survey-dashboard/global-filters/${surveyId}`,
    withCredentials: true,
  }).then(({ data }) => transformGlobalFilters(data));
};

/**
 * read survey responses
 * @param {Object} options
 * @param {Object} [options.query]
 * @param {Number} [options.signal] AbortController signal
 * @param {Number|String} options.surveyId
 * @return {Promise}
 */
export const readSurveyResponses = async ({ query = {}, signal, surveyId }) => {
  if (isLocalHost) {
    await delay(750);
    return transformSurveyResponses(mockSurveyResponses);
  }
  return axios({
    method: 'get',
    params: {
      ...query,
      survey_id: surveyId,
    },
    signal,
    url: '/v2/api/survey-dashboard/survey-responses/bar-chart',
    withCredentials: true,
  }).then(({ data }) => transformSurveyResponses(data));
};
